import { useState } from 'react';
import { AdResolutionIab, AdSurfaceInteractivity } from '../../../../global/consts';
import { Content, Event } from '../../../../global/interfaces';
import { useValidation } from '../../../../hooks';
import FileUpload from '../../../file-upload/file-upload';
import IabSelect from '../../../iab-select/iab-select';
import Input from '../../../input/input';
import Select from '../../../select/select';
import { validations } from '../validations';

import './ad-surface.scss';

const AdSurfaceFields = ({
  surface,
  updateSurface,
  allowCreateSurface = true,
  handleFileChange,
  loading,
}: {
  surface: {
    adType: string;
    placeholder?: { url: string };
    resolutionIab: AdResolutionIab;
    interactivity: AdSurfaceInteractivity;
    contentTags: Content[];
    maxWidth: string;
    maxHeight: string;
    floorPrice?: number;
    file?: File | undefined;
  };
  updateSurface: any;
  allowCreateSurface?: boolean;
  handleFileChange?: (e: Event['Input']) => void;
  loading: boolean;
}) => {
  const { errors, isFormInvalid, isFieldInvalid } = useValidation(validations);

  const [editPlaceholder, setEditPlaceholder] = useState(false);

  const showImageVideo = () => {
    if (!surface.placeholder) return null;
    const url = surface.file ? URL.createObjectURL(surface.file) : surface.placeholder.url;
    const isVideo = surface.adType === 'VIDEO';
    return (
      <div className="ad-surface-item__body_placeholder">
        {(!isVideo || editPlaceholder) && (
          <FileUpload
            label="Placeholder File"
            setFiles={(files: File[]) => {
              if (isFieldInvalid('file', { file: files[0], adType: surface.adType })) return;
              updateSurface({ ...surface, file: files[0] });
              setEditPlaceholder(false); // Reset editPlaceholder state
            }}
            loadedImgSrc={isVideo ? undefined : url}
            description={isVideo ? 'Upload Placeholder File' : undefined}
            errorMessage={errors?.file}
            handleChange={handleFileChange}
          />
        )}
        {isVideo && (
          <div id="video-container">
            <label>Placeholder Video</label>
            <video controls src={url} className="ad-surface-item__body_video">
              <track kind="captions" src="" label="English" />
            </video>
            <button
              type="button"
              className="btn-primary-solid"
              style={{ padding: '10px' }}
              onClick={() => setEditPlaceholder(true)}
            >
              Change
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={loading ? 'loading' : ''}>
      <Input
        label="Floor price"
        type="text"
        handleInput={(e: Event['Input']) => {
          isFormInvalid({ ...surface, floorPrice: e.target.value });
          updateSurface({ ...surface, floorPrice: e.target.value });
        }}
        value={surface.floorPrice ? `$${surface.floorPrice}` : ''}
        readOnly
      />
      <Select
        label="Resolution"
        value={AdResolutionIab[surface.resolutionIab]}
        options={AdResolutionIab}
        showError
        errorMsg={errors?.resolutionIab}
        changeHandler={(index) => {
          updateSurface({ ...surface, resolutionIab: index });
        }}
        disabled={!allowCreateSurface}
      />
      <div className="ad-surface-item__body_maxMin">
        <Input
          label="Max width"
          type="text"
          handleInput={(e: Event['Input']) => {
            isFormInvalid({ ...surface, maxWidth: e.target.value });
            updateSurface({ ...surface, maxWidth: e.target.value });
          }}
          value={surface.maxWidth || ''}
          errorMessage={errors?.maxWidth}
        />
        <Input
          label="Max height"
          type="text"
          handleInput={(e: Event['Input']) => {
            isFormInvalid({ ...surface, maxHeight: e.target.value });
            updateSurface({ ...surface, maxHeight: e.target.value });
          }}
          value={surface.maxHeight || ''}
          errorMessage={errors?.maxHeight}
        />
      </div>
      <Select
        label="Interactivity"
        value={AdSurfaceInteractivity[surface?.interactivity]}
        options={AdSurfaceInteractivity}
        showError
        errorMsg={errors?.interactivity}
        changeHandler={(index) => {
          updateSurface({ ...surface, interactivity: index });
        }}
        disabled={!allowCreateSurface}
      />
      {!surface.placeholder?.url || editPlaceholder ? (
        <FileUpload
          label="Placeholder File"
          setFiles={(files: File[]) => {
            if (isFieldInvalid('file', { file: files[0], adType: surface.adType })) return;
            updateSurface({ ...surface, file: files[0] });
            setEditPlaceholder(false);
          }}
          description={surface.file ? undefined : 'Upload Placeholder File'}
          errorMessage={errors?.file}
          handleChange={handleFileChange}
        />
      ) : (
        showImageVideo()
      )}
      <IabSelect
        label="Content Tags"
        placeholder="Select content tags"
        categories={surface.contentTags.map((tag) => tag.name)}
        onCategoriesChange={(_, categoriesWithData) => {
          const simplifiedCategories =
            categoriesWithData?.map(({ name, taxonomyContentId }) => ({ name, taxonomyContentId })) || [];
          if (
            simplifiedCategories
              .map((tag) => tag.name)
              .every((tag) => surface.contentTags.map((tag) => tag.name).includes(tag))
          )
            return;
          updateSurface({ ...surface, contentTags: simplifiedCategories });
        }}
        errorMsg={errors?.contentTags}
        disabled={!allowCreateSurface}
      />
    </div>
  );
};

export default AdSurfaceFields;
