import { useState } from 'react';
import BannerWithDropdown from '../../../../banner-with-dropdown/banner-with-dropdown';
import { GET_REGIONS_AND_COUNTRIES } from '../../../../../global/gql/queries';
import { GameFormData } from '../../types';
import { RegionsAndCountriesData } from '../../../../../global/interfaces';
import { useReneQuery } from '../../../../../hooks';

import './review-and-create-step.scss';
import Icon from '../../../../Icon/Icon';

type ReviewAndCreateStepProps = {
  formData: GameFormData;
};

const operatingSystemsToLabels = (operatingSystems: string[]): string[] => {
  const labels: Record<string, string> = {
    IOS: 'iOS',
    ANDROID: 'Android',
    WEB: 'Web',
  };

  return operatingSystems.map((os) => labels[os] || os);
};

const ReviewAndCreateStep = ({ formData }: ReviewAndCreateStepProps): JSX.Element => {
  const [copiedUrl, setCopiedUrl] = useState<string | null>(null);
  const { data: regionsAndCountries } = useReneQuery<{ RegionsAndCountries: RegionsAndCountriesData }>(
    GET_REGIONS_AND_COUNTRIES,
  );

  const countryMap = new Map<string, string>();
  regionsAndCountries?.RegionsAndCountries.items.forEach((region) => {
    region.countries.forEach((country) => {
      countryMap.set(country.code, country.name);
    });
  });

  const handleCopyUrl = (url: string): void => {
    navigator.clipboard.writeText(url);
    setCopiedUrl(url);
    setTimeout(() => setCopiedUrl(null), 2000);
  };

  const getCountryName = (code: string): string => {
    return countryMap.get(code) || code;
  };

  return (
    <div className="review-and-create-step">
      <div className="review-and-create-step__title">
        <h2>Review and create game</h2>
        <p>Review your game details and create when ready</p>
      </div>

      <div className="review-and-create-step__review">
        <BannerWithDropdown title="Basic Information">
          <p>
            <span className="property-name">Title:</span> {formData.basicInfo.title}
          </p>
          <p>
            <span className="property-name">Description:</span> {formData.basicInfo.description}
          </p>
          <p>
            <span className="property-name">Genre:</span> {formData.basicInfo.category}
          </p>
          {formData.basicInfo.iconImage && (
            <p>
              <span className="property-name">Icon Image:</span>{' '}
              <img src={URL.createObjectURL(formData.basicInfo.iconImage)} alt="icon" />
            </p>
          )}
          {formData.basicInfo.coverImage && (
            <p>
              <span className="property-name">Cover Image:</span>{' '}
              <img src={URL.createObjectURL(formData.basicInfo.coverImage)} alt="cover" />
            </p>
          )}
        </BannerWithDropdown>

        <BannerWithDropdown title="Game Details">
          <p>
            <span className="property-name">Operating Systems:</span>{' '}
            {operatingSystemsToLabels(formData.gameDetails.operatingSystems).join(', ')}
          </p>

          <div className="review-and-create-step__urls">
            {formData.gameDetails.iosData?.url && (
              <div className="url-container">
                <span className="property-name">AppStore URL:</span>{' '}
                <a href={formData.gameDetails.iosData.url} target="_blank" rel="noopener noreferrer">
                  {formData.gameDetails.iosData.url}
                </a>
                <button
                  type="button"
                  className="copy-button"
                  onClick={() => handleCopyUrl(formData.gameDetails.iosData?.url || '')}
                  title="Copy URL"
                  aria-label="Copy AppStore URL"
                >
                  <Icon name="copy" size={16} />
                  {copiedUrl === formData.gameDetails.iosData.url && <span className="copied-tooltip">Copied!</span>}
                </button>
              </div>
            )}
            {formData.gameDetails.androidData?.url && (
              <div className="url-container">
                <span className="property-name">PlayStore URL:</span>{' '}
                <a href={formData.gameDetails.androidData.url} target="_blank" rel="noopener noreferrer">
                  {formData.gameDetails.androidData.url}
                </a>
                <button
                  type="button"
                  className="copy-button"
                  onClick={() => handleCopyUrl(formData.gameDetails.androidData?.url || '')}
                  title="Copy URL"
                  aria-label="Copy PlayStore URL"
                >
                  <Icon name="copy" size={16} />
                  {copiedUrl === formData.gameDetails.androidData.url && (
                    <span className="copied-tooltip">Copied!</span>
                  )}
                </button>
              </div>
            )}
            {formData.gameDetails.webData?.url && (
              <div className="url-container">
                <span className="property-name">Web URL:</span>{' '}
                <a href={formData.gameDetails.webData.url} target="_blank" rel="noopener noreferrer">
                  {formData.gameDetails.webData.url}
                </a>
                <button
                  type="button"
                  className="copy-button"
                  onClick={() => handleCopyUrl(formData.gameDetails.webData?.url || '')}
                  title="Copy URL"
                  aria-label="Copy Web URL"
                >
                  <Icon name="copy" size={16} />
                  {copiedUrl === formData.gameDetails.webData.url && <span className="copied-tooltip">Copied!</span>}
                </button>
              </div>
            )}
          </div>

          <p>
            <span className="property-name">Genre:</span> {formData.basicInfo.category}
          </p>
          <p>
            <span className="property-name">IAB Categories:</span>{' '}
            {formData.gameDetails.iabCategories?.map((c) => c.name)?.join(', ')}
          </p>

          <div className="review-and-create-step__reach">
            <h4>Reach Data:</h4>
            <div className="review-and-create-step__reach__data-grid">
              <div className="grid-row">
                <div className="grid-label">MAU:</div>
                <div className="grid-value">
                  <div>{formData.gameDetails.monthlyActiveUsers?.toLocaleString() || 'Not specified'}</div>
                </div>
              </div>

              <div className="grid-row">
                <div className="grid-label">Top countries:</div>
                <div className="grid-value">
                  {formData.gameDetails.topCountryDistribution &&
                  formData.gameDetails.topCountryDistribution.length > 0 ? (
                    <div className="country-grid">
                      {formData.gameDetails.topCountryDistribution
                        .filter((country) => country.countryCode && country.monthlyActiveUsers)
                        .map((country, index) => (
                          <div className="country-column" key={index}>
                            <div className="country-name">{getCountryName(country.countryCode)}</div>
                            <div className="country-mau">{country.monthlyActiveUsers?.toLocaleString()}</div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    'None specified'
                  )}
                </div>
              </div>
            </div>
          </div>
        </BannerWithDropdown>
      </div>
    </div>
  );
};

export default ReviewAndCreateStep;
