import { useCallback, useEffect, useState } from 'react';
import { UserRole } from '../../../global/consts';
import { GET_AD_OFFERS, GET_USER_QUERY } from '../../../global/gql/queries';
import { AdOffer, AdOffers, UserData } from '../../../global/interfaces';
import { useLazyReneQuery } from '../../../hooks';
import { useReneQuery } from '../../../hooks/useReneQuery';
import { isRoleAllowed } from '../../../utils';
import { CreateOfferModal } from '../../../components/modal/create-offer-modal/create-offer-modal';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import Search from '../../../components/search/search';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import SmallDashboardBar from '../../../components/small-dashboard-bar/small-dashboard-bar';

import './organization-offers-page.scss';

type SingleOfferProps = {
  offer: AdOffer;
};

const SingleOffer = ({ offer }: SingleOfferProps) => {
  return (
    <div className="single-offer">
      <SmallDashboardBar
        id={offer.adOffer?.adOfferId}
        key={offer.userAdOfferId}
        linkTo={`/org/offers/${offer.adOffer?.adOfferId}`}
        name={offer.adOffer?.title || 'Untitled Offer'}
        showImage={false}
        description={offer.adOffer?.brand?.name || ''}
      >
        <div className="small-dashboard-bar__offer">
          <div className="small-dashboard-bar__offer_stats">
            <p className={offer.isViewed ? 'viewed' : 'not-viewed'}>
              {offer.isViewed ? 'Viewed' : 'Not Viewed'}
              <Icon name="eye" />
            </p>
            {offer.adOffer?.buttonUrl && (
              <p>
                CTA
                <Icon name="link" />
              </p>
            )}
            <p>
              <Icon name="mail" />
            </p>
          </div>
        </div>
      </SmallDashboardBar>
    </div>
  );
};

const OrganizationOffersPage = () => {
  const [offerSearchTerm, setSearchTerm] = useState('');
  const [isNewOfferModalOpen, setIsNewOfferModalOpen] = useState<boolean>(false);

  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);
  const { data, loading: offersLoading } = useReneQuery<{ UserAdOffers: AdOffers } | undefined>(GET_AD_OFFERS);

  const [search, { data: searchOffers, loading: searchLoading }] = useLazyReneQuery<{
    UserAdOffers: AdOffers;
  }>(GET_AD_OFFERS);

  useEffect(() => {
    if (offerSearchTerm) {
      search({ variables: { input: { adOfferSearchTerm: offerSearchTerm } } });
    }
  }, [offerSearchTerm, search]);

  const handleSearch = useCallback((data: string) => {
    setSearchTerm(data);
  }, []);

  const isUserAllowed = isRoleAllowed(user?.User.role as UserRole, [UserRole.ADVERTISER]);
  const showOfferData =
    offerSearchTerm && searchOffers ? searchOffers.UserAdOffers.items : data?.UserAdOffers?.items || [];

  return (
    <div className="organization-offers-page">
      <div className="organization-offers-page__heading">
        <div className="organization-offers-page__heading_title">
          <h1>Offers</h1>
        </div>
        <div className="organization-offers-page__heading_actions">
          <Search callback={handleSearch} apiSearch />
          {isUserAllowed && (
            <button
              type="button"
              className="btn-primary-solid organization-offers-page__heading_actions_new"
              onClick={() => setIsNewOfferModalOpen(true)}
            >
              <Icon name="plus" size={24} />
              <p>New Offer</p>
            </button>
          )}
        </div>
      </div>
      <div className="organization-offers-page__content">
        <div className="organization-offers-page__content_offers">
          {offersLoading || searchLoading ? (
            <LoadingFallback />
          ) : (
            <div className="offers-list">
              {showOfferData.length > 0 ? (
                showOfferData.map((offer) => <SingleOffer key={offer.userAdOfferId} offer={offer} />)
              ) : (
                <div className="no-offers">
                  <p>No offers found</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={isNewOfferModalOpen}>
        <CreateOfferModal adCampaignId="default" setIsModalOpen={() => setIsNewOfferModalOpen(false)} />
      </Modal>
    </div>
  );
};

export default OrganizationOffersPage;
