import { useState } from 'react';
import { Link } from 'react-router-dom';
import { AdData, Dispatcher } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import './preview-offer-modal.scss';

const PreviewOfferModal = ({ ad, setIsModalOpen }: { ad: AdData; setIsModalOpen: Dispatcher<string> }) => {
  const [tab, setTab] = useState<'desktop' | 'mobile'>('desktop');

  const handleModalClose = () => {
    setIsModalOpen('');
  };

  return (
    <div className="preview-offer-modal">
      <div className="preview-offer-modal__heading">
        <h1>Preview Offer</h1>
        <button type="button" onClick={handleModalClose}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="preview-offer-modal__tabs tabs">
        <button type="button" className={`${tab === 'desktop' ? '--selected' : ''}`} onClick={() => setTab('desktop')}>
          Desktop
        </button>
        <button type="button" className={`${tab === 'mobile' ? '--selected' : ''}`} onClick={() => setTab('mobile')}>
          Mobile
        </button>
      </div>
      <div className={`preview-offer-modal__content --${tab}`}>
        <h2>{ad.adOffer?.title}</h2>
        {/* <div className="preview-offer-modal__content_sender">
          <img src={ad.adOffer?.adOfferImage?.url} alt="" />
          <span>{ad.adOffer?.message}</span>
        </div> */}
        <img src={ad.adOffer?.adOfferImage?.url} alt="" />
        <p>{ad.adOffer?.message}</p>
        {ad.adOffer?.buttonText && (
          <Link to={ad.adOffer?.buttonUrl} target="_blank" className="btn-primary-solid">
            {ad.adOffer?.buttonText}
          </Link>
        )}
      </div>
    </div>
  );
};

export default PreviewOfferModal;
