import { AdType } from '../../../../global/consts';
import { commonValidations } from '../../../../global/validations';

export const validationsAds = {
  files: (
    form: {
      files: File[];
      uploadedFileNames?: string[];
    },
    key: 'files',
  ) => {
    const files = Array.from(form?.[key] || []);
    if (!files.length) return 'This field is required';
    const uploadedFiles = form?.uploadedFileNames || [];

    let errorMessage = '';
    files.forEach(({ name }) => {
      if (uploadedFiles.includes(name)) errorMessage = 'The file(s) already exists';
    });
    if (errorMessage) return errorMessage;

    const hasImages = files.some((file) => file.type.startsWith('image/'));
    const hasVideos = files.some((file) => file.type.startsWith('video/'));

    if (hasImages && hasVideos) {
      return 'You can only upload either images or videos, not both';
    }

    const totalSize = files.reduce((sum, file) => sum + file.size, 0);
    const maxSizeInBytes = hasVideos ? 10 * 1024 * 1024 : 5 * 1024 * 1024; // 10MB for videos, 5MB for images

    if (totalSize > maxSizeInBytes) {
      return `Total file size must be below ${hasVideos ? '10MB' : '5MB'}`;
    }

    return errorMessage;
  },
};

export const validationsBrandedObjects = {
  name: commonValidations.requiredTrimmed(),
  adType: commonValidations.required(),
  model: (form: { [k: string]: any }, key: 'file') => {
    if (form?.adType === AdType.THREE_D) {
      if (!form?.[key]) return 'This field is required';
      const extension = form?.[key]?.name.split('.').pop();
      if (extension !== 'obj') return 'You must provide a valid image (obj) file';
    }
  },
  texture: (form: { [k: string]: any }, key: 'file') => {
    if (form?.adType === AdType.THREE_D) {
      return commonValidations.fileImage('You must provide a valid image (png) file', ['image/png'])(form, key);
    }
  },
  preview: commonValidations.fileImage('You must provide a valid image (jpg, jpeg) file', ['image/jpeg', 'image/jpg']),
};
