import React from 'react';

type ShowPopUpMessage = React.Dispatch<React.SetStateAction<string>>;

export const UpdatePopUpContext = React.createContext<
  { updatePopUpMessage: string; showUpdatePopUpMessage: ShowPopUpMessage } | undefined
>(undefined);

export function useUpdatePopUpContext() {
  const context = React.useContext(UpdatePopUpContext);
  if (context === undefined) {
    throw new Error('useUpdatePopUpContext must be used within a NotificationProvider');
  }
  return context;
}
