import { Link } from 'react-router-dom';
import { GameData } from '../../global/interfaces';
import { AD_PLACEMENT, GAMES, ORG } from '../../global/routes';

import './game-card.scss';

const GameCard = ({ game }: { game: GameData }) => (
  <Link to={`/${ORG}/${GAMES}/${game.gameId}/${AD_PLACEMENT}`}>
    <div className="game-card">
      <img src={game.coverImage?.url ?? game.image.url} alt={game.name} className="game-card__image" />
      <div className="game-card__content">
        <p className="game-card__id">ID: {game.gameId}</p>
        <h3 className="game-card__title">{game.name}</h3>
      </div>
    </div>
  </Link>
);

export default GameCard;
