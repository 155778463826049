import { useState, useRef, useEffect } from 'react';
import Icon from '../Icon/Icon';
import SmallErrorMsg from '../small-error-msg/small-error-msg';
import Spinner from '../spinner/spinner';
import './searchable-multi-select.scss';

interface SearchableMultiSelectProps<T> {
  options: T[] | string[];
  selectedValues?: (T | string)[] | T | string;
  onChange: (selected: (T | string)[] | T | string) => void;
  labelKey?: keyof T;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  label?: string;
  errorMessage?: string;
  apiSearch?: boolean;
  onSearch?: (searchTerm: string) => void;
  loading?: boolean;
  selectedLabels?: Record<string, string>;
  getOptionId?: (option: T | string) => string;
}

export const SearchableMultiSelect = <T extends Record<string, any>>({
  options,
  selectedValues = [],
  onChange,
  labelKey = 'label',
  placeholder = 'Select options',
  className = '',
  disabled = false,
  multiSelect = false,
  label,
  errorMessage,
  apiSearch = false,
  onSearch,
  loading = false,
  selectedLabels = {},
  getOptionId = (option: T | string) => (typeof option === 'string' ? option : JSON.stringify(option)),
}: SearchableMultiSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Helper function to get a unique identifier for an option
  const getOptionIdentifier = (option: T | string): string => {
    return getOptionId(option);
  };

  // Helper function to check if an option is selected
  const isOptionSelected = (option: T | string): boolean => {
    const optionId = getOptionIdentifier(option);

    if (Array.isArray(selectedValues)) {
      return selectedValues.some((selected) => getOptionIdentifier(selected) === optionId);
    }
    if (selectedValues) {
      return getOptionIdentifier(selectedValues) === optionId;
    }

    return false;
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
        setSearchTerm('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    if (apiSearch && onSearch) {
      onSearch(searchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const handleOptionClick = (option: T | string) => {
    let updatedSelection: (T | string)[] | T | string;

    if (multiSelect) {
      let currentSelection: (T | string)[] = [];

      if (Array.isArray(selectedValues)) {
        currentSelection = selectedValues;
      } else if (selectedValues) {
        currentSelection = [selectedValues];
      }

      if (isOptionSelected(option)) {
        updatedSelection = currentSelection.filter(
          (selected) => getOptionIdentifier(selected) !== getOptionIdentifier(option),
        );
      } else {
        updatedSelection = [...currentSelection, option];
      }
    } else {
      updatedSelection = isOptionSelected(option) ? '' : option;
    }

    onChange(updatedSelection);
    setIsOpen(false);
    setSearchTerm('');
  };

  const handleRemoveValue = (valueToRemove: T | string, e: React.MouseEvent) => {
    e.stopPropagation();
    let updatedSelection: (T | string)[] | T | string;

    if (multiSelect) {
      updatedSelection = Array.isArray(selectedValues)
        ? selectedValues.filter((value) => getOptionIdentifier(value) !== getOptionIdentifier(valueToRemove))
        : [];
    } else {
      updatedSelection = '';
    }

    onChange(updatedSelection);
  };

  const filteredOptions = apiSearch
    ? (options as (T | string)[])
    : (options as (T | string)[]).filter((option: T | string) => {
        const label = getOptionLabel(option);
        return label.toLowerCase().includes(searchTerm.toLowerCase());
      });

  const getOptionLabel = (option: T | string): string => {
    const id = getOptionIdentifier(option);

    if (selectedLabels[id]) {
      return selectedLabels[id];
    }

    if (typeof option === 'string') {
      return option;
    }
    return String(option[labelKey!]);
  };

  return (
    <div className={`searchable-multi-select ${className} ${disabled ? 'disabled' : ''}`} ref={containerRef}>
      {label && <label className="searchable-multi-select__label">{label}</label>}
      <div
        className={`searchable-multi-select__trigger ${isOpen ? 'open' : ''}`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        {!isOpen && (
          <div className="searchable-multi-select__selected">
            {selectedValues && (Array.isArray(selectedValues) ? selectedValues.length > 0 : selectedValues) ? (
              <div className="searchable-multi-select__tags">
                {(Array.isArray(selectedValues) ? selectedValues : [selectedValues]).filter(Boolean).map((value) => {
                  const label = getOptionLabel(value);
                  const key = getOptionIdentifier(value);

                  return (
                    <span key={key} className="searchable-multi-select__tag">
                      {label}
                      <button
                        type="button"
                        className="searchable-multi-select__remove"
                        onClick={(e) => handleRemoveValue(value, e)}
                      >
                        <Icon name="close" />
                      </button>
                    </span>
                  );
                })}
              </div>
            ) : (
              <span>{placeholder}</span>
            )}
          </div>
        )}
        {isOpen && (
          <input
            ref={inputRef}
            type="text"
            className="searchable-multi-select__search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            placeholder="Type to search..."
          />
        )}
        <span className="searchable-multi-select__arrow" />
      </div>

      {isOpen && !disabled && (
        <div className="searchable-multi-select__options">
          {loading && (
            <div className="searchable-multi-select__loading">
              <Spinner size="sm" />
            </div>
          )}
          {!loading && filteredOptions.length === 0 && (
            <div className="searchable-multi-select__no-results">No results found</div>
          )}
          {!loading &&
            filteredOptions.length > 0 &&
            filteredOptions.map((option: T | string) => {
              const key = getOptionIdentifier(option);
              const label = getOptionLabel(option);

              return (
                <button
                  type="button"
                  key={key}
                  className={`searchable-multi-select__option ${isOptionSelected(option) ? 'selected' : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {label}
                </button>
              );
            })}
        </div>
      )}
      <SmallErrorMsg message={errorMessage} />
    </div>
  );
};
