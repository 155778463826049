import { useCallback, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { validations as getValidations } from './validations';
import { EventTypes } from '../../../global/consts';
import { TRACK_AUTH_EVENT_MUTATIONS, UPSERT_GAME_MUTATION } from '../../../global/gql/mutations';
import { GET_ORG_GAMES_QUERY, GET_ORG_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { GameData, UserData } from '../../../global/interfaces';
import { AD_PLACEMENT, GAMES, ORG } from '../../../global/routes';
import { useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { useFileUpload } from '../../../hooks/useFileUpload';
import { useProdEffect } from '../../../hooks/useProdEffect';
import { GameFormData } from './types';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import GameDetailsStep from './steps/game-details-step/game-details-step';
import ReviewAndCreateStep from './steps/review-and-create-step/review-and-create-step';
import BasicInformationStep from './steps/basic-information-step/basic-information-step';

import './new-game-modal.scss';

const steps = [
  { title: 'Basic Information', order: 1 },
  { title: 'Game Details', order: 2 },
  { title: 'Review and Create', order: 3 },
];

const NewGameModal = ({ setCloseModal }: { setCloseModal: () => void }) => {
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const uploadFile = useFileUpload();
  const [loading, setLoading] = useState<boolean>(false);
  const [gameId, setGameId] = useState<string | null>(null);

  const [formData, setFormData] = useState<GameFormData>({
    basicInfo: {
      title: '',
      description: '',
      category: '',
      iconImage: undefined,
      coverImage: undefined,
      iconImageURLFromBackend: undefined,
      coverImageURLFromBackend: undefined,
    },
    gameDetails: {
      operatingSystems: [],
      iosData: { bundleId: '', url: '' },
      androidData: { url: '', packageName: '' },
      webData: { url: '' },
      iabCategories: [],
      monthlyActiveUsers: undefined,
      topCountryDistribution: [],
    },
  });

  const { isFormInvalid, errors } = useValidation(getValidations(formData.gameDetails.operatingSystems));
  const { data: user } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const [trackEvent] = useReneMutation(TRACK_AUTH_EVENT_MUTATIONS, {
    onError() {},
  });

  const [upsertGame] = useReneMutation(UPSERT_GAME_MUTATION, {
    onCompleted: async (data: { UpsertGame: GameData }) => {
      if (!gameId) {
        setGameId(data.UpsertGame.gameId);
      }

      const iconImageURL = data.UpsertGame.image.url || '';
      const coverImageURL = data.UpsertGame.coverImage?.url || '';

      if (iconImageURL || coverImageURL) {
        updateFormData('basicInfo', {
          iconImageURLFromBackend: iconImageURL,
          coverImageURLFromBackend: coverImageURL,
        });
      }

      if (
        (!data.UpsertGame.image.fileId && formData.basicInfo.iconImage) ||
        (!data.UpsertGame.coverImage?.fileId && formData.basicInfo.coverImage)
      ) {
        const uploads: Promise<void | Response>[] = [];
        if (formData.basicInfo.iconImage) {
          uploads.push(uploadFile(data.UpsertGame.image.uploadUrl, formData.basicInfo.iconImage));
        }
        if (data.UpsertGame.coverImage?.uploadUrl && formData.basicInfo.coverImage) {
          uploads.push(uploadFile(data.UpsertGame.coverImage.uploadUrl, formData.basicInfo.coverImage));
        }
        await Promise.all(uploads);
      }
      setLoading(false);

      if (activeStep === steps.length) {
        setCloseModal();
        if (location.pathname === `/${ORG}`) {
          navigate(`${GAMES}/${data.UpsertGame.gameId}/${AD_PLACEMENT}`);
        }
      } else {
        setActiveStep((prev) => Math.min(prev + 1, steps.length));
      }
    },
    refetchQueries: [GET_ORG_GAMES_QUERY, GET_ORG_QUERY],
  });

  useProdEffect(() => {
    if (user?.User.email) {
      trackEvent({
        variables: {
          distinctId: user.User.email,
          eventType: EventTypes.GAME_CREATE_STARTED,
        },
      });
    }
  }, []);

  const updateFormData = useCallback(<K extends keyof GameFormData>(step: K, data: Partial<GameFormData[K]>) => {
    setFormData((prev) => ({
      ...prev,
      [step]: { ...prev[step], ...data },
    }));
  }, []);

  const handleSubmit = async () => {
    if (activeStep === steps.length) {
      setCloseModal();
      return;
    }

    const formToValidate: Record<string, any> = {
      title: formData.basicInfo.title,
      description: formData.basicInfo.description,
      iconImage: formData.basicInfo.iconImage,
      coverImage: formData.basicInfo.coverImage,
      category: formData.basicInfo.category,
      ...(formData.gameDetails.operatingSystems.includes('IOS') && {
        iosUrl: formData.gameDetails.iosData?.url,
        iosBundleId: formData.gameDetails.iosData?.bundleId,
      }),
      ...(formData.gameDetails.operatingSystems.includes('ANDROID') && {
        androidUrl: formData.gameDetails.androidData?.url,
        androidPackageName: formData.gameDetails.androidData?.packageName,
      }),
      ...(formData.gameDetails.operatingSystems.includes('WEB') && {
        webUrl: formData.gameDetails.webData?.url,
      }),
      operatingSystems: formData.gameDetails.operatingSystems,
      monthlyActiveUsers: formData.gameDetails.monthlyActiveUsers,
      topCountryDistribution: formData.gameDetails.topCountryDistribution,
    };

    if (isFormInvalid(formToValidate)) {
      return;
    }

    const variables = {
      ...(gameId ? { gameId } : {}),
      approvedUserScopes: 'user_all',
      name: formData.basicInfo.title.trim(),
      description: formData.basicInfo.description.trim(),
      image: { extension: formData.basicInfo.iconImage?.type.split('/')[1] },
      coverImage: { extension: formData.basicInfo.coverImage?.type.split('/')[1] },
      isActive: true,
      category: formData.basicInfo.category,
      monthlyActiveUsers: formData.gameDetails.monthlyActiveUsers ?? 0,
      iosData: formData.gameDetails.operatingSystems.includes('IOS')
        ? {
            url: formData.gameDetails.iosData?.url,
            bundleId: formData.gameDetails.iosData?.bundleId,
          }
        : undefined,
      androidData: formData.gameDetails.operatingSystems.includes('ANDROID')
        ? {
            url: formData.gameDetails.androidData?.url,
            packageName: formData.gameDetails.androidData?.packageName,
          }
        : undefined,
      webData: formData.gameDetails.operatingSystems.includes('WEB')
        ? {
            url: formData.gameDetails.webData?.url,
          }
        : undefined,
      contentTags: formData.gameDetails.iabCategories?.map((c) => ({
        name: c.name,
        taxonomyContentId: c.taxonomyContentId,
      })),
      topCountryDistribution: formData.gameDetails.topCountryDistribution,
    };

    setLoading(true);
    await upsertGame({ variables });
  };

  const getButtonText = () => {
    if (loading) return <Spinner size="sm" />;
    if (activeStep < steps.length) return 'Next';
    return 'Create';
  };

  const handleBack = () => {
    if (activeStep > 1) {
      setActiveStep((prev) => prev - 1);
    }
  };

  return (
    <div className="new-game-modal full-size">
      <div className="new-game-modal__close-button">
        <button type="button" onClick={setCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="new-game-modal__heading">
        <div>
          <h1>Create a New Game</h1>
        </div>
      </div>

      <aside className="new-game-modal__aside">
        {steps.map((stepItem) => (
          <button
            key={stepItem.order}
            type="button"
            disabled
            className={`new-game-modal__aside-item ${
              activeStep === stepItem.order ? 'new-game-modal__aside-item--active' : ''
            } ${activeStep > stepItem.order ? 'new-game-modal__aside-item--completed' : ''}`}
          >
            <div>{activeStep <= stepItem.order ? <p>{stepItem.order}</p> : <Icon name="checkmark" size={24} />}</div>
            <p>{stepItem.title}</p>
          </button>
        ))}
      </aside>

      <div className="new-game-modal__main">
        <div className="new-game-modal__main-content">
          {activeStep === 1 && (
            <BasicInformationStep updateFormData={updateFormData} formData={formData} errors={errors} />
          )}
          {activeStep === 2 && <GameDetailsStep updateFormData={updateFormData} formData={formData} errors={errors} />}
          {activeStep === 3 && <ReviewAndCreateStep formData={formData} />}
        </div>

        <div className="new-game-modal__main-footer">
          <div>
            <button className="btn-neutral-ghost" onClick={setCloseModal} type="button">
              Cancel
            </button>
          </div>
          <div>
            {activeStep !== 1 && (
              <button className="btn-primary-ghost" onClick={handleBack} type="button">
                Back
              </button>
            )}
            <button className="btn-primary-solid fixed-width" onClick={handleSubmit} disabled={loading} type="button">
              {getButtonText()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewGameModal;
