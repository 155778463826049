import React, { useEffect, useState } from 'react';
import { UpdatePopUpContext } from './use-update-message-context';

type CountProviderProps = { children: React.ReactNode };

export function UpdatePopUpProvider({ children }: CountProviderProps) {
  const [updatePopUpMessage, showUpdatePopUpMessage] = useState<string>('');

  useEffect(() => {
    let errorInterval: NodeJS.Timeout;
    if (updatePopUpMessage) {
      errorInterval = setInterval(() => {
        showUpdatePopUpMessage('');
      }, 10000);
    }
    return () => {
      clearInterval(errorInterval);
    };
  }, [updatePopUpMessage]);
  const value = React.useMemo(() => ({ updatePopUpMessage, showUpdatePopUpMessage }), [updatePopUpMessage]);
  return <UpdatePopUpContext.Provider value={value}>{children}</UpdatePopUpContext.Provider>;
}
