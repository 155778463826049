import './statistics.scss';

interface Props {
  data: { [key: string]: string | undefined };
  className?: string;
  children?: JSX.Element;
}

const Statistics: React.FC<Props> = ({ data, children, className }) => {
  function splitCamelCase(str: string) {
    return (
      str
        // Insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // Lowercase the entire string
        .toLowerCase()
        // Uppercase the first character
        .replace(/^./, (str) => str.toUpperCase())
    );
  }
  const titles = Object.keys(data).map((key) => splitCamelCase(key));
  return (
    <div className={`statistics-container ${className}`}>
      {Object.keys(data).map((key: string, i) => (
        <hgroup key={key}>
          <h2>{titles[i]}</h2>
          <h3>{data[key]}</h3>
        </hgroup>
      ))}
      {children}
    </div>
  );
};

export default Statistics;
