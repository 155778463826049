import { useRef, useEffect } from 'react';
import { useDebouncedValue } from './useDebouncedValue';
import { autosaveInterval } from '../global/consts';

export function useAutosave({
  data,
  callback,
  interval = autosaveInterval,
  saveOnUnmount = true,
}: {
  data: unknown;
  callback: (arg?: unknown) => void;
  interval?: number;
  saveOnUnmount?: boolean;
}) {
  const valueOnCleanup = useRef(data);
  const initialRender = useRef(true);
  const onSave = useRef(callback);

  const debouncedValue = useDebouncedValue(data, interval);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      onSave?.current(debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(() => {
    valueOnCleanup.current = data;
  }, [data]);

  useEffect(() => {
    if (onSave?.current) {
      onSave.current = callback;
    }
  }, [callback]);

  useEffect(
    () => () => {
      if (saveOnUnmount && onSave?.current) {
        onSave.current(valueOnCleanup.current);
      }
    },
    [saveOnUnmount],
  );
}
