import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Event, OrganizationData } from '../../../global/interfaces';
import { TOKEN, orgTypeOptions } from '../../../global/consts';
import { GAMES, ORG } from '../../../global/routes';
import { validations } from './validations';
import { useFileUpload, useReneMutation, useValidation } from '../../../hooks';
import { REFRESH_TOKEN, UPDATE_FIRST_TIME_USER, UPSERT_ORG_MUTATION } from '../../../global/gql/mutations';
import Input from '../../../components/input/input';
import Select from '../../../components/select/select';
import Spinner from '../../../components/spinner/spinner';
import Textarea from '../../../components/textarea/textarea';
import FileUpload from '../../../components/file-upload/file-upload';

import reneLogo from '../../../global/images/rene-logo-new-big.png';
import image1 from './images/image1.png';
import image2 from './images/image2.png';
import image3 from './images/image3.png';

import './first-time-user-page.scss';

const SelectPath = ({ handlePathSelection }: { handlePathSelection: (path: 'org' | 'games') => void }) => {
  return (
    <div className="first-time-user-page__path">
      <h1>Here for business?</h1>
      <h2>Creating organization enables you and your team to:</h2>
      <div className="first-time-user-page__path_item">
        <img src={image1} alt="reneverse" />
        <p>Integrate Games</p>
      </div>
      <div className="first-time-user-page__path_item">
        <img src={image2} alt="reneverse" />
        <p>Run Borderless Ads</p>
      </div>
      <div className="first-time-user-page__path_item">
        <img src={image3} alt="reneverse" />
        <p>Create Branded Objects</p>
      </div>
      <div className="first-time-user-page__path_actions">
        <button type="button" className="btn-neutral-ghost" onClick={() => handlePathSelection('games')}>
          Nah, I’m just playing
        </button>
        <button type="button" className="btn-primary-solid" onClick={() => handlePathSelection('org')}>
          Create Organization
        </button>
      </div>
    </div>
  );
};

const FirstTimeUserPage = () => {
  const navigate = useNavigate();
  const uploadFile = useFileUpload();
  const [isCreateOrg, setIsCreateOrg] = useState(false);
  const { errors, isFormInvalid } = useValidation(validations);
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<{
    name: string;
    description: string;
    type: { title: string; description: string; key: string } | undefined;
    file: File | undefined;
  }>({
    name: '',
    description: '',
    file: undefined,
    type: undefined,
  });

  const [refreshToken] = useReneMutation(REFRESH_TOKEN, {
    async onCompleted(data: { RefreshToken: { jwt: string } }) {
      if (data.RefreshToken.jwt) {
        await localStorage.setItem(TOKEN, data.RefreshToken.jwt);
      }
    },
  });

  const [updateUser] = useReneMutation(UPDATE_FIRST_TIME_USER);

  const [addOrganization] = useReneMutation(UPSERT_ORG_MUTATION, {
    errorPolicy: 'all',
    onCompleted(data: { UpsertOrganization: OrganizationData }) {
      if (form.file) {
        uploadFile(data.UpsertOrganization.image.uploadUrl, form.file).finally(() => {
          refreshToken().then(() => {
            updateUser({
              variables: {
                isFirstTimeUser: false,
              },
            }).then(() => {
              setLoading(false);
              navigate(`/${ORG}`);
            });
          });
        });
      }
    },
  });

  const handleFormChange = (e: Event['Input'] | Event['TextArea'] | Event['Select']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handlePathSelection = (action: 'org' | 'games') => {
    if (action === 'org') {
      return setIsCreateOrg(true);
    }
    if (action === 'games') {
      updateUser({
        variables: {
          isFirstTimeUser: false,
        },
      });
      return navigate(`/${GAMES}`);
    }
  };

  const handleAddingNewOrg = () => {
    if (isFormInvalid(form)) return;
    const variables = {
      name: form.name,
      data: {
        description: form.description,
      },
      type: form.type?.key,
      image: { extension: form.file?.type.split('/')[1] },
      isActive: true,
    };
    setLoading(true);
    addOrganization({ variables });
  };

  return (
    <div className="first-time-user-page">
      <div className="first-time-user-page__logo">
        <img src={reneLogo} alt="reneverse" />
      </div>
      {!isCreateOrg ? (
        <SelectPath handlePathSelection={handlePathSelection} />
      ) : (
        <div className="first-time-user-page__form">
          <div className="first-time-user-page__form_heading">
            <h1>Create organization</h1>
          </div>
          <Input
            label="Name"
            name="name"
            placeholder="Enter organization name"
            handleInput={handleFormChange}
            value={form.name}
            errorMessage={errors?.name}
          />
          <Select
            label="Type"
            value={form.type?.title || ''}
            placeholder="Select organization type"
            options={orgTypeOptions}
            changeHandler={(value) => setForm((prev) => ({ ...prev, type: orgTypeOptions[value] }))}
            showListValueFn={(value) => (
              <div className="select__org-type">
                <p>{value.title}</p>
                <p>{value.description}</p>
              </div>
            )}
            errorMsg={errors?.type}
            showError
          />
          <Textarea
            label="About"
            name="description"
            value={form.description}
            handleInput={handleFormChange}
            placeholder="Enter organization description"
            showCounter
            maxLength={100}
            errorMessage={errors?.description}
          />
          <FileUpload
            setFiles={(files: File[]) => setForm((prev) => ({ ...prev, file: files?.[0] }))}
            label="Picture"
            errorMessage={errors?.file}
            description="Drop or click to upload 320 x 320px "
          />

          <div className="first-time-user-page__form_actions">
            <button type="button" className="btn-primary-solid" onClick={handleAddingNewOrg}>
              {loading ? <Spinner size="sm" /> : 'Create Organization'}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FirstTimeUserPage;
