import { ChangeEvent, useRef } from 'react';
import Icon from '../Icon/Icon';
import './inline-file-upload.scss';

interface InlineFileUploadProps {
  onFileSelect: (file: File) => void;
  accept?: string;
  maxSize?: number;
  className?: string;
  label?: string;
  placeholder?: string;
  hideErrorMessage?: boolean;
  errorMessage?: string;
}

const InlineFileUpload: React.FC<InlineFileUploadProps> = ({
  onFileSelect,
  accept = '.obj', // Default to .obj files
  maxSize = 10485760, // 10MB default
  className = '',
  label,
  placeholder = 'Select file...',
  hideErrorMessage = false,
  errorMessage,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files?.length) {
      validateAndProcessFile(files[0]);
    }
  };

  const validateAndProcessFile = (file: File) => {
    if (maxSize && file.size > maxSize) {
      // eslint-disable-next-line no-alert
      alert(`File size should not exceed ${maxSize / 1024 / 1024}MB`);
      return;
    }

    onFileSelect(file);
  };

  return (
    <div className={`inline-file-upload ${className}`}>
      <div className={`inline-file-upload__content ${errorMessage ? 'inline-file-upload__content--error' : ''}`}>
        <label>{label}</label>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileInput}
          accept={accept}
          className="inline-file-upload__content_input"
        />
        <p>{fileInputRef.current?.files?.[0]?.name || <span>{placeholder}</span>}</p>
        <button type="button" className="btn-neutral-ghost" onClick={() => fileInputRef.current?.click()}>
          Select file
        </button>
      </div>
      {!hideErrorMessage && (
        <div className="inline-file-upload__error">
          {errorMessage && <Icon name="alert" />}
          <p>{errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default InlineFileUpload;
