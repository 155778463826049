import { DocumentNode } from 'graphql';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import CampaignsList from '../../../../components/campaigns-list/campaigns-list';
import Icon from '../../../../components/Icon/Icon';
import LoadingFallback from '../../../../components/loading-fallback/loading-fallback';
import Modal from '../../../../components/modal/modal';
import TopUpCreditModal from '../../../../components/modal/top-up-credit-modal/top-up-credit-modal';
import TopUpsList from '../../../../components/top-ups-list/top-ups-list';
import { RESET_DEMO_ACCOUNT } from '../../../../global/gql/mutations';
import { GET_AD_CAMPAIGNS_QUERY } from '../../../../global/gql/queries';
import { CampaignsData, Dispatcher, OrganizationData, UserData } from '../../../../global/interfaces';
import { useReneMutation, useReneQuery } from '../../../../hooks';
import './credit.scss';

const isUserData = (data: unknown): data is { User: UserData } => (data as { User: UserData })?.User !== undefined;

const Credit = ({
  allowTopUp = true,
  query,
  redirectPage,
  setOpenMobileMenu,
}: {
  allowTopUp?: boolean;
  query: DocumentNode;
  redirectPage: string;
  setOpenMobileMenu?: Dispatcher<boolean>;
}) => {
  const location = useLocation();
  const [isTopupOpen, setIsTopupOpen] = useState(false);

  const [tab, setTab] = useState<'usageHistory' | 'topUpHistory'>('usageHistory');

  const {
    data,
    loading: userLoading,
    refetch,
  } = useReneQuery<{ User: UserData } | { Organization: OrganizationData }>(query, {
    fetchPolicy: 'network-only',
  });

  const mappedData = (data as { User: UserData })?.User || (data as { Organization: OrganizationData })?.Organization;

  const { data: campaignsData, loading: campaignsLoading } = useReneQuery<{ AdCampaigns: CampaignsData } | undefined>(
    GET_AD_CAMPAIGNS_QUERY,
  );

  const showResetButton = isUserData(data) && data.User.isDemo;

  const [resetDemoAccount, { loading: resetting }] = useReneMutation(RESET_DEMO_ACCOUNT, {
    onCompleted: () => {
      refetch();
    },
  });

  const mappedCampaignsData = campaignsData?.AdCampaigns?.items || [];

  const loading = userLoading || campaignsLoading;

  return (
    <div className="credit">
      <div className="credit__heading">
        {setOpenMobileMenu ? (
          <button type="button" onClick={() => setOpenMobileMenu(true)}>
            <Icon name="hamburger" />
          </button>
        ) : null}
        <h1>Credit</h1>
        <div className="credit__heading__balance">
          <div className="credit__heading__balance__amount">
            <p>Balance:</p>
            <p className="credit__heading__balance__amount__total-balance">${mappedData?.balance?.balance || 0}</p>
          </div>
          {allowTopUp && (
            <div>
              <button type="button" className="btn-neutral-solid" onClick={() => setIsTopupOpen(true)}>
                Top-Up
              </button>
            </div>
          )}
          {showResetButton && (
            <div>
              <button type="button" className="secondary-btn" onClick={() => resetDemoAccount()} disabled={resetting}>
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="credit__main">
        <div className="credit__main_actions">
          {/* <div className="credit__main_actions_buttons">
          </div> */}
          {location.hash && (
            <div
              className={`credit__main_actions_status ${
                location.hash === '#success'
                  ? 'credit__main_actions_status--success'
                  : 'credit__main_actions_status--fail'
              }`}
            >
              <Icon name={location.hash === '#success' ? 'circle-check' : 'alert'} />
              <p>{location.hash === '#success' ? 'Top up success' : 'Top up failed'}</p>
            </div>
          )}
          <div className="credit__main_actions_tabs">
            <button
              type="button"
              className={`${tab === 'usageHistory' ? '_selected' : ''}`}
              onClick={() => setTab('usageHistory')}
            >
              Usage history
            </button>
            <button
              type="button"
              className={`${tab === 'topUpHistory' ? '_selected' : ''}`}
              onClick={() => setTab('topUpHistory')}
            >
              Top-up history
            </button>
          </div>
        </div>
        <div className="credit__main_history">
          {loading && <LoadingFallback />}
          {!loading && tab === 'usageHistory' && (
            <CampaignsList
              campaigns={mappedCampaignsData}
              userIsDemo={mappedData.isDemo}
              showImage={false}
              showAdsDetails
            />
          )}
          {!loading && tab === 'topUpHistory' && (
            <TopUpsList transactions={mappedData?.balance?.transactions?.items || []} />
          )}
        </div>
      </div>
      <Modal isOpen={isTopupOpen}>
        <TopUpCreditModal
          balance={mappedData?.balance?.balance}
          setIsModalOpen={setIsTopupOpen}
          redirectPage={redirectPage}
          refetch={refetch}
        />
      </Modal>
    </div>
  );
};

export default Credit;
