import { Dispatcher } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import './delete-modal.scss';

interface Props {
  setIsModalOpen: Dispatcher<boolean>;
  deleteHandler: Dispatcher<any>;
  isLoading?: boolean;
}

const DeleteModal: React.FC<Props> = ({ setIsModalOpen, deleteHandler, isLoading = false }) => {
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="delete_modal">
      <div className="delete_modal__heading">
        <button type="button" onClick={handleModalClose}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <Icon
        name="trash"
        size={24}
        bgInfo={{
          size: 64,
          type: 'danger',
        }}
      />
      <h2>Delete API Key</h2>
      <p>Are you sure you want to delete this API Key?</p>
      <div className="delete_modal__actions">
        <button type="button" className="btn-neutral-ghost" onClick={handleModalClose} disabled={isLoading}>
          Cancel
        </button>
        <button type="button" className="btn-negative-solid" onClick={deleteHandler} disabled={isLoading}>
          {isLoading ? <Spinner size="sm" /> : 'Delete'}
        </button>
      </div>
    </div>
  );
};

export default DeleteModal;
