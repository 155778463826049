import { useState } from 'react';
import { ApolloCache, DefaultContext, MutationFunctionOptions } from '@apollo/client';
import { ChainData, Dispatcher, Event } from '../../../global/interfaces';
import { validations } from './validations';
import { useValidation } from '../../../hooks/useValidation';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Toggle from '../../toggle/toggle';
import Spinner from '../../spinner/spinner';
import './generate-api-credentials-modal.scss';

const GenerateApiCredentialsModal = ({
  gameId,
  chain,
  loading,
  generateCred,
  setIsModalOpen,
}: {
  gameId: string;
  chain: ChainData | undefined;
  loading: boolean;
  generateCred: (
    options?: MutationFunctionOptions<any, Record<string, any>, DefaultContext, ApolloCache<any>> | undefined,
  ) => any;
  setIsModalOpen: Dispatcher<boolean>;
}) => {
  const { isFormInvalid, errors } = useValidation(validations);

  const [form, setForm] = useState({
    isTestNet: false,
    isSetExpiry: false,
    expiry: '',
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleToggle = (e: Event['Input']) => {
    const field = e.currentTarget.id;
    setForm((prev) => ({ ...prev, [field]: e.target.checked }));
  };

  const handleGenerateCred = () => {
    if (isFormInvalid(form)) {
      return;
    }
    let variables: {
      gameId: string;
      type: string;
      isTestNet?: boolean;
      expiry?: string;
    } = {
      gameId,
      type: 'GAME',
    };

    variables = chain ? { ...variables, isTestNet: form.isTestNet } : variables;
    variables = form.expiry ? { ...variables, expiry: Date.parse(form.expiry).toString() } : variables;
    generateCred({
      variables,
    });
  };

  return (
    <div className="generate-api-credentials-modal">
      <div className="generate-api-credentials-modal__header">
        <h2>Generate Api Credentials</h2>
        <button type="button" onClick={handleCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="generate-api-credentials-modal__main">
        {chain ? (
          <div className="generate-api-credentials-modal__main_toggle">
            <label>Test Net</label>
            <Toggle id="isTestNet" checked={form.isTestNet} setValue={handleToggle} />
          </div>
        ) : null}
        <div className="generate-api-credentials-modal__main_toggle">
          <label>Expiry</label>
          <Toggle id="isSetExpiry" checked={form.isSetExpiry} setValue={handleToggle} />
        </div>
        {form.isSetExpiry ? (
          <Input
            type="date"
            name="expiry"
            value={form.expiry}
            handleInput={(e) => setForm((prev) => ({ ...prev, expiry: e.target.value }))}
            placeholder="Expiry"
            errorMessage={errors?.expiry}
          />
        ) : null}
      </div>
      <div className="generate-api-credentials-modal__actions">
        <button type="button" className="btn-neutral-ghost" onClick={handleCloseModal}>
          Cancel
        </button>
        <button type="button" className="btn-primary-solid" onClick={handleGenerateCred}>
          {loading ? <Spinner size="sm" /> : 'Generate'}
        </button>
      </div>
    </div>
  );
};

export default GenerateApiCredentialsModal;
