import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLazyReneQuery, useReneQuery } from '../../../hooks';
import { GET_ORG_QUERY, GET_USER_QUERY } from '../../../global/gql/queries';
import { Dispatcher, Event, OrganizationData, UserData } from '../../../global/interfaces';
import { ORG, SETTINGS } from '../../../global/routes';
import { TokenType, UserRole } from '../../../global/consts';
import Icon from '../../../components/Icon/Icon';
import Modal from '../../../components/modal/modal';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import NewOrganizationModal from '../../../components/modal/new-organization-modal/new-organization-modal';
import LeaveOrganizationModal from '../../../components/modal/leave-organization-modal/leave-organization-modal';

import placeholder from '../../../global/images/avatar.webp';

import './manage-organizations-page.scss';

const NoOrganization = () => (
  <div className="manage-organization-page__section_main_empty">
    <Icon name="chatbot" size={80} />
    <p>No organization</p>
  </div>
);

const Organization = ({
  title,
  isOrgOwner,
  description,
  profilePicture,
  handleOpenModal,
}: {
  title: string;
  isOrgOwner: boolean;
  description: string;
  profilePicture: string;
  handleOpenModal: () => void;
}) => {
  const SingleOrganization = () => {
    const navigate = useNavigate();

    const handleAction = (e: Event['Button'], action: string) => {
      e.stopPropagation();
      e.preventDefault();
      if (action === 'leave') {
        handleOpenModal();
      } else {
        navigate(`/${ORG}/${SETTINGS}/organization`);
      }
    };

    return (
      <div className="manage-organization-page__section_main_organization">
        <div className="manage-organization-page__section_main_organization_heading">
          <img alt="organization" src={profilePicture || placeholder} />
          <div>
            <h2>{title}</h2>
            <p>{description}</p>
          </div>
        </div>
        <div className="manage-organization-page__section_main_organization_actions">
          <button type="button" className="btn-primary-solid" onClick={(e) => handleAction(e, 'settings')}>
            <Icon name="settings" size={16} />
            <p>Settings</p>
          </button>
          {!isOrgOwner && (
            <button type="button" className="btn-neutral-ghost" onClick={(e) => handleAction(e, 'leave')}>
              <Icon name="exit" size={16} />
              <p>Leave</p>
            </button>
          )}
        </div>
      </div>
    );
  };

  return (
    <Link to={`/${ORG}`}>
      <SingleOrganization />
    </Link>
  );
};

const ManageOrganizationsPage = () => {
  const [isNewOrgModalOpen, setIsNewOrgModalOpen] = useState<boolean>(false);
  const [isLeaveOrgModalOpen, setIsLeaveOrgModalOpen] = useState<boolean>(false);

  const { data: user, refetch } = useReneQuery<{ User: UserData }>(GET_USER_QUERY);

  const [fetchOrg, { data, loading }] = useLazyReneQuery<{ Organization: OrganizationData }>(
    GET_ORG_QUERY,
    {},
    TokenType.ORG,
  );

  useEffect(() => {
    if (user && user.User.role !== UserRole.GAMER) {
      fetchOrg();
    }
  }, [user, fetchOrg]);

  const handleModal = (callback: Dispatcher<boolean>) => () => {
    callback((prev) => !prev);
  };

  return (
    <div className="manage-organization-page">
      <div className="manage-organization-page__section">
        <div className="manage-organization-page__section_heading">
          <h1>ORGANIZATIONS</h1>
          {!data?.Organization ? (
            <button className="btn-primary-solid" type="button" onClick={handleModal(setIsNewOrgModalOpen)}>
              <Icon name="plus" size={24} />
              <p>New Organization</p>
            </button>
          ) : null}
        </div>
        {loading ? (
          <LoadingFallback />
        ) : (
          <div className="manage-organization-page__section_main">
            {data?.Organization && user ? (
              <Organization
                title={data.Organization.name}
                description={data.Organization.data?.description}
                profilePicture={data.Organization.image?.url}
                handleOpenModal={handleModal(setIsLeaveOrgModalOpen)}
                isOrgOwner={user.User.role === UserRole.OWNER}
              />
            ) : (
              <NoOrganization />
            )}
          </div>
        )}
      </div>
      <Modal isOpen={isNewOrgModalOpen}>
        <NewOrganizationModal setCloseModal={handleModal(setIsNewOrgModalOpen)} refetch={refetch} />
      </Modal>
      <Modal isOpen={isLeaveOrgModalOpen}>
        <LeaveOrganizationModal
          orgId={data?.Organization.orgId}
          userId={user?.User.userId}
          orgName={data?.Organization.name}
          setCloseModal={handleModal(setIsLeaveOrgModalOpen)}
        />
      </Modal>
    </div>
  );
};

export default ManageOrganizationsPage;
