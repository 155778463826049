import React, { useEffect, useState } from 'react';
import { ErrorContext } from './use-error-context';

type CountProviderProps = { children: React.ReactNode };

export function ErrorProvider({ children }: CountProviderProps) {
  const [error, setError] = useState<{ message: string; showError: boolean }>({
    message: '',
    showError: false,
  });

  useEffect(() => {
    let errorInterval: any;
    if (error.showError) {
      errorInterval = setInterval(() => {
        setError({ message: '', showError: false });
      }, 5000);
    }
    return () => {
      clearInterval(errorInterval);
    };
  }, [error.showError]);

  const value = React.useMemo(() => ({ error, setError }), [error]);
  return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
}
