import { useState } from 'react';
import { format } from 'date-fns';
import {
  AdCampaignCheckpoint,
  AdType,
  AdTypeLabel,
  CampaignGoalLabel,
  CampaignStatus,
  CampaignTrackerLabel,
} from '../../../../../global/consts';
import { useReneMutation } from '../../../../../hooks/useReneMutation';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../../../global/gql/mutations';
import { AdData, CampaignsData, OrganizationData, Refetch } from '../../../../../global/interfaces';
import { CampaignFormData } from '../../types';
import Modal from '../../../modal';
import Spinner from '../../../../spinner/spinner';
import PreviewOfferModal from '../../../preview-offer-modal/preview-offer-modal';
import './review.scss';

interface ReviewProps {
  formData: CampaignFormData;
  setActiveStep: (step: number) => void;
  setCloseModal: () => void;
  refetch: Refetch<{ Organization: OrganizationData } | { AdCampaigns: CampaignsData } | undefined>;
  handleDeleteAdCampaign: () => void;
  deleteAdCampaignLoading: boolean;
}

const Overview = ({ data }: { data: CampaignFormData['overview'] }) => {
  return (
    <div className="review__overview_data">
      <p>Name:</p>
      <p>{data.name}</p>
      <p>Objective:</p>
      <p>{CampaignGoalLabel[data.adCampaignObjectiveType]}</p>
      <p>Countries:</p>
      <p>{data.targetCountries?.map((country) => country.countryName).join(', ')}</p>
      <p>Gender:</p>
      <p>{data.gender.join(', ')}</p>
      <p>Age:</p>
      <p>
        {data.ageRange.min} - {data.ageRange.max}
      </p>
    </div>
  );
};

const AdsPlacement = ({
  data,
}: {
  data: {
    adPlacement: CampaignFormData['adsPlacement'];
    selectedAds: CampaignFormData['selectedAds'];
    brandedObjects: CampaignFormData['selectedBrandedObjects'];
  };
}) => {
  const [previewOfferId, setPreviewOfferId] = useState('');
  return (
    <div className="review__ads-placement_data">
      <div className="review__ads-placement_data_settings">
        <h5>Campaign settings</h5>
        <p>IAB Categories:</p>
        <p>{data.adPlacement.contentTags?.map((tag) => tag.name)?.join(', ')}</p>
        <p>Game genres:</p>
        <p>{data.adPlacement.genres?.join(', ')}</p>
        <p>Game titles:</p>
        <p>{data.adPlacement.titles?.map((title) => title.name)?.join(', ')}</p>
      </div>
      <div className="review__ads-placement_data_ads">
        <h5>Ad-specific settings</h5>
        {data.selectedAds?.map((ad) => {
          const url = ad.adType === AdType.BANNER ? ad.banner?.url : ad.video?.url;
          return (
            <div key={ad.adId} className="review__ads-placement_data_ads_ad">
              <img src={url} alt="ad" />
              <div>
                <h6>{ad.name}</h6>
                <p>Image/Video</p>
                <p>
                  Type: <span>{AdTypeLabel[ad.adType]}</span>
                </p>
                {/* <p>
                  Size: <span>{ad.size}</span>
                </p> */}
                <p>
                  IAB Categories (includes): <span>{ad.contentTags?.map((tag) => tag.name).join(', ')}</span>
                </p>
                <p>
                  Trackers: <span>{ad.trackers?.map((t) => CampaignTrackerLabel[t.eventType]).join(', ')}</span>
                </p>
                {/* <div>
                  <p>
                    On-interaction offer: <span>ON</span>
                  </p>
                  <button className="btn-neutral-ghost" onClick={() => setPreviewOfferId('dasdas')}>
                    Preview offer
                  </button>
                </div> */}
              </div>
            </div>
          );
        })}
        {data.brandedObjects?.map((brandedObject) => {
          return (
            <div key={brandedObject.brandedObjectId} className="review__ads-placement_data_ads_ad">
              <img src={brandedObject.image.url} alt="ad" />
              <div>
                <h6>{brandedObject.name}</h6>
                <p>Branded Object</p>
                <p>
                  Type: <span>{AdTypeLabel[brandedObject.brandedObjectType as keyof typeof AdType]}</span>
                </p>
                {/* <p>
              Size: <span>{ad.size}</span>
            </p> */}
                <p>
                  IAB Categories (includes): <span>{brandedObject.contentTags?.map((tag) => tag.name).join(', ')}</span>
                </p>
                {/* <div>
              <p>
                On-interaction offer: <span>ON</span>
              </p>
              <button className="btn-neutral-ghost" onClick={() => setPreviewOfferId('dasdas')}>
                Preview offer
              </button>
            </div> */}
              </div>
            </div>
          );
        })}
      </div>
      <Modal isOpen={!!previewOfferId}>
        <PreviewOfferModal ad={{} as AdData} setIsModalOpen={setPreviewOfferId} />
      </Modal>
    </div>
  );
};

const Budget = ({ data }: { data: CampaignFormData['budget'] }) => {
  return (
    <div className="review__budget_data">
      <h5>Scheduling</h5>
      <p>
        Campaign dates:{' '}
        <span>
          {format(new Date(data.startDate), 'MMM dd, yyyy')} - {format(new Date(data.endDate), 'MMM dd, yyyy')}
        </span>
      </p>
      <h5>Budget</h5>
      <div>
        <table>
          <thead>
            <tr>
              <th>Country/Market</th>
              <th>Ad type</th>
              <th>Impression price</th>
              <th>Quantity</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {data.pricing?.map((item) => (
              <tr key={item.adType}>
                <td>{item.countryName}</td>
                <td>{AdTypeLabel[item.adType]}</td>
                <td>{item.cpm}</td>
                <td>{item.totalImpressions}</td>
                <td>${((item.totalImpressions * item.cpm) / 1000).toFixed(2)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          Grand total:{' '}
          <span>
            ${(data.pricing?.reduce((acc, item) => acc + item.totalImpressions * item.cpm, 0) / 1000).toFixed(2)}
          </span>
        </p>
      </div>
    </div>
  );
};

const reviewSections = [
  {
    title: 'Overview & Target Audience',
    className: 'review__overview',
    dataForm: 'overview',
    step: 1,
    component: (data: CampaignFormData) => <Overview data={data.overview} />,
  },
  {
    title: 'Ads placement',
    className: 'review__ads-placement',
    dataForm: 'adsPlacement',
    step: 3,
    component: (data: CampaignFormData) => (
      <AdsPlacement
        data={{
          adPlacement: data.adsPlacement,
          selectedAds: data.selectedAds,
          brandedObjects: data.selectedBrandedObjects,
        }}
      />
    ),
  },
  {
    title: 'Budget & Scheduling',
    className: 'review__budget',
    step: 4,
    dataForm: 'budget',
    component: (data: CampaignFormData) => <Budget data={data.budget} />,
  },
];

const Review: React.FC<ReviewProps> = ({
  formData,
  handleDeleteAdCampaign,
  deleteAdCampaignLoading,
  setCloseModal,
  setActiveStep,
  refetch,
}) => {
  const [upsertCampaign, { loading: upsertCampaignLoading }] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted() {
      setCloseModal();
      refetch();
    },
  });

  const saveCampaign = () => {
    upsertCampaign({
      variables: {
        adCampaignId: formData.adCampaignId,
        checkpoint: AdCampaignCheckpoint.DONE,
      },
    });
  };

  return (
    <div className="review">
      <div className="review__content">
        <h2>Review & Launch</h2>
        <h3>Review your campaign details and launch when ready.</h3>
        <div className="review__sections">
          {reviewSections?.map((section) => (
            <div key={section.step} className={section.className}>
              <div className={`${section.className}__header`}>
                <div className="review__section_header">
                  <h4>{section.title}</h4>
                </div>
              </div>
              {section.component(formData)}
            </div>
          ))}
        </div>
      </div>
      <div className="create-campaign-modal__main_footer">
        <div>
          {formData.status === CampaignStatus.DRAFT && (
            <button type="button" className="btn-negative-ghost" onClick={handleDeleteAdCampaign}>
              {deleteAdCampaignLoading ? <Spinner size="sm" /> : 'Discard'}
            </button>
          )}
        </div>
        <div>
          <button type="button" className="btn-neutral-ghost" onClick={() => setActiveStep(4)}>
            Back
          </button>
          <button type="button" className="btn-primary-solid" onClick={saveCampaign}>
            {upsertCampaignLoading ? <Spinner size="sm" /> : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Review;
