import { useState } from 'react';
import Icon from '../../../../components/Icon/Icon';
import InstructionStepModal from '../../../../components/modal/instruction-step-modal/instruction-step-modal';
import './organization-game-connect.scss';

const OrganizationGameConnect = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const steps = [
    {
      step: 1,
      title: 'Setup Account',
      description: 'This is the first step of the instructions.',
      imageUrl:
        'https://s3-alpha-sig.figma.com/img/067c/d5a0/0b406146e7823e4113bf355f6caf8c8f?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=OLlQi~ylgTqD9BmQDIEKAACPHxwmQv5DbcqqqI8ld82LKCvdN3bUsnXXAaqQZh~tPrLGCDFddPW0Ex6ZgvwOCyJGMXrGBI-qrUHbO8IbTwyy87THnNJ0KSH4Y49VHa3wgiI1w~Cb0YV4p4-k2PhA5P6fr5MVl4qVt8Bx6cFHaMFW9k165U8Q~473WrpCP2RC1ghOJvmOxCxRtHW8ybNUm-nstbGkXYg6217lsszQifIz58e2NSkeS5i4tSni2PdCisSwOENa4iKrh5uSG9duZvqbQVh3e3dDY~nHvIS2CQKGiH6Kym1Gf6hlzt8lRM3cfbFgf~6oYddbjbwXuZAIRg__',
    },
    {
      step: 2,
      title: 'Configure Settings',
      description: 'This is the second step of the instructions.',
      imageUrl:
        'https://s3-alpha-sig.figma.com/img/067c/d5a0/0b406146e7823e4113bf355f6caf8c8f?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=OLlQi~ylgTqD9BmQDIEKAACPHxwmQv5DbcqqqI8ld82LKCvdN3bUsnXXAaqQZh~tPrLGCDFddPW0Ex6ZgvwOCyJGMXrGBI-qrUHbO8IbTwyy87THnNJ0KSH4Y49VHa3wgiI1w~Cb0YV4p4-k2PhA5P6fr5MVl4qVt8Bx6cFHaMFW9k165U8Q~473WrpCP2RC1ghOJvmOxCxRtHW8ybNUm-nstbGkXYg6217lsszQifIz58e2NSkeS5i4tSni2PdCisSwOENa4iKrh5uSG9duZvqbQVh3e3dDY~nHvIS2CQKGiH6Kym1Gf6hlzt8lRM3cfbFgf~6oYddbjbwXuZAIRg__',
    },
    {
      step: 3,
      title: 'Finish Process',
      description: 'This is the third step of the instructions.',
      imageUrl:
        'https://s3-alpha-sig.figma.com/img/067c/d5a0/0b406146e7823e4113bf355f6caf8c8f?Expires=1741564800&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=OLlQi~ylgTqD9BmQDIEKAACPHxwmQv5DbcqqqI8ld82LKCvdN3bUsnXXAaqQZh~tPrLGCDFddPW0Ex6ZgvwOCyJGMXrGBI-qrUHbO8IbTwyy87THnNJ0KSH4Y49VHa3wgiI1w~Cb0YV4p4-k2PhA5P6fr5MVl4qVt8Bx6cFHaMFW9k165U8Q~473WrpCP2RC1ghOJvmOxCxRtHW8ybNUm-nstbGkXYg6217lsszQifIz58e2NSkeS5i4tSni2PdCisSwOENa4iKrh5uSG9duZvqbQVh3e3dDY~nHvIS2CQKGiH6Kym1Gf6hlzt8lRM3cfbFgf~6oYddbjbwXuZAIRg__',
    },
  ];

  return (
    <div className="organization-game-connect">
      <div className="organization-game-connect__header">
        <h1>Instructions</h1>
        <button type="button" className="btn-primary-ghost" onClick={() => setIsModalOpen(true)}>
          <Icon name="plus" /> New Step
        </button>
      </div>
      <div className="organization-game-connect__steps-container">
        {steps.map((stepItem) => (
          <div className="organization-game-connect__step-item" key={stepItem.step}>
            <div className="organization-game-connect__step-item__step-header">Step {stepItem.step}</div>
            <div className="organization-game-connect__step-item__step-body">
              <div className="organization-game-connect__step-item__step-body__step-title">{stepItem.title}</div>
              <p className="organization-game-connect__step-item__step-body__step-description">
                {stepItem.description}
              </p>
            </div>
            <div className="organization-game-connect__step-item__step-image">
              <img src={stepItem.imageUrl} alt={`Step ${stepItem.step}`} />
            </div>
          </div>
        ))}
      </div>
      <InstructionStepModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default OrganizationGameConnect;
