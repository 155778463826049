import { ApolloError } from '@apollo/client';
import { TOKEN, UserRole } from '../global/consts';
import { ChainData, ChainsData, Dispatcher, Event, ChainOption } from '../global/interfaces';
import { RENE_API_URI } from '../global/consts/env-consts';

const timeToCheckTokenBeforeExpiration = 15 * 60 * 1000; // 15 minutes

export const isUserLoggedIn = () => {
  return localStorage.getItem(TOKEN);
};

export const handleKeyPressSubmit = (e: Event['Keyboard'], callback: any) => {
  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
    callback();
  }
};

export const checkTokenExpiration = (token: string) => {
  const [, payload] = token.split('.');
  const { exp } = JSON.parse(window.atob(payload));
  return exp * 1000 - Date.now() < timeToCheckTokenBeforeExpiration;
};

export const refreshToken = (token: string) => {
  const mutation = `
    mutation RefreshToken {
      RefreshToken {
        jwt
      }
    }
`;

  return fetch(RENE_API_URI, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ query: mutation }),
  })
    .then((response) => response.json())
    .then(({ data }) => {
      if (data?.RefreshToken?.jwt) {
        localStorage.setItem(TOKEN, data?.RefreshToken?.jwt);
        return data?.RefreshToken?.jwt;
      }
      return null;
    });
};

export const hasEmptyValue = (form: { [key: string]: any }) => Object.values(form).some((value) => !value);

export const deepCopy = (original: any) => {
  const copyOriginal = JSON.parse(JSON.stringify(original));
  return copyOriginal;
};

export const debounce = (func: any, timeout = 300) => {
  let timer: any;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func();
    }, timeout);
  };
};

export const handleError = (
  err: ApolloError,
  setError: React.Dispatch<React.SetStateAction<{ message: string; showError: boolean }>>,
  message?: string,
) => {
  if (err?.message?.match('401')) {
    return;
  }

  if (err.message === 'Document was updated by another user') {
    return setError({ message: 'Document was updated by another user, please reload.', showError: true });
  }

  return setError({ message: message || '', showError: true });
};

export const unsubscribeAllSubscriptions = () => {
  if (window.gameConnectSubscription) {
    window.gameConnectSubscription.unsubscribe();
    window.gameConnectSubscription = null;
  }

  if (window.gameAuthorizationSubscription) {
    window.gameAuthorizationSubscription.unsubscribe();
    window.gameAuthorizationSubscription = null;
  }

  if (window.generalNotificationsSubscription) {
    window.generalNotificationsSubscription.unsubscribe();
    window.generalNotificationsSubscription = null;
  }
};

export const isObjEmpty = (obj: any) => Object.keys(obj).length === 0;

export const extractExtensionAndName = (value: string) => {
  return value.split(/(.*)\.(.*)/);
};

/**
 * Compare two objects
 * First sorts their keys and check if they are equal length
 * If not returns false
 * If yes then it compares them by calling JSON.stringify on each element
 */
export const compareObjects = (obj1: { [x: string]: any } = {}, obj2: { [x: string]: any } = {}) => {
  const obj1Keys = Object.keys(obj1).sort();
  const obj2Keys = Object.keys(obj2).sort();

  if (obj1Keys.length !== obj2Keys.length) return false;
  const areObjEqual = obj1Keys.every((key, index) => {
    const objValue1 = obj1[key];
    const objValue2 = obj2[obj2Keys[index]];
    return JSON.stringify(objValue1) === JSON.stringify(objValue2);
  });
  if (areObjEqual) {
    return true;
  }
  return false;
};

/**
 * Compare attribute arrays (prev and current state)
 * First sorts them and checks if they are equal length
 * If not returns false
 * If yes then it compares them by calling compareObjects on each object element
 */
export const compareAttributeArrays = (arr1: any = [], arr2: any = []) => {
  const newAttrs = [...arr1].sort();
  const oldAttrs = [...arr2].sort();

  if (newAttrs?.length !== oldAttrs?.length) return false;
  return newAttrs?.every((attr, i) => compareObjects(attr, oldAttrs?.[i]));
};

export const getTimeAgo = (dateString: string) => {
  const date = new Date(dateString);

  // Calculate the time difference in milliseconds
  const timeDifference = Date.now() - date.getTime();

  // Define the time units and their respective durations in milliseconds
  const timeUnits = [
    { unit: 'year', duration: 31536000000 },
    { unit: 'month', duration: 2592000000 },
    { unit: 'week', duration: 604800000 },
    { unit: 'day', duration: 86400000 },
    { unit: 'hour', duration: 3600000 },
    { unit: 'minute', duration: 60000 },
    { unit: 'second', duration: 1000 },
  ];

  // Find the appropriate time unit and calculate the time ago value
  for (let i = 0; i < timeUnits.length; i++) {
    const { unit, duration } = timeUnits[i];
    if (timeDifference >= duration) {
      const timeAgo = Math.floor(timeDifference / duration);
      return `${timeAgo} ${unit}${timeAgo > 1 ? 's' : ''} ago`;
    }
  }

  return 'Just now';
};

export const formatDate = (date: string) => {
  const dateObj = new Date(date);
  const year = dateObj.getUTCFullYear();
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const showSaveMessage = (msg: string, setMessage: Dispatcher<string>) => {
  setMessage(msg);
  setTimeout(() => {
    setMessage('');
  }, 5000);
};

export const isRoleAllowed = (role: UserRole, roles?: UserRole[]) => {
  const orgAllowedRoles = [UserRole.OWNER, UserRole.ADMIN];
  const allowedRoles = roles ? [...orgAllowedRoles, ...roles] : orgAllowedRoles;
  return allowedRoles.includes(role);
};

export const createChainOptions = (chains: ChainsData | undefined) => {
  const defaultOption: {
    [key: string]: ChainData;
  } = {
    None: {
      name: 'None',
      mainnet: { chainId: 0, isActive: false, name: 'None' },
      chainSymbol: 'None',
      testnet: { chainId: 0, name: 'None' },
    },
  };

  if (!chains) {
    return defaultOption;
  }

  return chains.items.reduce<{ [key: string]: ChainData }>((acc, chain) => {
    acc[chain.name] = chain;
    return acc;
  }, defaultOption);
};

export const createCampaignChainOptions = (chains: ChainsData | undefined): ChainOption[] => {
  const defaultOption: ChainOption = {
    name: 'None',
    chainId: 0,
  };

  if (!chains) {
    return [defaultOption];
  }

  const filteredChains = chains.items.filter((chain) => chain.chainSymbol === 'A8' || chain.chainSymbol === 'SKALE');

  const chainOptions = filteredChains.reduce<ChainOption[]>(
    (acc, chain) => {
      if (chain.mainnet?.chainId) {
        acc.push({
          name: chain.name,
          chainId: chain.mainnet.chainId,
        });
      }
      if (chain.testnet?.chainId) {
        acc.push({
          name: `${chain.name} Testnet`,
          chainId: chain.testnet.chainId,
        });
      }
      return acc;
    },
    [defaultOption],
  );

  return chainOptions;
};

export const removeTypename = (value: any): any => {
  if (value === null || value === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  }
  if (typeof value === 'object') {
    return Object.entries(value).reduce((newObj: { [key: string]: any }, [key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v);
      }
      return newObj;
    }, {});
  }
  return value;
};

export const shortenAddress = (address: string, startLength = 4, endLength = 4): string => {
  if (address.length < startLength + endLength + 2) {
    return address;
  }
  return `${address.slice(0, startLength + 2)}...${address.slice(-endLength)}`;
};
export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const formatNumber = (value: number, fraction: number): string => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: fraction,
    maximumFractionDigits: fraction,
  }).format(value);
};

export const areArraysEqual = <T extends Record<string, any>>(arr1: T[], arr2: T[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  // Helper function to sort and stringify objects for comparison
  const stringifyObjects = (arr: T[]): string[] =>
    arr.map((obj) => JSON.stringify(obj, Object.keys(obj).sort())).sort();

  const sortedArr1 = stringifyObjects(arr1);
  const sortedArr2 = stringifyObjects(arr2);

  // Compare each stringified object
  return sortedArr1.every((item, index) => item === sortedArr2[index]);
};
export const getUniqueKeyValues = <T, K extends keyof T>(array: T[], key: K): T[K][] => {
  return Array.from(new Set(array.map((item) => item[key])));
};
