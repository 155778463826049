import './spinner.scss';

interface Props {
  size: string;
  color?: string;
}

const Spinner: React.FC<Props> = ({ size, color }) => {
  return (
    <div className={`lds-ring ${size} ${color}`} data-testid="spinner">
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default Spinner;
