import { useState } from 'react';
import { useNotificationContext } from '../../../context/notification-context/use-notification-context';
import { AUTHORIZE_GAME_MUTATION } from '../../../global/gql/mutations';
import { GET_GAME_TO_AUTH_QUERY } from '../../../global/gql/queries';
import { Dispatcher, GamesData } from '../../../global/interfaces';
import { useReneMutation, useReneQuery } from '../../../hooks';
import Icon from '../../Icon/Icon';
import Spinner from '../../spinner/spinner';
import placeholder from '../../../global/images/avatar.webp';

import './game-action-modal.scss';

const actionDescription: { AUTHORIZE: { [k: string]: string } } = {
  AUTHORIZE: {
    description: 'Started playing this game, do you want to connect with Reneverse?',
    actionButton: 'Connect',
    cancelButton: 'Back',
    success: 'Game connected',
  },
};

interface Props {
  authId: string;
  gameId: string;
  setSelectedGameForAction: Dispatcher<{ authId: string; gameId: string } | undefined>;
}

const GameActionModal: React.FC<Props> = ({ authId, gameId, setSelectedGameForAction }) => {
  const { showNotification } = useNotificationContext();
  const [isActionDone, setIsActionDone] = useState(false);

  const handleCloseModal = () => {
    setSelectedGameForAction(undefined);
  };

  const { data, loading: game } = useReneQuery<{ Games: GamesData } | undefined>(GET_GAME_TO_AUTH_QUERY, {
    variables: { gameId },
  });

  const [authorizeProject, { loading: authorizeLoading }] = useReneMutation(AUTHORIZE_GAME_MUTATION, {
    onCompleted: () => {
      setIsActionDone(true);
      showNotification(true);
    },
  });

  const handleAction = () => {
    authorizeProject({
      variables: {
        authId,
      },
    });
  };

  const handleCancel = () => {
    setSelectedGameForAction(undefined);
  };

  const gameAction = () => {
    return game ? (
      <Spinner size="lg" color="white" />
    ) : (
      <div className="game-action-modal__authorize">
        <img src={data?.Games?.items[0]?.image?.url || placeholder} alt="game" />
        <h3>{data?.Games?.items[0]?.name}</h3>
        <p>{actionDescription.AUTHORIZE.description}</p>
        <div>
          <button className="secondary-btn" type="button" onClick={handleCancel}>
            {actionDescription.AUTHORIZE.cancelButton}
          </button>
          <button className="primary-btn" type="button" onClick={handleAction}>
            {authorizeLoading ? <Spinner size="md" /> : actionDescription.AUTHORIZE.actionButton}
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="game-action-modal">
      <button type="button" onClick={handleCloseModal}>
        <Icon name="close" size={24} />
      </button>
      {isActionDone ? (
        <div className="game-action-modal__success">
          <Icon name="checkmark" size={50} />
          <h4>{actionDescription.AUTHORIZE.success}</h4>
        </div>
      ) : (
        gameAction()
      )}
    </div>
  );
};

export default GameActionModal;
