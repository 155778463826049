import React, { useState } from 'react';
import { NotificationContext } from './use-notification-context';

type CountProviderProps = { children: React.ReactNode };

export function NotificationProvider({ children }: CountProviderProps) {
  const [notification, showNotification] = useState<boolean>(false);
  const value = React.useMemo(() => ({ notification, showNotification }), [notification]);
  return <NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>;
}
