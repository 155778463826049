import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useReneMutation, useValidation } from '../../../hooks';
import { AdData, AdsData, Dispatcher } from '../../../global/interfaces';
import { UPSERT_BULK_AD_MUTATION } from '../../../global/gql/mutations';
import { GET_AD_CAMPAIGN_QUERY } from '../../../global/gql/queries';
import { validations } from './validations';
import { useFileUpload } from '../../../hooks/useFileUpload';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import Spinner from '../../spinner/spinner';
import Checkbox from '../../checkbox/checkbox';
import Textarea from '../../textarea/textarea';
import FileUpload from '../../file-upload/file-upload';

import './create-offer-modal.scss';

interface CreateOfferModalProps {
  ad?: AdData;
  adCampaignId: string;
  setIsModalOpen: Dispatcher<string>;
}

export const CreateOfferModal: React.FC<CreateOfferModalProps> = ({ ad, adCampaignId, setIsModalOpen }) => {
  const uploadFile = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validations);

  const [offerData, setOfferData] = useState<{
    title: string;
    message: string;
    ctaButton: boolean;
    buttonUrl: string;
    buttonText: string;
    file: File | null;
  }>({
    title: ad?.adOffer?.title || '',
    message: ad?.adOffer?.message || '',
    ctaButton: !!ad?.adOffer?.buttonUrl,
    buttonUrl: ad?.adOffer?.buttonUrl || '',
    buttonText: ad?.adOffer?.buttonText || '',
    file: null,
  });

  const apolloClient = useApolloClient();

  const [upsertAd, { loading }] = useReneMutation(UPSERT_BULK_AD_MUTATION, {
    onCompleted(data: { BulkInsertAd: { ads: AdsData } }) {
      const updatedAd = data.BulkInsertAd.ads.items[0];
      if (offerData.file && updatedAd && updatedAd.adOffer?.adOfferImage?.uploadUrl) {
        uploadFile(updatedAd.adOffer.adOfferImage.uploadUrl, offerData.file).finally(() => {
          setIsModalOpen('');
        });
      } else {
        setIsModalOpen('');
      }

      apolloClient.refetchQueries({
        include: [GET_AD_CAMPAIGN_QUERY],
      });
    },
  });

  // const { data: orgData } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY);

  const setFile = (files: File[]) => {
    setOfferData((prev) => ({ ...prev, file: files?.[0] }));
  };

  const handleCloseModal = () => {
    setIsModalOpen('');
  };

  const handleCreateOffer = () => {
    if (isFormInvalid(offerData)) return;

    const variables = {
      adCampaignId,
      ads: [
        {
          adId: ad?.adId,
          adOfferInput: {
            senderType: 'ORGANIZATION',
            title: offerData.title,
            message: offerData.message,
            buttonUrl: offerData.buttonUrl || undefined,
            buttonText: offerData.buttonText || undefined,
            adOfferImage: offerData.file
              ? {
                  extension: offerData.file?.type.split('/')[1],
                }
              : undefined,
          },
        },
      ],
    };
    upsertAd({ variables });
  };

  return (
    <div className="create-offer-modal">
      <div className="create-offer-modal__heading">
        <h2>Create offer</h2>
        <button type="button" onClick={handleCloseModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <h3>
        Reward users with exclusive deals or promotions when they engage with your ad, increasing the chances of
        conversion and enhancing user experience.
      </h3>
      <div className="create-offer-modal__content">
        {/* <div className="create-offer-modal__content_sender">
          <p>Sender</p>
          <div>
            <img src={orgData?.Organization?.image?.url} alt="sender" />
            <p>{orgData?.Organization?.name}</p>
          </div>
        </div> */}
        <Input
          label="Title"
          value={offerData.title}
          placeholder="Enter title"
          handleInput={(e) => setOfferData({ ...offerData, title: e.target.value })}
          errorMessage={errors?.title}
        />
        <Textarea
          label="Message"
          value={offerData.message}
          placeholder="Enter message"
          handleInput={(e) => setOfferData({ ...offerData, message: e.target.value })}
          showCounter
          errorMessage={errors?.message}
        />
        <FileUpload
          label="Visual (optional)"
          loadedImgSrc={ad?.adOffer?.adOfferImage?.url}
          setFiles={setFile}
          multiple={false}
        />
        <Checkbox
          value={offerData.ctaButton}
          setValue={(value) => {
            if (!value) {
              setOfferData({ ...offerData, ctaButton: value, buttonUrl: '', buttonText: '' });
            } else {
              setOfferData({ ...offerData, ctaButton: value });
            }
          }}
        >
          Show CTA button
        </Checkbox>
        {offerData.ctaButton && (
          <div className="create-offer-modal__content_cta">
            <Input
              label="Button URL"
              value={offerData.buttonUrl}
              placeholder="Enter CTA button text"
              handleInput={(e) => setOfferData({ ...offerData, buttonUrl: e.target.value })}
              errorMessage={errors?.buttonUrl}
            />
            <Input
              maxLength={16}
              label="Button text"
              value={offerData.buttonText}
              placeholder="Enter CTA button text"
              handleInput={(e) => setOfferData({ ...offerData, buttonText: e.target.value })}
              errorMessage={errors?.buttonText}
            />
          </div>
        )}
      </div>

      <div className="create-offer-modal__footer">
        <div className="create-offer-modal__footer-left">
          {/* <button type="button" className="btn-neutral-ghost" onClick={handlePreviewOffer}>
            Preview offer
          </button> */}
        </div>
        <div className="create-offer-modal__footer-right">
          <button type="button" className="btn-neutral-ghost" onClick={handleCloseModal}>
            Cancel
          </button>
          <button type="button" className="btn-primary-solid" onClick={handleCreateOffer}>
            {loading ? <Spinner size="sm" /> : 'Finish'}
          </button>
        </div>
      </div>
    </div>
  );
};
