import ReactDOM from 'react-dom';
import Icon from '../Icon/Icon';
import './modal.scss';

interface Props {
  isOpen: boolean;
  children: JSX.Element;
  onClose?: () => void;
  showCloseButton?: boolean;
}

const RenderModal = ({
  children,
  onClose,
  showCloseButton,
}: {
  children: JSX.Element;
  onClose?: () => void;
  showCloseButton?: boolean;
}) => {
  return (
    <div className="modal">
      {showCloseButton && onClose && (
        <button type="button" className="modal__close-button" onClick={onClose}>
          <Icon name="close" size={24} />
        </button>
      )}
      {children}
    </div>
  );
};

const Modal: React.FC<Props> = ({ children, isOpen, onClose, showCloseButton = false }) => {
  const el = document.getElementById('root')!;
  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <RenderModal onClose={onClose} showCloseButton={showCloseButton}>
      {children}
    </RenderModal>,
    el,
  );
};

export default Modal;
