import { useErrorContext } from '../../../context/error-context/use-error-context';
import { DEFAULT_ERROR_MESSAGE } from '../../../global/consts';
import './error-message.scss';

const ErrorMessage = () => {
  const { error } = useErrorContext();

  if (!error.showError) return null;
  return <div className="error_message">{error.message || DEFAULT_ERROR_MESSAGE}</div>;
};

export default ErrorMessage;
