import { useLocation } from 'react-router-dom';
import {
  QueryHookOptions,
  ApolloError,
  useLazyQuery,
  LazyQueryExecFunction,
  QueryResult,
  OperationVariables,
} from '@apollo/client';
import { ORG } from '../global/routes/index';
import { handleError } from '../utils';
import { useErrorContext } from '../context/error-context/use-error-context';
import { TokenType } from '../global/consts';

export const useLazyReneQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: any,
  options?: QueryHookOptions<TData, TVariables>,
  tokenType?: TokenType,
): [lazyQuery: LazyQueryExecFunction<TData, TVariables>, params: QueryResult<TData, TVariables>] => {
  const { setError } = useErrorContext();
  const location = useLocation();

  const setTokenType = (tokenType?: TokenType) => {
    if (tokenType) {
      return tokenType;
    }
    const isOrg = !!location.pathname.match(`/${ORG}`);
    return isOrg ? TokenType.ORG : TokenType.GAMER;
  };

  const [lazyQuery, params] = useLazyQuery<TData, TVariables>(query, {
    errorPolicy: 'all',
    context: {
      headers: {
        authorization: setTokenType(tokenType),
      },
    },
    onError: (err: ApolloError) => {
      handleError(err, setError);
    },
    ...options,
  });

  return [lazyQuery, params];
};
