import debounce from 'lodash/debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import loaderGif from '../../global/images/loader.gif';
import { useClickOutside } from '../../hooks';
import Input from '../input/input';
import './dropdown-search.scss';

type DropdownSearchProps<T> = {
  label?: string;
  options: {
    label: string;
    value: T;
  }[];
  selectedValue: T;
  handleSelect: (value: T) => void;
  handleSearchChange: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  name?: string;
  errorMessage?: string;
  emptyInputResets?: boolean;
};

const DropdownSearch = <T,>({
  label,
  options,
  selectedValue,
  handleSelect,
  handleSearchChange,
  placeholder = 'Search...',
  disabled = false,
  loading = false,
  name,
  errorMessage,
  emptyInputResets = false,
}: DropdownSearchProps<T>) => {
  const initialLabelRef = useRef<string | undefined>(
    selectedValue ? options.find((option) => option.value === selectedValue)?.label : undefined,
  );

  const [searchTerm, setSearchTerm] = useState(initialLabelRef.current || '');
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [shouldSearchWithSelectedValue, setShouldSearchWithSelectedValue] = useState(true);

  // Effect to handle initial search with selectedValue if needed
  useEffect(() => {
    if (
      shouldSearchWithSelectedValue &&
      selectedValue &&
      !options.find((option) => option.value === selectedValue) &&
      !isUserTyping
    ) {
      if (typeof selectedValue === 'string') {
        handleSearchChange(selectedValue as string);
        setShouldSearchWithSelectedValue(false);
      }
    }
  }, [selectedValue, options, handleSearchChange, isUserTyping, shouldSearchWithSelectedValue]);

  useEffect(() => {
    if (!isUserTyping) {
      const selectedOption = options.find((option) => option.value === selectedValue);
      if (selectedOption && searchTerm !== selectedOption.label) {
        setSearchTerm(selectedOption.label);
        initialLabelRef.current = selectedOption.label;
      } else if (!selectedValue && initialLabelRef.current !== undefined) {
        setSearchTerm('');
        initialLabelRef.current = undefined;
      }
    }
  }, [selectedValue, options, isUserTyping, searchTerm]);

  const [showDropdown, setShowDropdown] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState<number | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => {
    setShowDropdown(false);
    setIsUserTyping(false);
  });

  const debouncedHandleSearchChange = useMemo(() => debounce(handleSearchChange, 300), [handleSearchChange]);

  useEffect(() => {
    return () => {
      debouncedHandleSearchChange.cancel();
    };
  }, [debouncedHandleSearchChange]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setIsUserTyping(true);
    setSearchTerm(value);
    setShowDropdown(true);

    if (emptyInputResets && value === '') {
      handleSearchChange('');
      return;
    }

    if (value !== '') {
      debouncedHandleSearchChange(value);
    }
  };

  const handleOptionSelect = (value: T, label: string) => {
    handleSelect(value);
    setSearchTerm(label);
    setShowDropdown(false);
    setIsUserTyping(false);
  };

  const handleFocus = () => {
    setShowDropdown(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    // Only close dropdown if the focus didn't move to an element within the dropdown
    setTimeout(() => {
      const relatedTarget = event.relatedTarget as Node | null;
      if (!ref.current?.contains(relatedTarget)) {
        setShowDropdown(false);
        setIsUserTyping(false);
      }
    }, 0);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && focusedOptionIndex !== null) {
      const option = options[focusedOptionIndex];
      handleOptionSelect(option.value, option.label);
    }
  };

  return (
    <div className="dropdown-search" onBlur={handleBlur} tabIndex={-1} ref={ref} onKeyDown={handleKeyDown}>
      <Input
        type="text"
        value={searchTerm}
        handleInput={handleInputChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        disabled={disabled}
        className="dropdown-search__input"
        name={name}
        errorMessage={errorMessage}
        label={label}
      >
        {showDropdown && (
          <div className={`dropdown-search__options${label ? ' with-label' : ''}`}>
            {loading && (
              <div className="dropdown-search__loading">
                <img src={loaderGif} alt="loader" />
              </div>
            )}
            {!loading &&
              options.length > 0 &&
              options.map(({ label, value }, index) => (
                <button
                  type="button"
                  key={value as unknown as string}
                  onClick={() => handleOptionSelect(value, label)}
                  className={`dropdown-search__option ${value === selectedValue ? 'selected' : ''}`}
                  tabIndex={0}
                  onFocus={() => setFocusedOptionIndex(index)}
                >
                  {label}
                </button>
              ))}
          </div>
        )}
      </Input>
    </div>
  );
};

export default DropdownSearch;
