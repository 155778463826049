export const validations = {
  expiry: (form: { expiry: string; isSetExpiry: boolean }, key: 'expiry') => {
    const parsedDate = Date.parse(form[key]);
    const now = new Date();
    const sixMonthsFromNow = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 6)).getTime();

    if (form.isSetExpiry) {
      if (!form[key]) {
        return 'Expiry date is required';
      }

      if (parsedDate < new Date().getTime()) {
        return 'Date should be in the future';
      }

      if (parsedDate > sixMonthsFromNow) {
        return 'Date cannot be more than 6 months';
      }
    }
  },
};
