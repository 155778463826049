import { addYears, differenceInYears, isAfter, isBefore, isToday } from 'date-fns';
import { PricingItem } from '../../../../../../global/interfaces';

export const validations = {
  startDate: (form: { [key: string]: any }, _key: string, field: string) => {
    if (!field) return 'This field is required';
    if (form.isEdit) return;
    const startDate = new Date(field);
    const endDate = new Date(form.endDate);
    const maxFutureDate = addYears(new Date(), 5);
    if (isAfter(startDate, endDate)) return 'Start date cannot be after the end date';
    if (!isToday(startDate) && !isAfter(startDate, new Date())) return 'The date cannot be in the past';
    if (!isBefore(startDate, maxFutureDate)) return 'Start date cannot be more then 5 years in the future';
  },
  endDate: (form: { [key: string]: any }, _key: string, field: string) => {
    if (!field) return 'This field is required';
    const endDate = new Date(field);
    if (!isAfter(endDate, new Date())) return 'The End date needs to be in the future';
    if (differenceInYears(endDate, new Date(form.startDate)) > 0) return 'Date interval cannot be more than a year';
  },
  pricing: (form: { [key: string]: any }, _key: string, field: PricingItem[]) => {
    if (!field || !field.length) return 'This field is required';

    if (field.some((item) => !item.totalImpressions || item.totalImpressions <= 0)) {
      return 'Quantity must be greater than 0';
    }
  },

  balance: (form: { [key: string]: any }, _key: string, field: number) => {
    if (field === 0) return 'Insufficient balance';
    if (field < form.total) return 'Insufficient balance';
  },
};
