import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Dispatcher } from '../../global/interfaces';
import Icon from '../Icon/Icon';
import './aside-popup-menu.scss';

const AsidePopupMenu = ({
  children,
  isMenuOpen,
  setIsOpen,
}: {
  isMenuOpen: boolean;
  setIsOpen: Dispatcher<boolean>;
  children: React.ReactNode;
}) => {
  const params = useParams();

  useEffect(() => {
    setIsOpen(false);
  }, [params.menuItem, setIsOpen]);

  return (
    <aside className={`aside-popup-menu ${isMenuOpen ? 'aside-popup-menu--open' : ''}`}>
      <div className="aside-popup-menu__backdrop" onClick={() => setIsOpen(false)} />
      <div className="aside-popup-menu__menu">
        <button className="aside-popup-menu__menu_close" onClick={() => setIsOpen(false)} type="button">
          <Icon name="close" />
        </button>
        {children}
      </div>
    </aside>
  );
};

export default AsidePopupMenu;
