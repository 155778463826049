import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UPSERT_BRANDED_OBJECT_PLACEMENT_MUTATION } from '../../../global/gql/mutations';
import { Event, isValidPricingType, UpsertBrandedObjectPlacementInput } from '../../../global/interfaces';
import { useReneMutation } from '../../../hooks';
import { usePricingQuery } from '../../../hooks/usePricingQuery';
import Icon from '../../Icon/Icon';
import Input from '../../input/input';
import RadioButtons from '../../radio/radio-buttons';
import Spinner from '../../spinner/spinner';
import Modal from '../modal';
import IabSelect from '../../iab-select/iab-select';
import './new-branded-object-placement-modal.scss';

type NewBrandedObjectPlacementModalProps = {
  isOpen: boolean;
  onClose: () => void;
  editPlacement?: UpsertBrandedObjectPlacementInput;
};

const NewBrandedObjectPlacementModal = ({ isOpen, onClose, editPlacement }: NewBrandedObjectPlacementModalProps) => {
  const { pathname } = useLocation();
  const gameId = pathname.split('/games/')[1].split('/')[0];
  const [form, setForm] = useState<
    Partial<UpsertBrandedObjectPlacementInput> & {
      name?: string;
      ratio?: string;
      categories: string[];
    }
  >({
    name: '',
    height: 0,
    length: 0,
    width: 0,
    categories: [],
    type: 'THREE_D',
    ratio: '',
  });

  useEffect(() => {
    if (editPlacement) {
      setForm((prev) => ({
        ...prev,
        ratio: `${editPlacement.height}x${editPlacement.length}x${editPlacement.width}`,
        categories: editPlacement.categories ?? [],
        type: editPlacement.type,
        brandedObjectPlacementId: editPlacement.brandedObjectPlacementId,
      }));
    }
  }, [editPlacement]);

  const apolloClient = useApolloClient();

  const [mutate, { loading, error }] = useReneMutation(UPSERT_BRANDED_OBJECT_PLACEMENT_MUTATION, {
    onCompleted: async () => {
      await apolloClient.refetchQueries({ include: ['BrandedObjectPlacements'] });
      onClose();
    },
  });

  const { data: pricingData } = usePricingQuery({
    input: {
      countries: ['US'],
      adTypes: ['TWO_D', 'THREE_D'],
    },
  });

  const handleCategoriesSelect = useCallback(
    (categories: string[]) => setForm((prev) => ({ ...prev, categories })),
    [],
  );

  const floorPrice = pricingData?.Pricing?.items?.find((item) => item.adType === form.type)?.cpm || 0;

  const handleInput = (e: Event['Input']) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { ratio, brandedObjectPlacementId } = form;
    const ratioParts = ratio?.split('x') || [];

    if (ratioParts.length !== 3) {
      return;
    }

    const [heightStr, widthStr, depthStr] = ratioParts;
    const height = Number(heightStr);
    const width = Number(widthStr);
    const depth = Number(depthStr);

    if (Number.isNaN(height) || Number.isNaN(width) || Number.isNaN(depth)) {
      return;
    }

    mutate({
      variables: {
        input: {
          brandedObjectPlacementId,
          height,
          width,
          length: depth,
          categories: form.categories,
          type: form.type,
          gameId,
        },
      },
    });
  };

  const getButtonContent = () => {
    if (loading) return <Spinner size="sm" />;
    return editPlacement ? 'Update' : 'Create';
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="new-branded-object-modal">
        <div className="new-branded-object-modal__heading">
          <h2>New Branded Object Placement</h2>
          <button type="button" id="close-button" onClick={onClose}>
            <Icon name="close" size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {/* TODO: no name input in BE */}
          {/* <Input
            label="Name"
            name="name"
            value={form.name || ''}
            placeholder="Enter name"
            handleInput={handleInput}
            type="text"
          /> */}
          {/* TODO: floor price will not be disabled in the future */}
          <Input
            label="Floor Price"
            name="floorPrice"
            value={`$${floorPrice}`}
            placeholder="Floor Price"
            handleInput={() => {}}
            type="text"
            disabled
          />
          <RadioButtons
            label="Type"
            options={[
              { label: '3D branded object', value: 'THREE_D' },
              { label: '2D branded object', value: 'TWO_D' },
            ]}
            selectedValue={form.type || 'THREE_D'}
            setSelectedValue={(val) => isValidPricingType(val) && setForm((prev) => ({ ...prev, type: val }))}
          />
          {/* TODO: dropdown with values when they are ready */}
          <Input
            label="Ratio (H x L x W)"
            name="ratio"
            value={form.ratio}
            placeholder="e.g. 1x1x1"
            handleInput={handleInput}
            type="text"
          />
          <IabSelect
            label="IAB Categories"
            placeholder="Add categories"
            onCategoriesChange={handleCategoriesSelect}
            categories={editPlacement?.categories || []}
          />

          <button type="submit" className="btn-neutral-solid" disabled={loading}>
            {getButtonContent()}
          </button>
        </form>
        {error && <p className="error">An error occurred. Please try again.</p>}
      </div>
    </Modal>
  );
};
export default NewBrandedObjectPlacementModal;
