import { gql } from '@apollo/client';

export const CHAIN_FIELDS = gql`
  fragment ChainFields on Chain {
    name
    chainSymbol
    mainnet {
      chainId
      name
      isActive
    }
    testnet {
      chainId
      name
    }
  }
`;

export const USER_FIELDS = gql`
  fragment UserFields on User {
    email
    username
    data {
      firstName
      lastName
    }
    isAgreedToLicense
    externalAccounts {
      discordId
      twitterId
    }
    image {
      url
      uploadUrl
      fileId
    }
    isActive
    orgId
    role
    scopes
    stats {
      games
      value
    }
    userId
    verified {
      isEmail
    }
    walletAddress
    notifications {
      message
      meta {
        key
        value
      }
      notificationId
      type
      userId
      isRead
    }
    gettingStartedSteps {
      gettingStartedStepId
      order
      title
      isDone
      type
      url
    }
    balance {
      balance
    }
  }
`;

export const TRANSACTION_FIELDS = gql`
  fragment TransactionFields on Transaction {
    ownableAssetId
    date
    type
    status
    nftId
    charge {
      currency
      value
    }
    sender {
      name
      imageUrl
      walletAddress
    }
    gasFees {
      chainId
      value
    }
    receiver {
      name
      imageUrl
      walletAddress
    }
    asset {
      assetId
      metadata {
        name
        image
      }
    }
  }
`;

export const BRANDED_OBJECT_FIELDS = gql`
  fragment BrandedObjectFields on BrandedObject {
    brandedObjectId
    brandedObjectSize
    brandedObjectType
    contentTags {
      extension
      name
      taxonomyContentId
      tier1
      tier2
      tier3
      tier4
      updatedAt
    }
    depth
    description
    height
    image {
      extension
      fileId
      isIpfs
      name
      uploadUrl
      url
    }
    model {
      extension
      fileId
      isIpfs
      name
      uploadUrl
      url
    }
    name
    texture {
      extension
      fileId
      isIpfs
      name
      uploadUrl
      url
    }
    updatedAt
    width
  }
`;

export const GAME_FIELDS = gql`
  fragment GameFields on Game {
    isActive
    name
    orgId
    gameId
    updatedAt
    description
    category
    image {
      url
      fileId
    }
    coverImage {
      url
      fileId
    }
    gameConnect {
      status
    }
    gameConnectAuth {
      authorized
    }
    stats {
      players
      value
    }
    adCampaignStats {
      impressions
    }
    players {
      items {
        ...UserFields
      }
    }
    apiCredentials {
      apiKey
      apiSecret
      expiry
      isTestNet
      scopes
    }
    iosData {
      bundleId
      url
    }
    androidData {
      packageName
      url
    }
    webData {
      url
    }
    genres
    monthlyActiveUsers
    topCountryDistribution {
      countryCode
      monthlyActiveUsers
    }
    contentTags {
      extension
      name
      taxonomyContentId
      tier1
      tier2
      tier3
      tier4
      updatedAt
    }
  }
  ${USER_FIELDS}
`;
