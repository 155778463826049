import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../components/Icon/Icon';
import { BrandsList, Dispatcher, Event, Refetch, OrganizationData, Brand } from '../../../global/interfaces';
import { BRANDS_SEARCH_QUERY, GET_ORG_BRANDS } from '../../../global/gql/queries';
import { useFileUpload, useLazyReneQuery, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { UPSERT_BRAND_MUTATION } from '../../../global/gql/mutations';
import { validations } from './validations';
import Modal from '../../../components/modal/modal';
import Input from '../../../components/input/input';
import Drawer from '../../../components/drawer/drawer';
import Search from '../../../components/search/search';
import Spinner from '../../../components/spinner/spinner';
import Textarea from '../../../components/textarea/textarea';
import NewBrandModal from '../../../components/modal/new-brand-modal/new-brand-modal';
import EditableImage from '../../../components/editable-image/editable-image';
import LoadingFallback from '../../../components/loading-fallback/loading-fallback';
import placeholder from '../../../global/images/avatar.webp';
import './organization-brands-page.scss';

const BrandCard = ({ brand, setSelectedBrand }: { brand: Brand; setSelectedBrand: Dispatcher<Brand | undefined> }) => {
  return (
    <button type="button" className="organization-brands-page__brand" onClick={() => setSelectedBrand(brand)}>
      <img src={brand?.image?.url || placeholder} alt="brand" />
      <h3>{brand.name}</h3>
      <Link to={brand.website} target="_blank" rel="noreferrer">
        {brand.website}
      </Link>
    </button>
  );
};

const BrandView = ({
  brand,
  refetch,
  setSelectedBrand,
}: {
  brand: Brand;
  refetch: Refetch<
    | {
        Organization: OrganizationData;
      }
    | undefined
  >;
  setSelectedBrand: Dispatcher<Brand | undefined>;
}) => {
  const uploadFile = useFileUpload();
  const { errors, isFormInvalid } = useValidation(validations);
  const [prevBrand, setPrevBrand] = useState<Brand>();
  const [file, setFile] = useState<File>();
  const [form, setForm] = useState<{
    name: string;
    description: string;
    image?: string;
    website: string;
  }>({
    name: '',
    description: '',
    image: '',
    website: '',
  });

  if (brand && brand !== prevBrand) {
    setPrevBrand(brand);
    setForm({
      name: brand?.name,
      description: brand?.description,
      image: brand?.image?.url,
      website: brand?.website || '',
    });
  }

  const [upsertBrand, { loading }] = useReneMutation(UPSERT_BRAND_MUTATION, {
    onCompleted(data: { UpsertBrand: Brand }) {
      if (file) {
        uploadFile(data.UpsertBrand.image.uploadUrl, file).finally(() => {
          refetch();
        });
      } else {
        refetch();
      }
    },
  });

  const handleFormChange = (e: Event['Input'] | Event['TextArea'] | Event['Select']) => {
    setForm((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleEditBrand = () => {
    if (isFormInvalid(form)) return;
    let variables: {
      brandId: string;
      name: string;
      description: string;
      image?: { extension: string; fileId: string };
      website: string;
    } = {
      brandId: brand.brandId,
      name: form.name.trim(),
      description: form.description.trim(),
      website: form.website,
    };

    variables =
      file && prevBrand?.image.fileId
        ? { ...variables, image: { extension: file?.type.split('/')[1], fileId: prevBrand?.image.fileId } }
        : variables;

    upsertBrand({ variables });
  };

  return (
    <div className="brand-view">
      <div className="brand-view__form">
        <EditableImage label="Logo" imageUrl={form.image} alt="brand" setFile={setFile} />
        <Input
          label="Name"
          name="name"
          placeholder="Enter brand name"
          handleInput={handleFormChange}
          value={form.name}
          errorMessage={errors?.name}
        />
        <Textarea
          label="Description"
          name="description"
          value={form.description}
          handleInput={handleFormChange}
          placeholder="Enter brand description"
          showCounter
          maxLength={100}
          errorMessage={errors?.description}
        />
        <Input
          label="Website"
          name="website"
          className="brand-view__website"
          placeholder="Enter website url"
          handleInput={handleFormChange}
          value={form.website}
          errorMessage={errors?.website}
        />
      </div>

      <div className="brand-view__actions">
        <button type="button" className="btn-neutral-ghost" onClick={() => setSelectedBrand(undefined)}>
          Close
        </button>
        <button type="button" className="btn-primary-solid" onClick={handleEditBrand}>
          {loading ? <Spinner size="sm" /> : 'Save'}
        </button>
      </div>
    </div>
  );
};

const OrganizationBrandsPage = () => {
  const [selectedBrand, setSelectedBrand] = useState<Brand>();
  const [brandSearchTerm, setBrandsSearchTerm] = useState('');
  const [isAddBrandModal, setAddBrandModal] = useState(false);

  const [search, { data: searchBrands, loading: searchLoading }] = useLazyReneQuery<{ BrandSearch: BrandsList }>(
    BRANDS_SEARCH_QUERY,
  );

  const { data: brands, loading, refetch } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_BRANDS);

  const showData = brandSearchTerm && brands ? searchBrands?.BrandSearch : brands?.Organization.brands;

  useEffect(() => {
    if (brandSearchTerm) {
      search({ variables: { brandSearchTerm } });
    }
  }, [brandSearchTerm, search]);

  const handleSearch = useCallback((data: string) => {
    setBrandsSearchTerm(data);
  }, []);

  return (
    <div className="organization-brands-page">
      <div className="organization-brands-page__header">
        <h2>
          Brands{' '}
          {brands?.Organization.brands.items?.length && brands.Organization.brands?.items.length > 0
            ? brands?.Organization.brands.items?.length
            : ''}
        </h2>
        <Search callback={handleSearch} apiSearch />
        <button type="button" className="btn-primary-solid" onClick={() => setAddBrandModal(true)}>
          <Icon name="plus" />
          <p>New Brand</p>
        </button>
      </div>
      <div className="organization-brands-page__content">
        {searchLoading || loading ? (
          <LoadingFallback />
        ) : (
          showData?.items.map((brand) => (
            <BrandCard key={brand.brandId} brand={brand} setSelectedBrand={setSelectedBrand} />
          ))
        )}
      </div>
      <Modal isOpen={isAddBrandModal}>
        <NewBrandModal setCloseModal={() => setAddBrandModal(false)} refetch={refetch} />
      </Modal>
      <Drawer
        title={selectedBrand?.name as string}
        isOpen={!!selectedBrand}
        onClose={() => setSelectedBrand(undefined)}
      >
        {selectedBrand && <BrandView brand={selectedBrand} refetch={refetch} setSelectedBrand={setSelectedBrand} />}
      </Drawer>
    </div>
  );
};

export default OrganizationBrandsPage;
