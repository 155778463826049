import { useEffect, useState } from 'react';
import { DatePicker } from '../../../../components/calendar/calendar';
import { GET_REACH_ESTIMATE, GET_REGIONS_AND_COUNTRIES } from '../../../../global/gql/queries';
import { ReachEstimateData, RegionsAndCountriesData } from '../../../../global/interfaces';
import { useLazyReneQuery, useReneQuery } from '../../../../hooks';
import Icon from '../../../../components/Icon/Icon';
import Table from '../../../../components/table/table';
import WorldMap from '../../../../components/world-map/world-map';
import './reach-drawer-content.scss';

const ReachDrawerContent = () => {
  const { data: regionsAndCountries } = useReneQuery<{ RegionsAndCountries: RegionsAndCountriesData }>(
    GET_REGIONS_AND_COUNTRIES,
  );

  const countryCodeToName: { [code: string]: string } =
    regionsAndCountries?.RegionsAndCountries?.items
      ?.flatMap((region) => region.countries)
      ?.reduce((acc, country) => {
        acc[country.code] = country.name;
        return acc;
      }, {} as { [code: string]: string }) || {};

  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  // Get countries from selected regions
  const countriesInSelectedRegions =
    regionsAndCountries?.RegionsAndCountries?.items
      ?.filter((i) => selectedCountries.includes(i.region.code))
      .flatMap((i) => i.countries) || [];

  // All country codes to use in the query (from both region selection and direct country selection)
  const allSelectedCountryCodes = [
    ...new Set([...countriesInSelectedRegions.map((c) => c.code), ...selectedCountries]),
  ];

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  const [getReachEstimate, { data: reachEstimate, loading: reachEstimateLoading }] = useLazyReneQuery<{
    ReachEstimate: ReachEstimateData;
  }>(GET_REACH_ESTIMATE, {
    variables: {
      input: {
        countryCodes: allSelectedCountryCodes,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    },
  });

  useEffect(() => {
    if (startDate && endDate && selectedCountries.length > 0) {
      getReachEstimate();
    }
  }, [getReachEstimate, startDate, endDate, selectedCountries]);

  // Handle country selection from the map
  const handleCountrySelect = (countryCode: string, isSelected: boolean) => {
    setSelectedCountries((prev) => {
      let newSelection;
      if (isSelected) {
        // Ensure we're storing uppercase ISO2 codes
        const formattedCode = /^[A-Z]{2}$/i.test(countryCode) ? countryCode.toUpperCase() : countryCode;

        newSelection = [...prev, formattedCode];
      } else {
        newSelection = prev.filter((code) => code.toUpperCase() !== countryCode.toUpperCase());
      }
      return newSelection;
    });
  };

  const columns = [
    { header: 'Country', accessor: 'countryName' },
    { header: 'Games', accessor: 'gameCount' },
    { header: 'MAU', accessor: 'regionMonthlyActiveUsers' },
    { header: 'vs Global %', accessor: 'regionVersusGlobalPercentage' },
    { header: 'Campaign Reach', accessor: 'campaignReachEstimate' },
  ];

  const data = reachEstimate?.ReachEstimate.entries.length
    ? reachEstimate.ReachEstimate.entries.map((entry) => ({
        countryName: countryCodeToName[entry.countryCode] || entry.countryCode,
        gameCount: entry.gameCount,
        regionMonthlyActiveUsers: entry.regionMonthlyActiveUsers,
        regionVersusGlobalPercentage: entry.regionVersusGlobalPercentage,
        campaignReachEstimate: entry.campaignReachEstimate,
      }))
    : [];

  const statistics: [string, string | number][] = [
    ['Unique Games', reachEstimate?.ReachEstimate.gameCount ?? 0],
    ['Global MAU', reachEstimate?.ReachEstimate.globalMonthlyActiveUsers ?? 0],
    ['Region MAU', reachEstimate?.ReachEstimate.regionMonthlyActiveUsers ?? 0],
    ['Region vs Global', `${reachEstimate?.ReachEstimate.regionVersusGlobalPercentage ?? 0}%*`],
    ['Campaign Reach', reachEstimate?.ReachEstimate.campaignReachEstimate ?? 0],
  ];

  return (
    <div className="reach-drawer-content">
      <span>
        Estimate your campaign reach by selecting target regions, viewing active player data, and calculating potential
        audience impact across multiple markets.
      </span>
      <div className="reach-drawer-content__map-container">
        <WorldMap
          countries={regionsAndCountries?.RegionsAndCountries?.items?.flatMap((i) => i.countries) || []}
          selectedCountries={allSelectedCountryCodes}
          onCountrySelect={handleCountrySelect}
        />
      </div>
      <div className="reach-drawer-content__dates">
        <DatePicker label="Start Date" selectedDay={startDate} setSelectedDay={setStartDate} />
        <DatePicker label="End Date" selectedDay={endDate} setSelectedDay={setEndDate} />
      </div>
      <span className="reach-drawer-content__disclaimer">
        <Icon name="alert" size={16} /> * Indicates estimated value
      </span>
      {reachEstimateLoading ? (
        <div className="reach-drawer-content__loading">Loading reach estimates...</div>
      ) : (
        <Table columns={columns} data={data} statistics={statistics} />
      )}
    </div>
  );
};

export default ReachDrawerContent;
