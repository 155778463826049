import { useState } from 'react';
import Modal from '../modal';
import Input from '../../input/input';
import Textarea from '../../textarea/textarea';
import FileUpload from '../../file-upload/file-upload';

import './instruction-step-modal.scss';

interface InstructionStepModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const InstructionStepModal: React.FC<InstructionStepModalProps> = ({ isOpen, onClose }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [screenshot, setScreenshot] = useState<File | null>(null);

  const handleFileChange = (files: File[]) => {
    if (files.length) {
      setScreenshot(files[0]);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission, e.g. send data to server
    // eslint-disable-next-line no-console
    console.log({ title, description, screenshot });
    onClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <div className="instruction-step-modal">
        <div className="instruction-step-modal__header">
          <h2>New Instruction Step</h2>
          <button type="button" className="instruction-step-modal__close-button" onClick={onClose}>
            X
          </button>
        </div>
        <form onSubmit={handleSubmit} className="instruction-step-modal__form">
          <Input
            label="Title"
            name="title"
            placeholder="Enter step title"
            value={title}
            handleInput={(e) => setTitle(e.target.value)}
          />
          <Textarea
            label="Description"
            name="description"
            placeholder="Enter step description"
            value={description}
            handleInput={(e) => setDescription(e.target.value)}
          />
          <FileUpload label="Screenshot" setFiles={handleFileChange} multiple={false} />
          <div className="instruction-step-modal__actions">
            <button type="button" onClick={onClose} className="btn-neutral-ghost">
              Cancel
            </button>
            <button type="submit" className="btn-primary-solid">
              Create
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default InstructionStepModal;
