import React from 'react';

type SetErrorMessage = React.Dispatch<React.SetStateAction<{ message: string; showError: boolean }>>;

export const ErrorContext = React.createContext<
  { error: { message: string; showError: boolean }; setError: SetErrorMessage } | undefined
>(undefined);

export function useErrorContext() {
  const context = React.useContext(ErrorContext);
  if (context === undefined) {
    throw new Error('useErrorContext must be used within a NotificationProvider');
  }
  return context;
}
