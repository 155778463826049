import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useApolloClient } from '@apollo/client';
import { useReneMutation, useValidation } from '../../../hooks';
import { DatePicker } from '../../calendar/calendar';
import { CampaignAssetType, CampaignStatusLabel } from '../../../global/consts';
import { UPSERT_AD_CAMPAIGN_MUTATION } from '../../../global/gql/mutations';
import { GET_AD_CAMPAIGN_QUERY, GET_AD_CAMPAIGNS_QUERY } from '../../../global/gql/queries';
import { CampaignData, Dispatcher, UpsertCampaignAction } from '../../../global/interfaces';
import Icon from '../../Icon/Icon';
import Modal from '../../modal/modal';
import Select from '../../select/select';
import Spinner from '../../spinner/spinner';
import { validations } from './validations';

import './campaign-banner.scss';

const CampaignBanner = ({
  status,
  campaignType,
  adCampaignId,
  name,
  usedImpressions,
  usedCost,
  startDate,
  endDate,
  brandName,
  allowEditCampaign,
  campaignLoading,
  setIsOpenCampaignModal,
}: {
  status: CampaignData['status'] | undefined;
  campaignType: CampaignAssetType | undefined;
  adCampaignId: string | undefined;
  name: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  brandName: string | undefined;
  allowEditCampaign: boolean;
  campaignLoading: boolean;
  usedImpressions: number | undefined;
  usedCost: number | undefined;
  setIsOpenCampaignModal: () => void;
}) => {
  const { errors, isFieldInvalid } = useValidation(validations);

  const apolloClient = useApolloClient();

  const campaignStartDate = startDate && new Date(startDate);
  const campaignEndDate = endDate && new Date(endDate);

  const [isReactivateModal, setIsReactivateModal] = useState(false);
  const [upsertCampaign, { loading }] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted: async () => {
      await apolloClient.refetchQueries({
        include: [GET_AD_CAMPAIGNS_QUERY, GET_AD_CAMPAIGN_QUERY],
      });
      setIsReactivateModal(false);
    },
  });

  const campaignOptions: { key: number; label: string; func: Dispatcher<boolean> }[] = useMemo(
    () => [
      {
        key: 0,
        label: 'Edit',
        icon: <Icon name="edit" />,
        func: setIsOpenCampaignModal,
      },
    ],
    [setIsOpenCampaignModal],
  );

  const handleBrandedAssetAction = useCallback(
    (index: number) => {
      campaignOptions[index].func(true);
    },
    [campaignOptions],
  );

  const handleReactivate = (date: Date) => {
    if (!adCampaignId || !date || isFieldInvalid('endDate', { endDate: date, startDate })) return;
    upsertCampaign({
      variables: {
        adCampaignId,
        endDate: date,
      },
    });
  };

  const updateCampaignStatus = useCallback(
    (action: UpsertCampaignAction) => upsertCampaign({ variables: { adCampaignId, action } }),
    [adCampaignId, upsertCampaign],
  );

  const actionButton = useCallback(() => {
    let label = '';
    let className = 'campaign-banner__action';
    let action = () => {};
    if (status === 'FINISHED' || status === 'DRAFT') {
      return null;
    }
    if (status === 'STOPPED' || status === 'UNSCHEDULED') {
      label = status === 'STOPPED' ? 'Resume' : 'Schedule';
      action = () => updateCampaignStatus('SCHEDULED');
      className += ' campaign-banner__action--schedule';
    } else if (status === 'IN_PROGRESS') {
      label = 'Stop Campaign';
      action = () => updateCampaignStatus('STOPPED');
      className += ' campaign-banner__action--stop';
    } else if (status === 'SCHEDULED') {
      label = 'Unschedule';
      action = () => updateCampaignStatus('UNSCHEDULED');
      className += ' campaign-banner__action--unschedule';
    }
    return (
      <button className={className} onClick={action} disabled={loading || campaignLoading} type="button">
        {loading ? <Spinner size="sm" /> : label}
      </button>
    );
  }, [status, updateCampaignStatus, loading, campaignLoading]);

  return (
    <div className="campaign-banner">
      {allowEditCampaign && (
        <Select
          className="campaign-banner__dots"
          value=""
          placeholder=""
          options={campaignOptions}
          changeHandler={handleBrandedAssetAction}
          showListValueFn={(option) => {
            return (
              <>
                {option.icon} {option.label}
              </>
            );
          }}
        >
          <Icon name="dots" size={44} />
        </Select>
      )}
      <div className="campaign-banner__title">
        <div>
          <h2>{brandName}</h2>
          <h1>{name}</h1>
          {status && (
            <div className="campaign-banner__date">
              <p className={status}>{CampaignStatusLabel[status]}</p>
              <p>{campaignStartDate && format(new Date(startDate), 'MMM dd')}.</p> -
              <p>{campaignEndDate && format(new Date(endDate), 'MMM dd, yyyy')}.</p>
            </div>
          )}
        </div>
      </div>
      <div className="campaign-banner__stats">
        {campaignType && <p>{campaignType === 'BANNER' ? 'Ad Banner' : 'Branded Asset'}</p>}
        <p>
          <span>{usedImpressions}</span> impressions
        </p>
        <p>
          $ <span>{usedCost}</span>
        </p>
      </div>
      {actionButton()}

      <Modal isOpen={isReactivateModal}>
        <ReactivateCampaignModal
          error={errors?.endDate}
          loading={loading}
          handleReactivate={handleReactivate}
          setIsReactivateModal={setIsReactivateModal}
        />
      </Modal>
    </div>
  );
};

export default CampaignBanner;

const ReactivateCampaignModal = ({
  loading,
  error,
  handleReactivate,
  setIsReactivateModal,
}: {
  loading: boolean;
  error: string;
  handleReactivate: (date: Date) => void;
  setIsReactivateModal: Dispatcher<boolean>;
}) => {
  const [date, setDate] = useState<Date>(new Date());

  const closeModal = () => {
    setIsReactivateModal(false);
  };

  return (
    <div className="reactivate-campaign-modal">
      <div className="reactivate-campaign-modal__heading">
        <h2>Reactivate Campaign</h2>
        <button type="button" onClick={closeModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="reactivate-campaign-modal__date">
        <p>New end date</p>
        <DatePicker selectedDay={date} setSelectedDay={setDate} />
        <p className="reactivate-campaign-modal__date_error">{error}</p>
      </div>
      <div className="reactivate-campaign-modal__actions">
        <button type="button" className="secondary-btn" onClick={closeModal} disabled={loading}>
          Cancel
        </button>
        <button type="button" className="primary-btn" onClick={() => handleReactivate(date)}>
          {loading ? <Spinner size="sm" /> : 'Create'}
        </button>
      </div>
    </div>
  );
};
