import { GET_PUBLIC_AD_CAMPAIGN_QUERY } from '../../../global/gql/queries';
import { Dispatcher, PublicCampaignsData, UserData } from '../../../global/interfaces';
import { useReneQuery } from '../../../hooks';
import Icon from '../../Icon/Icon';
import point from '../../../global/images/point.png';
import placeholder from '../../../global/images/avatar.webp';
import LoadingFallback from '../../loading-fallback/loading-fallback';

import './leaderboard-modal.scss';

const Rank = ({ leader }: { leader: { rank: string; amount: string; user: UserData } }) => (
  <div>
    <p>{leader.rank}</p>
    <div>
      <img src={leader.user.image?.url || placeholder} alt="profile" />
      <p>
        {leader.user.data.firstName} {leader.user.data.lastName}
      </p>
    </div>
    <p>
      {leader.amount} <img src={point} alt="rene point" />
    </p>
  </div>
);

const LeaderboardModal = ({
  adCampaignId,
  setShowLeaderboard,
}: {
  adCampaignId: string;
  setShowLeaderboard: Dispatcher<string>;
}) => {
  const { data, loading } = useReneQuery<{ PublicAdCampaigns: PublicCampaignsData } | undefined>(
    GET_PUBLIC_AD_CAMPAIGN_QUERY,
    {
      variables: {
        adCampaignId,
      },
    },
  );

  return (
    <div className="leaderboard-modal">
      <div className="leaderboard-modal__heading">
        <div>
          <h2>Leaderboard</h2>
          <p>{data?.PublicAdCampaigns?.items[0]?.name}</p>
        </div>
        <button type="button" onClick={() => setShowLeaderboard('')}>
          <Icon name="close" size={24} />
        </button>
      </div>
      {!loading ? (
        <div className="leaderboard-modal__main">
          {data?.PublicAdCampaigns?.items[0]?.leaderboard?.items.map((leader, i) => (
            <Rank leader={leader} key={i} />
          ))}
        </div>
      ) : (
        <LoadingFallback />
      )}
    </div>
  );
};

export default LeaderboardModal;
