import { useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { FIRST_TIME_USER, LOGIN } from '../../../global/routes';
import { TOKEN } from '../../../global/consts';
import { UserData } from '../../../global/interfaces';
import { handleError } from '../../../utils';
import { GET_USER_ROLE_QUERY } from '../../../global/gql/queries';
import { useErrorContext } from '../../../context/error-context/use-error-context';
import { useReneMutation, useReneQuery } from '../../../hooks';
import { INITIATE_USER_VERIFICATION_MUTATION, VERIFY_USER_MUTATION } from '../../../global/gql/mutations';
import Spinner from '../../../components/spinner/spinner';
import OtpInput from '../../../components/otp-input/otp-input';

import reneLogo from '../../../global/images/rene-logo-new-big.png';

import './verify-user-page.scss';

const VerifyUserPage = () => {
  const { setError } = useErrorContext();
  const navigate = useNavigate();
  const [otp, setOtp] = useState('');
  const [wrongOtp, setWrongOtp] = useState('');
  const onChange = (value: string) => setOtp(value);

  const { data } = useReneQuery<{ User: UserData }>(GET_USER_ROLE_QUERY, {
    fetchPolicy: 'cache-first',
  });

  const [initiateVerifyUser, { loading: otpLoading }] = useReneMutation(INITIATE_USER_VERIFICATION_MUTATION);

  const [verifyUser, { loading }] = useReneMutation(VERIFY_USER_MUTATION, {
    variables: {
      otp,
    },
    onCompleted(data: { VerifyUser: { jwt: string } }) {
      if (data.VerifyUser.jwt) {
        localStorage.setItem(TOKEN, data?.VerifyUser.jwt);
        navigate(`/${FIRST_TIME_USER}`, { replace: true });
      }
    },
    onError(err: ApolloError) {
      if (err.message === 'Otp is invalid / expired') {
        return setWrongOtp('Wrong OTP! Please try again');
      }
      handleError(err, setError);
    },
  });

  const handleVerifyUser = () => {
    verifyUser();
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate(`/${LOGIN}`);
  };

  return (
    <div className="verify-user-page">
      <div className="verify-user-page__logo">
        <img src={reneLogo} alt="reneverse" />
      </div>
      <div className="verify-user-page__form">
        <h1>Enter OTP Code</h1>
        <h2>Please enter the 6-digit code from the email we sent you to {data?.User.email}</h2>
        <OtpInput value={otp} valueLength={6} onChange={onChange} submit={handleVerifyUser} />
        {wrongOtp && <h3>{wrongOtp}</h3>}
        <button type="button" className="btn-primary-solid" disabled={otp.length !== 6} onClick={handleVerifyUser}>
          {loading ? <Spinner size="sm" /> : 'Verify'}
        </button>
        <div className="verify-user-page__form_resend">
          <p>Did you not receive OTP code? </p>
          <button type="button" onClick={() => initiateVerifyUser()}>
            {otpLoading ? <Spinner size="sm" /> : 'Send again'}
          </button>
        </div>
        <p className="verify-user-page__form_back">
          Change the email address?
          <button type="button" onClick={handleLogout}>
            Signup here
          </button>
        </p>
      </div>
    </div>
  );
};

export default VerifyUserPage;
