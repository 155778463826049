/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';
import { CONTENT_SEARCH } from '../../global/gql/queries';
import { Content, ContentSearchData } from '../../global/interfaces';
import { useReneQuery } from '../../hooks/useReneQuery';
import Icon from '../Icon/Icon';
import Search from '../search/search';
import Spinner from '../spinner/spinner';
import SmallErrorMsg from '../small-error-msg/small-error-msg';

import './iab-select.scss';

interface CategoryNode {
  name: string;
  children?: Record<string, CategoryNode>;
}

function organizeCategories(data: Content[]): Record<string, CategoryNode> {
  const tree: Record<string, CategoryNode> = {};

  data.forEach((item) => {
    const { tier1, tier2, tier3, tier4 } = item;

    if (!tree[tier1]) {
      tree[tier1] = { name: tier1, children: {} };
    }
    if (tier2 && !tree[tier1].children![tier2]) {
      tree[tier1].children![tier2] = { name: tier2, children: {} };
    }
    if (tier3 && tier2) {
      if (!tree[tier1].children![tier2].children![tier3]) {
        tree[tier1].children![tier2].children![tier3] = { name: tier3, children: {} };
      }
    }
    if (tier4 && tier3 && tier2) {
      tree[tier1].children![tier2].children![tier3].children![tier4] = { name: tier4 };
    }
  });

  return tree;
}

const IabSelect = ({
  label,
  placeholder,
  disabled = false,
  onCategoriesChange,
  categories,
  errorMsg,
}: {
  disabled?: boolean;
  label: string;
  placeholder: string;
  onCategoriesChange?: (categories: string[], categoriesWithData?: Content[]) => void;
  categories?: string[];
  errorMsg?: string;
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const initialRenderRef = useRef(true);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategories, setSelectedCategories] = useState<Record<string, boolean>>({});
  const [expandedCategories, setExpandedCategories] = useState<Record<string, boolean>>({});
  const [initialCategories, setInitialCategories] = useState<Content[]>([]);

  const { data, loading } = useReneQuery<{
    ContentSearch: ContentSearchData;
  }>(CONTENT_SEARCH, {
    variables: { contentSearchTerm: searchTerm },
  });

  useEffect(() => {
    if (data?.ContentSearch?.items && !initialCategories.length) {
      setInitialCategories(data.ContentSearch.items);
    }
  }, [data?.ContentSearch.items, selectedCategories]);

  useEffect(() => {
    if (onCategoriesChange) {
      const selectedPaths = Object.entries(selectedCategories)
        .filter(([_, isSelected]) => isSelected)
        .map(([path]) => path);
      const categories = selectedPaths.map((path) => path.split(' > ').pop() ?? '').filter(Boolean);
      if (initialCategories.length) {
        const categoriesWithData = initialCategories.filter((item) => {
          // Build the full path for the item
          const itemPath = [item.tier1, item.tier2, item.tier3, item.tier4].filter(Boolean).join(' > ');
          return selectedPaths.includes(itemPath);
        });
        onCategoriesChange(categories, categoriesWithData);
      }
    }
  }, [selectedCategories, data?.ContentSearch.items]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    if (initialRenderRef.current && categories?.length && data?.ContentSearch.items) {
      initialRenderRef.current = false;
      const expandPaths: Record<string, boolean> = {};
      const newSelectedCategories: Record<string, boolean> = {};

      categories.forEach((category) => {
        const item = data.ContentSearch.items.find((i) => i.name === category);
        if (item) {
          let path = '';
          if (item.tier1) {
            path = item.tier1;
            expandPaths[path] = true;
            if (item.tier2) {
              path = `${path} > ${item.tier2}`;
              expandPaths[path] = true;
              if (item.tier3) {
                path = `${path} > ${item.tier3}`;
                expandPaths[path] = true;
                if (item.tier4) {
                  path = `${path} > ${item.tier4}`;
                  expandPaths[path] = true;
                }
              }
            }
          }
          // Store the full path in selectedCategories
          if (path) {
            newSelectedCategories[path] = true;
          }
        }
      });

      setExpandedCategories(expandPaths);
      setSelectedCategories(newSelectedCategories);
    }
  }, [categories, data?.ContentSearch.items]);

  const getAllDescendantPaths = (category: CategoryNode, currentPath: string): string[] => {
    let paths: string[] = [currentPath];

    if (category.children) {
      Object.entries(category.children).forEach(([_, child]) => {
        const childPath = currentPath ? `${currentPath} > ${child.name}` : child.name;
        paths = [...paths, ...getAllDescendantPaths(child, childPath)];
      });
    }

    return paths;
  };

  const toggleCategory = (category: CategoryNode, path: string) => {
    const newSelectedState = !selectedCategories[path];
    const descendantPaths = getAllDescendantPaths(category, path);

    setSelectedCategories((prev) => {
      const newState = { ...prev };
      descendantPaths.forEach((descendantPath) => {
        newState[descendantPath] = newSelectedState;
      });
      return newState;
    });
  };

  const toggleExpand = (categoryPath: string) => {
    setExpandedCategories((prev) => ({
      ...prev,
      [categoryPath]: !prev[categoryPath],
    }));
  };

  const getCategorySelectionState = (category: CategoryNode, path: string): 'none' | 'partial' | 'all' => {
    const descendantPaths = getAllDescendantPaths(category, path);
    const selectedDescendants = descendantPaths.filter((p) => selectedCategories[p]);

    if (selectedDescendants.length === 0) return 'none';
    if (selectedDescendants.length === descendantPaths.length) return 'all';
    return 'partial';
  };

  const renderCategory = (category: CategoryNode, path = ''): JSX.Element => {
    const newPath = path ? `${path} > ${category.name}` : category.name;
    const hasChildren = category.children && Object.keys(category.children).length > 0;
    const isExpanded = expandedCategories[newPath];
    const selectionState = getCategorySelectionState(category, newPath);

    return (
      <div key={newPath}>
        <div className="iab-select__options_list_item">
          <button type="button" onClick={() => toggleCategory(category, newPath)}>
            <span
              className={`iab-select__checkbox ${selectionState !== 'none' ? 'iab-select__checkbox--selected' : ''}`}
            >
              {selectionState === 'all' && <Icon name="checkmark" />}
              {selectionState === 'partial' && <Icon name="minus" />}
            </span>
            <span>{category.name}</span>
          </button>
          {hasChildren && (
            <span
              className={`iab-select__options_list_item_arrow ${isExpanded ? 'expanded' : ''}`}
              onClick={() => toggleExpand(newPath)}
            />
          )}
        </div>
        {hasChildren && isExpanded && (
          <div className="iab-select__options_list_nested">
            {Object.values(category.children!).map((child) => renderCategory(child, newPath))}
          </div>
        )}
      </div>
    );
  };

  // const areAllCategoriesSelected = () => {
  //   const allCategories = data?.ContentSearch.items || [];
  //   const tree = organizeCategories(allCategories);
  //   const allPaths: string[] = [];

  //   Object.values(tree).forEach((category) => {
  //     allPaths.push(...getAllDescendantPaths(category, category.name));
  //   });

  //   return allPaths.length > 0 && allPaths.every((path) => selectedCategories[path]);
  // };

  // const handleSelectAll = () => {
  //   const allCategories = data?.ContentSearch.items || [];
  //   const tree = organizeCategories(allCategories);
  //   const allPaths: string[] = [];

  //   Object.values(tree).forEach((category) => {
  //     allPaths.push(...getAllDescendantPaths(category, category.name));
  //   });

  //   const shouldSelect = !areAllCategoriesSelected();

  //   const newState = allPaths.reduce((acc, path) => {
  //     acc[path] = shouldSelect;
  //     return acc;
  //   }, {} as Record<string, boolean>);

  //   setSelectedCategories(newState);
  // };

  const selectedCount = Object.values(selectedCategories).filter(Boolean).length;

  const areAllCategoriesExpanded = () => {
    const allCategories = data?.ContentSearch.items || [];
    const tree = organizeCategories(allCategories);
    const allPaths: string[] = [];

    Object.values(tree).forEach((category) => {
      const paths = getAllDescendantPaths(category, category.name);
      // Only include paths that have children (can be expanded)
      paths
        .filter((p) => p && typeof p === 'string')
        .forEach((path) => {
          const parts = path.split(' > ');
          const node = parts.reduce(
            (acc, part) => {
              return acc?.children?.[part] || null;
            },
            { children: tree } as CategoryNode | null,
          );
          if (node?.children && Object.keys(node.children).length > 0) {
            allPaths.push(path);
          }
        });
    });

    return allPaths.length > 0 && allPaths.every((path) => expandedCategories[path]);
  };

  const handleExpandAll = () => {
    const allCategories = data?.ContentSearch.items || [];
    const tree = organizeCategories(allCategories);
    const allPaths: string[] = [];

    Object.values(tree).forEach((category) => {
      const paths = getAllDescendantPaths(category, category.name);
      // Only include paths that have children (can be expanded)
      paths.forEach((path) => {
        const parts = path.split(' > ');
        const node = parts.reduce(
          (acc, part) => {
            return acc?.children?.[part] || null;
          },
          { children: tree } as CategoryNode | null,
        );
        if (node?.children && Object.keys(node.children).length > 0) {
          allPaths.push(path);
        }
      });
    });

    const shouldExpand = !areAllCategoriesExpanded();

    setExpandedCategories(
      allPaths.reduce((acc, path) => {
        acc[path] = shouldExpand;
        return acc;
      }, {} as Record<string, boolean>),
    );
  };

  const handleClearSelection = () => {
    setSelectedCategories({});
  };

  const handleSearch = useCallback((data: any) => {
    setSearchTerm(data);
  }, []);

  return (
    <div className={`iab-select ${disabled ? 'disabled' : ''}`} ref={containerRef}>
      {label && <label className="iab-select__label">{label}</label>}
      <div className={`iab-select__trigger ${isOpen ? 'open' : ''}`} onClick={() => !disabled && setIsOpen(!isOpen)}>
        <p className="iab-select__trigger_selected">
          {selectedCount > 0 ? (
            Object.entries(selectedCategories)
              .filter(([_, isSelected]) => isSelected)
              .map(([path]) => path.split(' > ').pop())
              .join(', ')
          ) : (
            <span>{placeholder}</span>
          )}
        </p>
        <span className="iab-select__trigger_arrow" />
      </div>
      {isOpen && !disabled && (
        <div className="iab-select__options">
          <div className="iab-select__options_search">
            <Search callback={handleSearch} disabled={disabled} apiSearch autoFocus />
          </div>
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <div className="iab-select__options_list">
              <div className="iab-select__options_list_item iab-select__options_list_item_expand">
                {/* <div>
                  <span
                    className={`iab-select__checkbox ${
                      areAllCategoriesSelected() ? 'iab-select__checkbox--selected' : ''
                    }`}
                    onClick={handleSelectAll}
                  >
                    {areAllCategoriesSelected() && <Icon name="checkmark" />}
                  </span>
                  <span>Select All</span>
                </div> */}
                <button type="button" onClick={handleExpandAll}>
                  {areAllCategoriesExpanded() ? 'Collapse' : 'Expand'}
                </button>
              </div>
              {Object.values(organizeCategories(data?.ContentSearch.items || [])).map((category) =>
                renderCategory(category),
              )}
            </div>
          )}

          {selectedCount > 0 && (
            <div className="iab-select__options_footer">
              <div>
                <button type="button" onClick={handleClearSelection}>
                  <Icon name="close" />
                </button>
                <p>{selectedCount} selected</p>
              </div>
              <button type="button" className="btn-primary-solid" onClick={() => setIsOpen(false)}>
                Done
              </button>
            </div>
          )}
        </div>
      )}
      <SmallErrorMsg message={errorMsg} />
    </div>
  );
};

export default IabSelect;
