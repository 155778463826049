import { useCallback, useEffect, useState } from 'react';
import { GET_REGIONS_AND_COUNTRIES } from '../../../../../global/gql/queries';
import { Content, RegionsAndCountriesData } from '../../../../../global/interfaces';
import { useReneQuery } from '../../../../../hooks';
import { GameFormData } from '../../types';
import Input from '../../../../input/input';
import Checkbox from '../../../../checkbox/checkbox';
import IabSelect from '../../../../iab-select/iab-select';
import DropdownSearch from '../../../../dropdown-search/dropdown-search';

import './game-details-step.scss';

type GameDetailsStepProps = {
  updateFormData: <K extends keyof GameFormData>(step: K, data: Partial<GameFormData[K]>) => void;
  formData: GameFormData;
  errors?: { [key: string]: any };
};

const returnMAUOrMax = (value: number | string | undefined) => {
  const num = typeof value === 'string' ? parseInt(value) : value;
  if (!num || Number.isNaN(num)) return undefined;
  return Math.min(num, 100_000_000);
};

const regionsAndCountriesDataToCountriesOptions = (data: { RegionsAndCountries: RegionsAndCountriesData }) =>
  data?.RegionsAndCountries.items
    .flatMap((rc) => rc.countries)
    .map((c) => ({
      label: c.name,
      value: c.code,
    })) || [];

const GameDetailsStep = ({ updateFormData, formData, errors }: GameDetailsStepProps) => {
  const { data: regionsAndCountries } = useReneQuery<{ RegionsAndCountries: RegionsAndCountriesData }>(
    GET_REGIONS_AND_COUNTRIES,
  );

  const [countryOptions, setCountryOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    if (regionsAndCountries) {
      setCountryOptions(regionsAndCountriesDataToCountriesOptions(regionsAndCountries));
    }
  }, [regionsAndCountries]);

  const handleCountrySearch = useCallback(
    (searchTerm: string) => {
      if (regionsAndCountries) {
        if (searchTerm === '') {
          setCountryOptions(regionsAndCountriesDataToCountriesOptions(regionsAndCountries));
        }
        setCountryOptions(
          regionsAndCountriesDataToCountriesOptions(regionsAndCountries).filter(
            (c) =>
              c.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
              c.value.toLowerCase().includes(searchTerm.toLowerCase()),
          ),
        );
      }
    },
    [regionsAndCountries],
  );

  const handleCategoriesChange = useCallback(
    (_: string[], categoriesWithData?: Content[]) =>
      updateFormData('gameDetails', { iabCategories: categoriesWithData ?? [] }),
    [updateFormData],
  );

  return (
    <div className="game-details-step">
      <div className="game-details-step__title">
        <h2>Game Specifications</h2>
        <p>Define the game type, platforms, and categories to optimize campaign matching.</p>
      </div>
      <div className="game-details-step__checkboxes">
        <div className="game-details-step__checkboxes__os">
          <Checkbox
            value={formData.gameDetails.operatingSystems.includes('IOS')}
            setValue={(value) => {
              const updatedOS = value
                ? [...formData.gameDetails.operatingSystems, 'IOS']
                : formData.gameDetails.operatingSystems.filter((os) => os !== 'IOS');
              updateFormData('gameDetails', {
                operatingSystems: updatedOS,
                iosData: value ? formData.gameDetails.iosData : { bundleId: '', url: '' },
              });
            }}
          >
            iOS
          </Checkbox>
          <Input
            name="iosUrl"
            placeholder="iOS store URL"
            handleInput={(e) =>
              updateFormData('gameDetails', {
                iosData: { url: e.target.value, bundleId: formData.gameDetails.iosData?.bundleId || '' },
              })
            }
            value={formData.gameDetails.iosData?.url || ''}
            disabled={!formData.gameDetails.operatingSystems.includes('IOS')}
            errorMessage={formData.gameDetails.operatingSystems.includes('IOS') ? errors?.iosUrl : undefined}
          />
          <Input
            name="iosBundleId"
            placeholder="Bundle ID"
            handleInput={(e) =>
              updateFormData('gameDetails', {
                iosData: { bundleId: e.target.value, url: formData.gameDetails.iosData?.url || '' },
              })
            }
            value={formData.gameDetails.iosData?.bundleId || ''}
            disabled={!formData.gameDetails.operatingSystems.includes('IOS')}
            errorMessage={formData.gameDetails.operatingSystems.includes('IOS') ? errors?.iosBundleId : undefined}
          />
        </div>

        <div className="game-details-step__checkboxes__os">
          <Checkbox
            value={formData.gameDetails.operatingSystems.includes('ANDROID')}
            setValue={(value) => {
              const updatedOS = value
                ? [...formData.gameDetails.operatingSystems, 'ANDROID']
                : formData.gameDetails.operatingSystems.filter((os) => os !== 'ANDROID');
              updateFormData('gameDetails', {
                operatingSystems: updatedOS,
                androidData: value ? formData.gameDetails.androidData : { url: '', packageName: '' },
              });
            }}
          >
            Android
          </Checkbox>
          <Input
            name="androidUrl"
            placeholder="Android store URL"
            handleInput={(e) =>
              updateFormData('gameDetails', {
                androidData: { url: e.target.value, packageName: formData.gameDetails.androidData?.packageName || '' },
              })
            }
            value={formData.gameDetails.androidData?.url || ''}
            disabled={!formData.gameDetails.operatingSystems.includes('ANDROID')}
            errorMessage={formData.gameDetails.operatingSystems.includes('ANDROID') ? errors?.androidUrl : undefined}
          />
          <Input
            name="androidPackageName"
            placeholder="Package Name"
            handleInput={(e) =>
              updateFormData('gameDetails', {
                androidData: { packageName: e.target.value, url: formData.gameDetails.androidData?.url || '' },
              })
            }
            value={formData.gameDetails.androidData?.packageName || ''}
            disabled={!formData.gameDetails.operatingSystems.includes('ANDROID')}
            errorMessage={
              formData.gameDetails.operatingSystems.includes('ANDROID') ? errors?.androidPackageName : undefined
            }
          />
        </div>

        <div className="game-details-step__checkboxes__os">
          <Checkbox
            value={formData.gameDetails.operatingSystems.includes('WEB')}
            setValue={(value) => {
              const updatedOS = value
                ? [...formData.gameDetails.operatingSystems, 'WEB']
                : formData.gameDetails.operatingSystems.filter((os) => os !== 'WEB');
              updateFormData('gameDetails', {
                operatingSystems: updatedOS,
                webData: value ? formData.gameDetails.webData : { url: '' },
              });
            }}
          >
            Web
          </Checkbox>
          <Input
            name="webUrl"
            placeholder="Web URL"
            handleInput={(e) =>
              updateFormData('gameDetails', { webData: { ...formData.gameDetails.webData, url: e.target.value } })
            }
            value={formData.gameDetails.webData?.url || ''}
            disabled={!formData.gameDetails.operatingSystems.includes('WEB')}
            errorMessage={formData.gameDetails.operatingSystems.includes('WEB') ? errors?.webUrl : undefined}
          />
        </div>
      </div>

      <div className="game-details-step__iab-select">
        <IabSelect
          label="IAB Categories"
          placeholder="Select categories..."
          categories={formData.gameDetails.iabCategories?.map((c) => c.name) || []}
          onCategoriesChange={handleCategoriesChange}
        />
        <small>*You can also select these later in game settings</small>
      </div>

      <div className="game-details-step__reach_info">
        <h3>Reach data</h3>
        <p>Define the game type, platforms, and categories to optimize campaign matching.</p>
      </div>
      <Input
        label="MAU"
        name="monthlyActiveUsers"
        placeholder="Enter game's MAU (max 100M)"
        type="number"
        handleInput={(e) => updateFormData('gameDetails', { monthlyActiveUsers: returnMAUOrMax(e.target.value) })}
        value={formData.gameDetails.monthlyActiveUsers ?? ''}
        errorMessage={errors?.monthlyActiveUsers}
      />
      <div className="game-details-step__mau-distribution">
        <h3>MAU distribution in top countries</h3>
        {[...Array(5)].map((_, index) => (
          <div key={index} className="game-details-step__mau-distribution__item">
            <div className="game-details-step__mau-distribution__item__index">
              <span>{index + 1}.</span>
            </div>
            <DropdownSearch
              placeholder="Select Country"
              options={countryOptions}
              handleSelect={(selectedCountry) => {
                const updatedDistribution = [...formData.gameDetails.topCountryDistribution];
                if (!updatedDistribution[index]) {
                  updatedDistribution[index] = { countryCode: '', monthlyActiveUsers: 0 };
                }
                updatedDistribution[index] = {
                  ...updatedDistribution[index],
                  countryCode: selectedCountry,
                };
                updateFormData('gameDetails', { topCountryDistribution: updatedDistribution });
              }}
              handleSearchChange={handleCountrySearch}
              selectedValue={formData.gameDetails.topCountryDistribution[index]?.countryCode || ''}
              emptyInputResets
              errorMessage={errors?.[`topCountryDistribution[${index}].countryCode`]}
            />
            <Input
              name={`mauCountry${index + 1}`}
              placeholder="Enter MAU (max 100M)"
              type="number"
              handleInput={(e) => {
                const updatedDistribution = [...formData.gameDetails.topCountryDistribution];
                if (!updatedDistribution[index]) {
                  updatedDistribution[index] = { countryCode: '', monthlyActiveUsers: 0 };
                }
                updatedDistribution[index] = {
                  ...updatedDistribution[index],
                  monthlyActiveUsers: returnMAUOrMax(e.target.value),
                };
                updateFormData('gameDetails', { topCountryDistribution: updatedDistribution });
              }}
              value={formData.gameDetails.topCountryDistribution[index]?.monthlyActiveUsers || ''}
              errorMessage={errors?.[`topCountryDistribution[${index}].monthlyActiveUsers`]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameDetailsStep;
