import Icon from '../../../Icon/Icon';
import './top-up-status.scss';

const TopUpStatus = ({
  balance,
  amount,
  isSuccess,
  transactionId,
  closeModal,
}: {
  balance: string;
  amount: string;
  isSuccess: boolean;
  transactionId?: string;
  closeModal: () => void;
}) => {
  const title = isSuccess ? 'Successful!' : 'Failed';
  return (
    <div className={`top-up-status-modal ${!isSuccess && '--error'}`}>
      <div className="top-up-status-modal__heading">
        <div>
          <Icon name={isSuccess ? 'checkmark' : 'close'} size={24} />
        </div>
        <button type="button" onClick={closeModal}>
          <Icon name="close" size={24} />
        </button>
      </div>
      <div className="top-up-status-modal__main">
        <h2>Credit Top-Up {title}</h2>
        {isSuccess ? (
          <div>
            <p>Your payment has been processed successfully.</p>
            <p>
              Your account has been credited with <span>${amount}</span>
            </p>
            <p className="top-up-status-modal__main_info">
              Current Balance: <b>${balance}</b>
            </p>
            {transactionId && <p className="top-up-status-modal__main_hash">Transaction ID: {transactionId}</p>}{' '}
          </div>
        ) : (
          <div>
            <p>
              Your payment has been unsuccessfully processed. <br /> Please try again!
            </p>
          </div>
        )}
      </div>
      <button type="button" className="btn-primary-solid" onClick={closeModal}>
        {isSuccess ? 'OK' : 'Go to credit page'}
      </button>
    </div>
  );
};

export default TopUpStatus;
