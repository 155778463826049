import { Link } from 'react-router-dom';
import { GET_ORG_QUERY } from '../../../global/gql/queries';
import { TokenType } from '../../../global/consts';
import { OrganizationData } from '../../../global/interfaces';
import { SETTINGS, ORG } from '../../../global/routes';
import { useReneQuery } from '../../../hooks';
import imagePlaceholder from '../../../global/images/avatar.webp';

import './organization-menu.scss';

const OrganizationMenu = () => {
  const { data } = useReneQuery<{ Organization: OrganizationData }>(GET_ORG_QUERY, {}, TokenType.ORG);
  if (!data) {
    return null;
  }

  return (
    <div className="organization">
      <Link to={`/${ORG}/${SETTINGS}/organization`}>
        <img src={data?.Organization?.image?.url || imagePlaceholder} alt="studio" />
        <div>
          <p>Member of</p>
          <p>{data?.Organization?.name}</p>
        </div>
      </Link>
    </div>
  );
};

export default OrganizationMenu;
