import { useLocation } from 'react-router-dom';
import {
  useQuery,
  QueryHookOptions,
  ApolloQueryResult,
  ApolloError,
  ApolloClient,
  OperationVariables,
} from '@apollo/client';
import { TokenType } from '../global/consts/index';
import { ORG } from '../global/routes/index';
import { handleError } from '../utils';
import { useErrorContext } from '../context/error-context/use-error-context';

interface QueryResult<TData, TVariables> {
  data: TData | undefined;
  error: any;
  loading: boolean;
  client: ApolloClient<any>;
  refetch: (variables?: TVariables) => Promise<ApolloQueryResult<TData>>;
}

export const useReneQuery = <TData = any, TVariables extends OperationVariables = OperationVariables>(
  query: any,
  options?: QueryHookOptions<TData, TVariables>,
  tokenType?: TokenType,
): QueryResult<TData, TVariables> => {
  const location = useLocation();
  const { setError } = useErrorContext();

  const setTokenType = (tokenType?: TokenType) => {
    if (tokenType) {
      return tokenType;
    }
    const isOrg = !!location.pathname.match(`/${ORG}`);
    return isOrg ? TokenType.ORG : TokenType.GAMER;
  };

  const { data, error, loading, refetch, client } = useQuery<TData, TVariables>(query, {
    errorPolicy: 'all',
    context: {
      headers: {
        authorization: setTokenType(tokenType),
      },
    },
    onError: (err: ApolloError) => {
      handleError(err, setError);
    },
    ...options,
  });

  return { data, error, loading, client, refetch };
};
