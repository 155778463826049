import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigateFunction, useNavigate } from 'react-router';
import { ApolloError, useMutation } from '@apollo/client';
import { Dispatcher, Event } from '../../../global/interfaces';
import { useErrorContext } from '../../../context/error-context/use-error-context';
import { HOME, FORGOT_PASSWORD, REGISTER, LOGIN } from '../../../global/routes';
import { handleError, handleKeyPressSubmit, isUserLoggedIn } from '../../../utils';
import { useValidation } from '../../../hooks';
import { TOKEN } from '../../../global/consts';
import { LOGIN_USER_MUTATION } from '../../../global/gql/mutations';
import { passwordValidation } from './validations';
import Input from '../../../components/input/input';
import Spinner from '../../../components/spinner/spinner';

import reneLogo from '../../../global/images/rene-logo-new-big.png';

import './login-page.scss';

interface PasswordFormState {
  credential: string;
  password: string;
}

const PasswordForm: React.FC<{
  navigate: NavigateFunction;
  setError: Dispatcher<{ message: string; showError: boolean }>;
}> = ({ navigate, setError }) => {
  const {
    state: { credential },
  } = useLocation();
  const { errors, isFormInvalid } = useValidation(passwordValidation);
  const [credentials, setCredentials] = useState<PasswordFormState>({ credential, password: '' });

  const [loginUser, { loading, error: loginError }] = useMutation(LOGIN_USER_MUTATION, {
    onCompleted(data: { LoginUser: { jwt: string } }) {
      if (data?.LoginUser?.jwt) {
        localStorage.setItem(TOKEN, data?.LoginUser.jwt);
        navigate(HOME, { replace: true });
      }
    },
    onError: (err: ApolloError) => {
      if (err.message.match('User login failed')) return;
      handleError(err, setError);
    },
  });

  const inputHandler = (e: Event['Input']) => {
    setCredentials((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleLogin = () => {
    if (isFormInvalid(credentials)) return;

    let variables = {};
    if (credentials.credential.match('@')) {
      variables = {
        email: credentials.credential,
        password: credentials.password,
      };
    } else {
      variables = {
        username: credentials.credential,
        password: credentials.password,
      };
    }
    loginUser({
      variables,
    });
  };

  const handleGoBack = () => {
    navigate(`/${LOGIN}`, { state: { credential } });
  };

  return (
    <div className="login-page">
      <div className="login-page__logo">
        <img src={reneLogo} alt="reneverse" />
      </div>
      <div className="login-page__form">
        <h1>Sign in</h1>
        <div className="login-page__form_email">
          <span>{credential}</span>
          <p>
            Change the email address?
            <button type="button" onClick={handleGoBack}>
              Go back
            </button>
          </p>
        </div>
        <Input
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          placeholder="Enter your password"
          handleInput={inputHandler}
          onKeyDown={(e) => handleKeyPressSubmit(e, handleLogin)}
          value={credentials.password}
          errorMessage={errors?.password}
        />
        {loginError && <p className="login-page__form_error">{loginError.message}</p>}
        <Link to={`/${FORGOT_PASSWORD}`}>Forgot password?</Link>
        <button type="button" className="btn-primary-solid" onClick={handleLogin}>
          {loading ? <Spinner size="sm" /> : 'Sign in'}
        </button>
        <Link to={`/${REGISTER}`}>New user?</Link>
      </div>
    </div>
  );
};

const PasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const { setError } = useErrorContext();
  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    if (isLoggedIn) navigate(HOME);
  }, [isLoggedIn, navigate]);

  return (
    <div className="login-page-container">
      <PasswordForm setError={setError} navigate={navigate} />
    </div>
  );
};

export default PasswordPage;
