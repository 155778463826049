import { useCallback, useState } from 'react';
import { validations } from './validations';
import {
  AdData,
  AdsData,
  BrandedObjectsData,
  CampaignData,
  CampaignsData,
  Content,
  Dispatcher,
  GameData,
  GamesData,
  OrganizationData,
  Refetch,
} from '../../../../../global/interfaces';
import { CreateOfferModal } from '../../../create-offer-modal/create-offer-modal';
import { GAME_SEARCH_QUERY, GET_GAME_GENRES } from '../../../../../global/gql/queries';
import { SearchableMultiSelect } from '../../../../searchable-multi-select/searchable-multi-select';
import { UPSERT_AD_CAMPAIGN_MUTATION, UPSERT_BULK_AD_MUTATION } from '../../../../../global/gql/mutations';
import { useLazyReneQuery, useReneMutation, useValidation } from '../../../../../hooks';
import Modal from '../../../modal';
import Spinner from '../../../../spinner/spinner';
import IabSelect from '../../../../iab-select/iab-select';
import RadioButtons from '../../../../radio/radio-buttons';
import PreviewOfferModal from '../../../preview-offer-modal/preview-offer-modal';
import { areArraysEqual } from '../../../../../utils';
import { CampaignFormData } from '../../types';
import { MultiSelect } from '../../../../multi-select/multi-select';
import {
  AdCampaignCheckpoint,
  AdType,
  CampaignStatus,
  CampaignTracker,
  CampaignTrackerLabel,
} from '../../../../../global/consts';
import Checkbox from '../../../../checkbox/checkbox';

import './ads-placement.scss';

interface AdsPlacementProps {
  data: {
    selectedAds: CampaignFormData['selectedAds'];
    selectedBrandedObjects: CampaignFormData['selectedBrandedObjects'];
    contentTags: CampaignFormData['adsPlacement']['contentTags'];
    genres: CampaignFormData['adsPlacement']['genres'];
    titles: CampaignFormData['adsPlacement']['titles'];
  };
  status: CampaignStatus;
  adCampaignId: string;
  setActiveStep: (step: number) => void;
  setFormData: Dispatcher<CampaignFormData>;
  handleDeleteAdCampaign: () => void;
  deleteAdCampaignLoading: boolean;
  refetch: Refetch<
    | {
        Organization: OrganizationData;
      }
    | {
        AdCampaigns: CampaignsData;
      }
    | undefined
  >;
}

const AdComponent = ({
  ad,
  handleCategoriesChange,
  handleTrackersChange,
  setPreviewOfferAd,
  setEditOfferAd,
}: {
  ad: AdsData['items'][0] | BrandedObjectsData['items'][0];
  handleCategoriesChange: (adId: string, categories: Content[]) => void;
  handleTrackersChange: (adId: string, trackers: CampaignTracker[]) => void;
  setPreviewOfferAd: (ad: AdData) => void;
  setEditOfferAd: (ad: AdData) => void;
}) => {
  const isAd = 'adType' in ad;
  const [isOffer, setIsOffer] = useState(isAd && !!ad.adOffer);

  let type: 'image' | 'video' | 'branded-object';
  let url: string | undefined;

  if (isAd) {
    type = ad.adType === AdType.BANNER ? 'image' : 'video';
    url = ad.adType === AdType.BANNER ? ad.banner?.url : ad.video?.url;
  } else {
    type = 'branded-object';
    url = ad.image?.url;
  }

  const id = isAd ? ad.adId : ad.brandedObjectId;

  return (
    <div className="ads-placement__specific_content">
      <div className="ads-placement__specific_content_left">
        {(type === 'image' || type === 'branded-object') && <img src={url} alt="ad" />}
        {type === 'video' && (
          <video autoPlay muted>
            <source src={url} />
          </video>
        )}
        <p>{ad.name}</p>
        <p>{isAd ? 'Image/Video' : 'Branded object'}</p>
      </div>
      <div className="ads-placement__specific_content_right">
        <div className="ads-placement__specific_content_right_iab">
          <IabSelect
            label="IAB categories"
            placeholder="Select IAB categories"
            categories={ad.contentTags?.map((c) => c.name) || []}
            onCategoriesChange={(_categories, categoriesWithData) =>
              handleCategoriesChange(id, categoriesWithData ?? [])
            }
          />

          {/* <div className="ads-placement__specific_content_right_iab_filters">
      <button
        onClick={() => setSelectedFilter('included')}
        className={selectedFilter === 'included' ? 'active' : ''}
      >
        Included
      </button>
      <button
        onClick={() => setSelectedFilter('excluded')}
        className={selectedFilter === 'excluded' ? 'active' : ''}
      >
        Excluded
      </button>
    </div> */}
        </div>
        <div className="ads-placement__specific_content_right_trackers">
          {isAd && (
            <MultiSelect
              multiSelect
              label="Trackers"
              options={Object.values(CampaignTracker)?.map((tracker) => ({
                value: tracker,
                label: CampaignTrackerLabel[tracker],
              }))}
              selectedValues={ad.trackers?.map((t) => t.eventType)}
              onChange={(selected) => handleTrackersChange(id, selected as CampaignTracker[])}
              placeholder="Select tracker"
            />
          )}
        </div>
        {isAd && (
          <div className="ads-placement__specific_content_right_offer">
            <Checkbox value={isOffer} setValue={() => setIsOffer(!isOffer)}>
              On-interaction offer
            </Checkbox>
            {isOffer && type !== 'branded-object' && (
              <div className="ads-placement__specific_content_right_offer_buttons">
                <button type="button" className="btn-neutral-ghost" onClick={() => setPreviewOfferAd(ad)}>
                  Preview offer
                </button>
                <button type="button" className="btn-neutral-solid" onClick={() => setEditOfferAd(ad)}>
                  Edit
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const AdsPlacement: React.FC<AdsPlacementProps> = ({
  data,
  status,
  adCampaignId,
  handleDeleteAdCampaign,
  deleteAdCampaignLoading,
  setActiveStep,
  setFormData,
  refetch,
}) => {
  const { errors, isFormInvalid } = useValidation(validations);
  const [selectedAdsData, setSelectedAdsData] = useState({
    selectedAds: data.selectedAds || [],
    selectedBrandedObjects: data.selectedBrandedObjects || [],
  });
  const [selectedCategories, setSelectedCategories] = useState<Content[]>(data.contentTags || []);
  const [selectedGenres, setSelectedGenres] = useState<string[]>(data.genres || []);
  const [selectedTitles, setSelectedTitles] = useState<GameData[]>(data.titles || []);
  const [previewOfferAd, setPreviewOfferAd] = useState<AdData>();
  const [editOfferAd, setEditOfferAd] = useState<AdData>();
  const [targetedGames, setTargetedGames] = useState<'all' | 'specific'>(data.titles.length > 0 ? 'specific' : 'all');
  // const [selectedFilter, setSelectedFilter] = useState<'included' | 'excluded'>('included');
  const [searchGenres, { data: searchGenresData, loading: searchGenresLoading }] = useLazyReneQuery<{
    GameGenreSearch: string[];
  }>(GET_GAME_GENRES);

  const [searchGames, { data: searchGamesData, loading: searchGamesLoading }] = useLazyReneQuery<{
    GameSearch: GamesData;
  }>(GAME_SEARCH_QUERY);

  const [upsertCampaign, { loading: upsertCampaignLoading }] = useReneMutation(UPSERT_AD_CAMPAIGN_MUTATION, {
    onCompleted(data: { UpsertAdCampaign: CampaignData }) {
      setFormData((prev) => ({
        ...prev,
        status: data.UpsertAdCampaign.status as CampaignStatus,
        adsPlacement: {
          contentTags: data.UpsertAdCampaign.contentTags,
          genres: data.UpsertAdCampaign.adCampaignGenres.map((c) => c.name),
          titles: data.UpsertAdCampaign.targetedGames.items,
        },
      }));
      setActiveStep(4);
      refetch();
    },
  });

  const [upsertAd] = useReneMutation(UPSERT_BULK_AD_MUTATION, {
    onCompleted(data: { BulkInsertAd: { ads: AdsData; brandedObjects: BrandedObjectsData } }) {
      setFormData((prev) => ({
        ...prev,
        selectedAds: data.BulkInsertAd?.ads?.items || [],
        selectedBrandedObjects: data.BulkInsertAd?.brandedObjects?.items || [],
      }));
    },
  });

  const handleSearch = (searchTerm: string, type: 'genres' | 'titles') => {
    if (searchTerm) {
      if (type === 'genres') {
        searchGenres({ variables: { gameGenreSearchTerm: searchTerm } });
      }
      if (type === 'titles') {
        searchGames({ variables: { gameSearchTerm: searchTerm, global: true } });
      }
    }
  };

  const handleAdsCategoriesChange = useCallback(
    (adId: string, categories: Content[]) => {
      setSelectedAdsData((prev) => ({
        ...prev,
        selectedAds: prev.selectedAds.map((ad) => (ad.adId === adId ? { ...ad, contentTags: categories } : ad)),
      }));
    },
    [setSelectedAdsData],
  );
  const handleTrackersChange = useCallback(
    (adId: string, trackers: CampaignTracker[]) => {
      setSelectedAdsData((prev) => ({
        ...prev,
        selectedAds: prev.selectedAds.map((ad) =>
          ad.adId === adId
            ? {
                ...ad,
                trackers: trackers.map((tracker) => ({ eventType: tracker })),
              }
            : ad,
        ),
      }));
    },
    [setSelectedAdsData],
  );

  const handleBrandedObjectCategoriesChange = useCallback(
    (brandedObjectId: string, categories: Content[]) => {
      setSelectedAdsData((prev) => ({
        ...prev,
        selectedBrandedObjects: prev.selectedBrandedObjects.map((obj) =>
          obj.brandedObjectId === brandedObjectId ? { ...obj, contentTags: categories } : obj,
        ),
      }));
    },
    [setSelectedAdsData],
  );

  const handleCategoriesChange = useCallback(
    (_: string[], categoriesWithData?: Content[]) => setSelectedCategories(categoriesWithData ?? []),
    [setSelectedCategories],
  );

  const upsertAds = () => {
    const adsEqual = areArraysEqual(
      selectedAdsData.selectedAds.map((ad) => ({
        adId: ad.adId,
        contentTags: ad.contentTags,
        trackers: ad.trackers,
      })),
      data.selectedAds.map((ad) => ({
        adId: ad.adId,
        contentTags: ad.contentTags,
        trackers: ad.trackers,
      })),
    );

    const brandedObjectsEqual = areArraysEqual(
      selectedAdsData.selectedBrandedObjects.map((obj) => obj.contentTags),
      data.selectedBrandedObjects.map((obj) => obj.contentTags),
    );

    if (!adsEqual || !brandedObjectsEqual) {
      const variables = {
        adCampaignId,
        ads: selectedAdsData.selectedAds?.map((ad) => ({
          adId: ad.adId,
          contentTags: ad.contentTags?.map((c) => ({
            name: c.name,
            taxonomyContentId: c.taxonomyContentId,
          })),
          trackers: ad.trackers?.map((t) => ({
            eventType: t.eventType,
          })),
        })),
        brandedObjects: selectedAdsData.selectedBrandedObjects?.map((obj) => ({
          brandedObjectId: obj.brandedObjectId,
          contentTags: obj.contentTags.map((c) => ({
            name: c.name,
            taxonomyContentId: c.taxonomyContentId,
          })),
        })),
      };
      upsertAd({ variables });
    }
  };

  const saveCampaign = () => {
    if (isFormInvalid({ contentTags: selectedCategories, genres: selectedGenres, titles: selectedTitles })) return;

    const variables = {
      adCampaignId,
      contentTags: selectedCategories.map((c) => ({
        name: c.name,
        taxonomyContentId: c.taxonomyContentId,
      })),
      genres: selectedGenres,
      targetedGameIds: targetedGames === 'specific' ? selectedTitles.map((t) => t.gameId) : undefined,
      checkpoint: AdCampaignCheckpoint.BUDGETING,
    };
    upsertAds();
    upsertCampaign({ variables });
  };

  return (
    <div className="ads-placement">
      <div className="ads-placement__content">
        <h2>Ads placement</h2>
        <h4>Select the games and audience by choosing game titles and relevant IAB categories.</h4>
        <div className="ads-placement__settings">
          <h3>Campaign settings</h3>
          <div className="ads-placement__settings_iab">
            <IabSelect
              label="IAB categories"
              placeholder="Select IAB categories"
              categories={selectedCategories.map((c) => c.name) || []}
              onCategoriesChange={handleCategoriesChange}
              errorMsg={errors?.contentTags}
            />
            {/* <div className="ads-placement__settings_iab_filters">
            <label>Included</label>
            <Toggle
              id="included"
              name="included"
              checked={selectedFilter === 'excluded'}
              setValue={() => setSelectedFilter('excluded')}
            />
            <label>Excluded</label>
          </div> */}
          </div>
          <div className="ads-placement__settings_genres">
            <RadioButtons
              label="Games"
              options={[
                { label: 'All games', value: 'all' },
                { label: 'Specific genres and titles', value: 'specific' },
              ]}
              selectedValue={targetedGames}
              setSelectedValue={setTargetedGames}
            />
            <SearchableMultiSelect
              apiSearch
              options={searchGenresData?.GameGenreSearch || []}
              onSearch={(searchTerm) => handleSearch(searchTerm, 'genres')}
              selectedValues={selectedGenres}
              onChange={(value) => setSelectedGenres(value as string[])}
              multiSelect
              loading={searchGenresLoading}
              placeholder="Select genres..."
              label="Genres"
              errorMessage={errors?.genres}
            />
            {targetedGames === 'specific' && (
              <SearchableMultiSelect
                apiSearch
                options={searchGamesData?.GameSearch.items || []}
                labelKey="name"
                onSearch={(searchTerm) => handleSearch(searchTerm, 'titles')}
                selectedValues={selectedTitles}
                onChange={(selected) => {
                  setSelectedTitles(selected as GameData[]);
                }}
                multiSelect
                loading={searchGamesLoading}
                placeholder="Select titles..."
                label="Titles"
                errorMessage={errors?.titles}
              />
            )}
          </div>
        </div>
        <div className="ads-placement__specific">
          <h3>Ad-specific settings</h3>
          {selectedAdsData.selectedAds.map((ad) => (
            <AdComponent
              key={ad.adId}
              ad={ad}
              handleCategoriesChange={handleAdsCategoriesChange}
              handleTrackersChange={handleTrackersChange}
              setPreviewOfferAd={setPreviewOfferAd}
              setEditOfferAd={setEditOfferAd}
            />
          ))}
          {selectedAdsData.selectedBrandedObjects.map((brandedObject) => (
            <AdComponent
              key={brandedObject.brandedObjectId}
              ad={brandedObject}
              handleCategoriesChange={handleBrandedObjectCategoriesChange}
              handleTrackersChange={handleTrackersChange}
              setPreviewOfferAd={setPreviewOfferAd}
              setEditOfferAd={setEditOfferAd}
            />
          ))}
        </div>
      </div>
      <div className="create-campaign-modal__main_footer">
        <div>
          {status === CampaignStatus.DRAFT && (
            <button type="button" className="btn-negative-ghost" onClick={handleDeleteAdCampaign}>
              {deleteAdCampaignLoading ? <Spinner size="sm" /> : 'Discard'}
            </button>
          )}
        </div>
        <div>
          <button type="button" className="btn-neutral-ghost" onClick={() => setActiveStep(2)}>
            Back
          </button>
          <button type="button" className="btn-primary-solid" onClick={saveCampaign}>
            {upsertCampaignLoading ? <Spinner size="sm" /> : 'Next'}
          </button>
        </div>
      </div>
      {previewOfferAd && (
        <Modal isOpen>
          <PreviewOfferModal ad={previewOfferAd} setIsModalOpen={() => setPreviewOfferAd(undefined)} />
        </Modal>
      )}
      {editOfferAd && (
        <Modal isOpen>
          <CreateOfferModal
            ad={editOfferAd}
            adCampaignId={adCampaignId}
            setIsModalOpen={() => setEditOfferAd(undefined)}
          />
        </Modal>
      )}
    </div>
  );
};

export default AdsPlacement;
