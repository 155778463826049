import React from 'react';

type ShowNotification = React.Dispatch<React.SetStateAction<boolean>>;

export const NotificationContext = React.createContext<
  { notification: boolean; showNotification: ShowNotification } | undefined
>(undefined);

export function useNotificationContext() {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }
  return context;
}
