import { GET_GAME_GENRES } from '../../../../../global/gql/queries';
import { useLazyReneQuery } from '../../../../../hooks';
import DropdownSearch from '../../../../dropdown-search/dropdown-search';
import FileUpload from '../../../../file-upload/file-upload';
import Input from '../../../../input/input';
import Textarea from '../../../../textarea/textarea';
import { GameFormData } from '../../types';

import './basic-information-step.scss';

type BasicInformationStepProps = {
  updateFormData: <K extends keyof GameFormData>(step: K, data: Partial<GameFormData[K]>) => void;
  formData: GameFormData;
  errors?: { [key: string]: any };
};

const BasicInformationStep = ({ updateFormData, formData, errors }: BasicInformationStepProps) => {
  const [search, { data, loading: genresLoading }] = useLazyReneQuery<{ GameGenreSearch: string[] }>(GET_GAME_GENRES);

  const handleSearchChange = (value: string) => search({ variables: { gameGenreSearchTerm: value } });

  const genres = data?.GameGenreSearch || [];

  const handleGenreSelect = (value: string) => {
    updateFormData('basicInfo', { category: value });
  };

  return (
    <div className="basic-information-step">
      <Input
        label="Title"
        name="title"
        placeholder="Enter game title"
        handleInput={(e) => updateFormData('basicInfo', { title: e.target.value })}
        value={formData.basicInfo.title}
        errorMessage={errors?.title}
      />
      <Textarea
        label="Description"
        name="description"
        value={formData.basicInfo.description}
        handleInput={(e) => updateFormData('basicInfo', { description: e.target.value })}
        placeholder="Enter game description"
        showCounter
        maxLength={100}
        errorMessage={errors?.description}
      />

      <div className="dropdown-container" onClick={(e) => e.stopPropagation()}>
        <DropdownSearch
          label="Genre"
          options={genres.map((genre) => ({ label: genre, value: genre })) || []}
          selectedValue={formData.basicInfo.category}
          handleSelect={handleGenreSelect}
          handleSearchChange={handleSearchChange}
          placeholder="Search for game category..."
          loading={genresLoading}
          name="category"
          errorMessage={errors?.category}
        />
      </div>

      <div className="image-upload-grid">
        <FileUpload
          label="Icon Image"
          setFiles={(files: File[]) => updateFormData('basicInfo', { iconImage: files[0] })}
          loadedImgSrc={formData.basicInfo.iconImageURLFromBackend}
          description="96 x 96 px"
          errorMessage={errors?.iconImage}
        />
        <FileUpload
          label="Cover Image"
          setFiles={(files: File[]) => updateFormData('basicInfo', { coverImage: files[0] })}
          loadedImgSrc={formData.basicInfo.coverImageURLFromBackend}
          description="512 x 256 px"
          errorMessage={errors?.coverImage}
        />
      </div>
    </div>
  );
};

export default BasicInformationStep;
