/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import * as topojson from 'topojson-client';
import * as d3 from 'd3';
import { Feature, FeatureCollection } from 'geojson';
import { Country } from '../../global/interfaces';
import './world-map.scss';

interface GeoFeature extends Feature {
  properties: {
    iso_a2: string;
    [key: string]: any;
  };
}

interface WorldMapProps {
  countries: Country[];
  selectedCountries?: string[];
  onCountrySelect?: (countryCode: string, isSelected: boolean) => void;
}

const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;

  return (...args: Parameters<F>): ReturnType<F> => {
    if (timeout !== null) {
      clearTimeout(timeout);
    }

    return new Promise((resolve) => {
      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    }) as ReturnType<F>;
  };
};

const WorldMap: React.FC<WorldMapProps> = ({ countries, selectedCountries = [], onCountrySelect }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [geoData, setGeoData] = useState<FeatureCollection | null>(null);
  const [countryCodeMap, setCountryCodeMap] = useState<Map<string, string>>(new Map());

  // Add a ref for the zoom group
  const zoomGroupRef = useRef<SVGGElement | null>(null);

  // Store the current zoom transform to preserve it between renders
  const currentTransformRef = useRef<d3.ZoomTransform>(d3.zoomIdentity);

  // Create a debounced version of the selection handler
  const debouncedSelect = useRef(
    debounce((code: string, isSelected: boolean) => {
      if (onCountrySelect) {
        onCountrySelect(code, isSelected);
      }
    }, 100),
  ).current;

  // Create a mapping between country codes and ISO codes
  useEffect(() => {
    if (countries && countries.length > 0) {
      const codeMap = new Map<string, string>();

      countries.forEach((country) => {
        // Store both uppercase and original versions for better matching
        codeMap.set(country.code.toUpperCase(), country.code);

        // Also map country names to codes for potential matching by name
        if (country.name) {
          codeMap.set(country.name.toUpperCase(), country.code);
        }
      });

      setCountryCodeMap(codeMap);
    }
  }, [countries]);

  // Check if a country is in our valid list
  const isValidCountry = (countryCode: string): boolean => {
    // Convert to uppercase for case-insensitive comparison
    const upperCode = countryCode.toUpperCase();

    // Check if this code exists in our countries list
    return countries.some((country) => country.code.toUpperCase() === upperCode);
  };

  // Fetch and parse TopoJSON data
  const fetchMapData = async () => {
    try {
      const response = await fetch('/world-110m.json'); // Path to your TopoJSON file
      const topoData = await response.json();

      // First cast to unknown, then to FeatureCollection to avoid type errors
      const result = topojson.feature(topoData, topoData.objects.countries) as unknown as FeatureCollection;

      // Add country codes if missing
      if (result.features && result.features.length > 0) {
        // Check if the first feature has a country code
        const firstFeature = result.features[0] as GeoFeature;
        const hasCountryCode = getCountryCodeFromFeature(firstFeature) !== null;

        if (!hasCountryCode) {
          // Add numeric IDs as fallback country codes
          result.features.forEach((feature, index) => {
            if (feature.properties) {
              feature.properties.id = `ID_${index}`;
            }
          });
        }
      }

      return result;
    } catch (err) {
      return null;
    }
  };

  // Get the country code from various possible property formats in TopoJSON data
  const getCountryCodeFromFeature = (feature: GeoFeature): string | null => {
    if (!feature.properties) return null;

    // Check various common property names for country codes in different TopoJSON datasets
    const props = feature.properties;

    // Try to get a code from various possible property names
    const rawCode =
      props.iso_a2 ||
      props.ISO_A2 ||
      props.ISO ||
      props.iso ||
      props.id ||
      props.ID ||
      props.code ||
      props.CODE ||
      props.ISO_A3 ||
      props.iso_a3 ||
      props.ADMIN ||
      props.name ||
      null;

    if (!rawCode) return null;

    // For numeric codes, convert to string
    return String(rawCode);
  };

  // Convert any country code to standard ISO2 format (like US, IT)
  const standardizeCountryCode = (code: string): string => {
    // First check our mapping
    const upperCode = code.toUpperCase();
    const mappedCode = countryCodeMap.get(upperCode);

    if (mappedCode) return mappedCode;

    // If not in our mapping but looks like an ISO2 code (2 letters), return as is
    if (/^[A-Z]{2}$/i.test(code)) {
      return code.toUpperCase();
    }

    // Otherwise return the original code
    return code;
  };

  // Highlight selected countries based on country codes
  const highlightCountries = (
    container: d3.Selection<SVGGElement | SVGSVGElement, unknown, null, undefined>,
    geoData: FeatureCollection,
    countryCodes: string[],
  ) => {
    container.selectAll<SVGPathElement, GeoFeature>('path').attr('fill', (d) => {
      // Get country code using our helper function
      const rawCountryCode = getCountryCodeFromFeature(d);
      if (!rawCountryCode) return '#ccc';

      // Standardize the code
      const standardCode = standardizeCountryCode(rawCountryCode);

      // Check if this is a valid country from our backend
      const isValid = isValidCountry(standardCode);

      // If not valid, return a different color to indicate it's not selectable
      if (!isValid) return '#eee';

      // Check if this country is in our selected list (case-insensitive)
      return countryCodes.some((code) => code.toUpperCase() === standardCode.toUpperCase()) ? '#6b38f5' : '#ccc';
    });
  };

  // Load map data when component mounts
  useEffect(() => {
    const loadMapData = async () => {
      const data = await fetchMapData();
      setGeoData(data);
    };

    loadMapData();
  }, []);

  // Render the map and highlight countries when geoData and countries are available
  useEffect(() => {
    if (!svgRef.current || !geoData || !countries) return;

    const svg = d3.select<SVGSVGElement, unknown>(svgRef.current);
    const width = svgRef.current.clientWidth || 800;
    const height = svgRef.current.clientHeight || 600;

    // Clear previous map
    svg.selectAll('*').remove();

    // Create a group for the map that will be transformed during zoom/pan
    const zoomGroup = svg.append('g');

    // Store the zoom group reference
    zoomGroupRef.current = zoomGroup.node();

    const projection = d3
      .geoMercator()
      .scale(150)
      .translate([width / 2, height / 2]);

    const pathGenerator = d3.geoPath().projection(projection);

    // Define zoom behavior
    const zoom = d3
      .zoom<SVGSVGElement, unknown>()
      .scaleExtent([1, 8]) // Set min/max zoom scale
      .on('zoom', (event) => {
        // Store the current transform for later use
        currentTransformRef.current = event.transform;

        // Apply the zoom transform to the group
        zoomGroup.attr('transform', event.transform);
      });

    // Apply zoom behavior to the SVG
    svg.call(zoom);

    // Restore previous zoom transform if it exists
    if (currentTransformRef.current !== d3.zoomIdentity) {
      svg.call(zoom.transform, currentTransformRef.current);
    }

    // Add zoom controls
    const zoomControls = svg.append('g').attr('transform', `translate(${width - 80}, 10)`);

    // Zoom in button
    zoomControls
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 30)
      .attr('height', 30)
      .attr('fill', 'white')
      .attr('stroke', '#ccc')
      .attr('rx', 4)
      .attr('cursor', 'pointer')
      .on('click', () => {
        svg.transition().duration(300).call(zoom.scaleBy, 1.3);
      });

    zoomControls
      .append('text')
      .attr('x', 15)
      .attr('y', 20)
      .attr('text-anchor', 'middle')
      .attr('fill', '#333')
      .attr('pointer-events', 'none')
      .text('+');

    // Zoom out button
    zoomControls
      .append('rect')
      .attr('x', 40)
      .attr('y', 0)
      .attr('width', 30)
      .attr('height', 30)
      .attr('fill', 'white')
      .attr('stroke', '#ccc')
      .attr('rx', 4)
      .attr('cursor', 'pointer')
      .on('click', () => {
        svg.transition().duration(300).call(zoom.scaleBy, 0.7);
      });

    zoomControls
      .append('text')
      .attr('x', 55)
      .attr('y', 20)
      .attr('text-anchor', 'middle')
      .attr('fill', '#333')
      .attr('pointer-events', 'none')
      .text('-');

    // Reset zoom button
    zoomControls
      .append('rect')
      .attr('x', 20)
      .attr('y', 40)
      .attr('width', 30)
      .attr('height', 30)
      .attr('fill', 'white')
      .attr('stroke', '#ccc')
      .attr('rx', 4)
      .attr('cursor', 'pointer')
      .on('click', () => {
        svg.transition().duration(300).call(zoom.transform, d3.zoomIdentity);
      });

    zoomControls
      .append('text')
      .attr('x', 35)
      .attr('y', 60)
      .attr('text-anchor', 'middle')
      .attr('fill', '#333')
      .attr('pointer-events', 'none')
      .text('↺');

    // Draw the map with explicit event handling
    const paths = zoomGroup
      .selectAll<SVGPathElement, GeoFeature>('path')
      .data(geoData.features as GeoFeature[])
      .enter()
      .append('path')
      .attr('d', (d) => pathGenerator(d as any) || '')
      .attr('fill', '#ccc')
      .attr('stroke', '#fff')
      .attr('stroke-width', 0.5)
      .attr('cursor', 'pointer'); // Add pointer cursor to indicate clickable

    // Add click event with explicit d3.pointer for better event handling
    paths.on('click', (event, d) => {
      event.stopPropagation();

      const rawCountryCode = getCountryCodeFromFeature(d);

      if (onCountrySelect && rawCountryCode) {
        const standardCode = standardizeCountryCode(rawCountryCode);
        const finalCode = /^[A-Z]{2}$/i.test(standardCode) ? standardCode.toUpperCase() : standardCode;

        if (!isValidCountry(finalCode)) return;

        const isCurrentlySelected = selectedCountries.some((code) => code.toUpperCase() === finalCode.toUpperCase());

        // Use the debounced handler to prevent multiple rapid selections
        debouncedSelect(finalCode, !isCurrentlySelected);
      }
    });

    // Add hover effects separately with visual indication of selectability
    paths
      .on('mouseover', (event, d) => {
        const rawCountryCode = getCountryCodeFromFeature(d);
        if (rawCountryCode) {
          const standardCode = standardizeCountryCode(rawCountryCode);
          const isValid = isValidCountry(standardCode);

          // Only show hover effect for valid countries
          if (isValid) {
            d3.select(event.currentTarget).attr('stroke', '#fff').attr('stroke-width', 2);
          } else {
            d3.select(event.currentTarget).attr('stroke', '#ddd');
          }
        }
      })
      .on('mouseout', (event) => {
        d3.select(event.currentTarget).attr('stroke-width', 0.5).attr('stroke', '#fff');
      });

    // Highlight selected countries without resetting the zoom
    highlightCountries(zoomGroup, geoData, selectedCountries);

    // Add a tooltip for country names
    const tooltip = d3.select('body').append('div').attr('class', 'map-tooltip');

    paths
      .on('mouseover.tooltip', (event, d) => {
        const rawCountryCode = getCountryCodeFromFeature(d);
        if (rawCountryCode) {
          const standardCode = standardizeCountryCode(rawCountryCode);
          // Find country name from our data
          const country = countries.find((c) => c.code.toUpperCase() === standardCode.toUpperCase());
          if (country) {
            tooltip.style('visibility', 'visible').text(country.name);
          }
        }
      })
      .on('mousemove.tooltip', (event) => {
        tooltip.style('top', `${event.pageY - 10}px`).style('left', `${event.pageX + 10}px`);
      })
      .on('mouseout.tooltip', () => {
        tooltip.style('visibility', 'hidden');
      });

    // Clean up tooltip on unmount
    return () => {
      d3.select('.map-tooltip').remove();
    };
  }, [geoData, countries, selectedCountries, onCountrySelect, countryCodeMap, debouncedSelect]);

  // Handle loading and error states
  if (!countries) return <p>Loading map...</p>;

  return (
    <div className="world-map-container">
      <svg ref={svgRef} width="100%" height="100%" className="world-map-svg" />
      <div className="map-legend">
        <div className="legend-item">
          <div className="legend-color legend-color--available" />
          <span>Available</span>
        </div>
        <div className="legend-item">
          <div className="legend-color legend-color--selected" />
          <span>Selected</span>
        </div>
        <div className="legend-item">
          <div className="legend-color legend-color--unavailable" />
          <span>Not available</span>
        </div>
      </div>
      {selectedCountries.length > 0 && (
        <div className="selected-countries-info">
          <small>Selected countries: {selectedCountries.length}</small>
        </div>
      )}
    </div>
  );
};

export default WorldMap;
