import { useRef } from 'react';
import { GET_GAMER_PROMOTIONS } from '../../global/gql/queries';
import { useSlider } from '../../hooks/useSlider';
import { Promotions } from '../../global/interfaces';
import { useReneQuery } from '../../hooks';
import Icon from '../Icon/Icon';
import LoadingFallback from '../loading-fallback/loading-fallback';

import './recommendations-gamer.scss';

const RecommendationsGamer = () => {
  const trackRef = useRef<HTMLDivElement>(null);

  const { data: promotions, loading } = useReneQuery<{ Promotions: Promotions }>(GET_GAMER_PROMOTIONS);

  const { handleNext, handlePrev } = useSlider({
    trackRef,
    dependency: promotions,
    gap: 16,
    className: 'current-slide',
  });

  return (
    <div className="recommendations-gamer">
      <button type="button" onClick={handlePrev}>
        <Icon name="chevron-left" />
      </button>
      <button type="button" onClick={handleNext}>
        <Icon name="chevron-right" />
      </button>
      <div className="recommendations-gamer__games carousel">
        {!promotions && loading ? <LoadingFallback /> : null}
        <div className="recommendations-gamer__games" ref={trackRef}>
          {promotions?.Promotions.items.map((promotion) => (
            <a
              href={promotion.buttonUrl}
              key={promotion.promotionId}
              target="_blank"
              className="recommendations-gamer__games_game current-slide"
              rel="noreferrer"
            >
              <img src={promotion.promotionImage?.url} alt="game" />
              {promotion.buttonText && <p>{promotion.buttonText}</p>}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecommendationsGamer;
