import { useState, useRef, useEffect } from 'react';
import Icon from '../Icon/Icon';
import SmallErrorMsg from '../small-error-msg/small-error-msg';
import Spinner from '../spinner/spinner';
import './multi-select.scss';

interface MultiSelectProps<T> {
  options: T[] | string[];
  selectedValues?: string[] | string;
  onChange: (selected: string[] | string) => void;
  valueKey?: keyof T;
  labelKey?: keyof T;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  multiSelect?: boolean;
  label?: string;
  errorMessage?: string;
  loading?: boolean;
  showDeselectButton?: boolean;
}

export const MultiSelect = <T extends Record<string, any>>({
  options,
  selectedValues = [],
  onChange,
  valueKey = 'value',
  labelKey = 'label',
  placeholder = 'Select options',
  className = '',
  disabled = false,
  multiSelect = false,
  label,
  errorMessage,
  loading = false,
  showDeselectButton = false,
}: MultiSelectProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleOptionClick = (value: string) => {
    let updatedSelection: string[] | string;

    if (multiSelect) {
      const currentSelection = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
      updatedSelection = currentSelection.includes(value)
        ? currentSelection.filter((item) => item !== value)
        : [...currentSelection, value];
    } else {
      updatedSelection = Array.isArray(selectedValues) && selectedValues.includes(value) ? '' : value;
      setIsOpen(false);
    }

    onChange(updatedSelection);
  };

  const clearSelection = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange(multiSelect ? [] : '');
  };

  const hasSelectedValues =
    selectedValues && (Array.isArray(selectedValues) ? selectedValues.length > 0 : selectedValues);

  const getSelectedLabels = () => {
    const values = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    return values
      .map((value) => {
        if (!value) return null;
        if (typeof options[0] === 'string') {
          return value;
        }
        const option = (options as T[]).find((opt) => String(opt[valueKey!]) === value);
        return option ? String(option[labelKey!]) : null;
      })
      .filter(Boolean)
      .join(', ');
  };

  return (
    <div className={`multi-select ${className} ${disabled ? 'disabled' : ''}`} ref={containerRef}>
      {label && <label className="multi-select__label">{label}</label>}
      <div className={`multi-select__trigger ${isOpen ? 'open' : ''}`} onClick={() => !disabled && setIsOpen(!isOpen)}>
        <p className="multi-select__selected">{hasSelectedValues ? getSelectedLabels() : <span>{placeholder}</span>}</p>
        <div className="multi-select__actions">
          {showDeselectButton && hasSelectedValues && !disabled && (
            <button
              type="button"
              className="multi-select__actions_clear-btn"
              onClick={clearSelection}
              aria-label="Clear selection"
            >
              <Icon name="close" />
            </button>
          )}
          <span className="multi-select__actions_arrow" />
        </div>
      </div>

      {isOpen && !disabled && (
        <div className="multi-select__options">
          {loading ? (
            <div className="multi-select__loading">
              <Spinner size="sm" />
            </div>
          ) : (
            options.map((option) => {
              const value = typeof option === 'string' ? option : String(option[valueKey!]);
              const label = typeof option === 'string' ? option : String(option[labelKey!]);

              return (
                <button
                  type="button"
                  key={value}
                  className={`multi-select__option ${
                    (Array.isArray(selectedValues) ? selectedValues.includes(value) : selectedValues === value)
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() => handleOptionClick(value)}
                >
                  {multiSelect && (
                    <span className="multi-select__checkbox">
                      {Array.isArray(selectedValues) && selectedValues.includes(value) && <Icon name="checkmark" />}
                    </span>
                  )}
                  {label}
                </button>
              );
            })
          )}
        </div>
      )}
      <SmallErrorMsg message={errorMessage} />
    </div>
  );
};
