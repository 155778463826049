import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavigateFunction, useNavigate } from 'react-router';
import { ApolloError, useMutation } from '@apollo/client';
import { Event } from '../../../global/interfaces';
import { INITIATE_PASSWORD_RESET_MUTATION } from '../../../global/gql/mutations';
import { GET_USER_LOGIN_STEP_EVALUATION_QUERY } from '../../../global/gql/queries';
import { useErrorContext } from '../../../context/error-context/use-error-context';
import { HOME, REGISTER, PASSWORD, CREATE_PASSWORD } from '../../../global/routes';
import { handleKeyPressSubmit, isUserLoggedIn, handleError } from '../../../utils';
import { useLazyReneQuery, useValidation } from '../../../hooks';
import { credentialValidation } from './validations';
import Input from '../../../components/input/input';
import Spinner from '../../../components/spinner/spinner';

import reneLogo from '../../../global/images/rene-logo-new-big.png';

import './login-page.scss';

const LoginForm: React.FC<{
  navigate: NavigateFunction;
}> = ({ navigate }) => {
  const { state } = useLocation();
  const { credential: stateCredential } = state || {};
  const { setError } = useErrorContext();
  const { errors, isFormInvalid } = useValidation(credentialValidation);
  const [credential, setCredential] = useState<string>(stateCredential || '');

  const [evaluateUser, { loading }] = useLazyReneQuery<{
    UserLoginStepEvaluation: { needsPassword: boolean };
  }>(GET_USER_LOGIN_STEP_EVALUATION_QUERY, { fetchPolicy: 'network-only' });

  const [initiatePasswordReset] = useMutation(INITIATE_PASSWORD_RESET_MUTATION, {
    variables: {
      email: credential,
    },
    onCompleted(data: { InitiatePasswordReset: boolean }) {
      if (data?.InitiatePasswordReset) {
        navigate(`/${CREATE_PASSWORD}`, { state: { email: credential } });
      }
    },
    onError: (err: ApolloError) => {
      if (err.message === 'Password reset failed, please check the params') {
        return handleError(err, setError, 'Email not registered');
      }
      handleError(err, setError);
    },
  });

  const inputHandler = (e: Event['Input']) => {
    setCredential(e.target.value);
  };

  const handleLogin = async () => {
    if (isFormInvalid({ credential })) return;

    let variables = {};
    if (credential.match('@')) {
      variables = {
        email: credential,
      };
    } else {
      variables = {
        username: credential,
      };
    }
    evaluateUser({ variables }).then(({ data }) => {
      const needsPassword = data?.UserLoginStepEvaluation?.needsPassword;
      if (needsPassword) {
        initiatePasswordReset();
      } else {
        navigate(`/${PASSWORD}`, { state: { credential } });
      }
    });
  };

  return (
    <div className="login-page">
      <div className="login-page__logo">
        <img src={reneLogo} alt="reneverse" />
      </div>
      <div className="login-page__form">
        <h1>Welcome, Please sign in here</h1>
        <Input
          label="Email or Username"
          name="email"
          type="text"
          autoComplete="email username"
          placeholder="Enter your email or Account name"
          handleInput={inputHandler}
          onKeyDown={(e) => handleKeyPressSubmit(e, handleLogin)}
          value={credential}
          errorMessage={errors?.credential}
        />
        <button type="button" className="btn-primary-solid" onClick={handleLogin}>
          {loading ? <Spinner size="sm" /> : 'Next'}
        </button>
        <Link to={`/${REGISTER}`}>
          New user? <span>Sign up</span>
        </Link>
      </div>
    </div>
  );
};

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = isUserLoggedIn();

  useEffect(() => {
    if (isLoggedIn) navigate(HOME);
  }, [isLoggedIn, navigate]);

  return (
    <div className="login-page-container">
      <LoginForm navigate={navigate} />
    </div>
  );
};

export default LoginPage;
