import { ApolloError } from '@apollo/client';
import { useCallback, useState } from 'react';
import { useErrorContext } from '../../../context/error-context/use-error-context';
import { useUpdatePopUpContext } from '../../../context/update-message-context/use-update-message-context';
import { AdType } from '../../../global/consts';
import { DELETE_AD_SURFACE_MUTATION, UPSERT_AD_SURFACE_MUTATION } from '../../../global/gql/mutations';
import { GET_AD_SURFACES_QUERY } from '../../../global/gql/queries';
import { AdSurfaceData, Dispatcher } from '../../../global/interfaces';
import { useAutosave, useFileUpload, useReneMutation, useReneQuery, useValidation } from '../../../hooks';
import { handleError, removeTypename } from '../../../utils';
import { validations } from './validations';
import Icon from '../../Icon/Icon';
import Modal from '../../modal/modal';
import LoadingFallback from '../../loading-fallback/loading-fallback';
import BannerWithDropdown from '../../banner-with-dropdown/banner-with-dropdown';
import NewAdSurfaceModal from '../../modal/new-ad-surface-video-modal/new-ad-surface-modal';
import AdSurfaceFields from './ad-surface/ad-surface-fields';

import './ad-surface-list.scss';

interface AdSurfaceListProps {
  id: string | undefined;
  type: 'brandedAssetId' | 'ownableAssetId' | 'gameId';
  isUserAllowedToUpsert: boolean;
  setOpenMobileMenu: Dispatcher<boolean>;
}

const AdSurfaceList: React.FC<AdSurfaceListProps> = ({ id, type, isUserAllowedToUpsert, setOpenMobileMenu }) => {
  const uploadFile = useFileUpload();

  const { setError } = useErrorContext();
  const { showUpdatePopUpMessage } = useUpdatePopUpContext();
  const [newAdSurfaceType, setNewAdSurfaceType] = useState<AdType | undefined>();

  const [form, setForm] = useState<{ surfaces: AdSurfaceData[] }>({
    surfaces: [],
  });
  const [adSurface, setAdSurface] = useState<AdSurfaceData | null>(null);
  const [loading, setLoading] = useState(false);

  const { isFormInvalid } = useValidation(validations);

  const { loading: loadingList, refetch } = useReneQuery<any>(GET_AD_SURFACES_QUERY, {
    fetchPolicy: 'network-only',
    variables: { [type]: id },
    onCompleted: (result: { AdSurfaces: { items: AdSurfaceData[] } }) => {
      setForm({ surfaces: result.AdSurfaces.items });
    },
  });

  const [upsertAdSurface] = useReneMutation(UPSERT_AD_SURFACE_MUTATION);
  const [deleteAdSurface] = useReneMutation(DELETE_AD_SURFACE_MUTATION);

  const finishSurfaceUpdate = useCallback(() => {
    showUpdatePopUpMessage('Changes saved successfully');
    refetch();
  }, [refetch, showUpdatePopUpMessage]);

  const handleSave = useCallback(() => {
    if (!adSurface || isFormInvalid(adSurface)) return;
    const newAdSurface = JSON.parse(JSON.stringify(adSurface)) as AdSurfaceData;
    if (newAdSurface?.contentTags?.length) {
      newAdSurface.contentTags = removeTypename(newAdSurface.contentTags);
    }
    //if placeholder already exist or its null, don't update the placeholder since the BE doesn't except to send it back
    if (newAdSurface.placeholder?.url || newAdSurface.placeholder === null) {
      delete newAdSurface.placeholder;
    }

    setLoading(true);
    upsertAdSurface({ variables: newAdSurface })
      .then(({ data, errors }: { data: { UpsertAdSurface: AdSurfaceData }; errors: ApolloError }) => {
        if (errors) throw errors;
        if (data && adSurface.file && data.UpsertAdSurface.placeholder?.uploadUrl) {
          uploadFile(data.UpsertAdSurface.placeholder.uploadUrl, adSurface.file).finally(() => {
            finishSurfaceUpdate();
          });
        } else {
          finishSurfaceUpdate();
        }
      })
      .catch((err: ApolloError) => {
        handleError(err, setError);
      })
      .finally(() => {
        setAdSurface(null);
        setLoading(false);
      });
  }, [adSurface, finishSurfaceUpdate, isFormInvalid, setError, uploadFile, upsertAdSurface]);

  useAutosave({ data: adSurface, callback: handleSave });

  const handleNewAdSurface = (surface: AdSurfaceData) => {
    setForm((prev) => ({ surfaces: [surface, ...prev.surfaces] }));
    setAdSurface(null);
  };

  const updateSurface = (updatedSurface: any, action?: string) => {
    const { surfaces } = form;
    const index = surfaces?.findIndex((surface: AdSurfaceData) => surface.adSurfaceId === updatedSurface.adSurfaceId);
    if (action === 'delete') {
      surfaces.splice(index, 1);
      setAdSurface(null);
    } else {
      let adSurface: any = {
        ...updatedSurface,
      };
      adSurface = updatedSurface.file
        ? { ...adSurface, placeholder: { extension: updatedSurface?.file?.type.split('/')[1] } }
        : adSurface;

      surfaces[index] = adSurface;
      setAdSurface(adSurface);
    }
    setForm({ surfaces });
  };

  const handleDeleteSurface = (adSurfaceId: string) => {
    const variables = { adSurfaceId };
    deleteAdSurface({ variables }).then(({ data }: { data: { DeleteAdSurface: boolean } }) => {
      if (data.DeleteAdSurface) {
        updateSurface({ adSurfaceId }, 'delete');
        showUpdatePopUpMessage('Changes saved successfully');
      }
    });
  };

  const handleNewBannerClick = () => setNewAdSurfaceType(AdType.BANNER);
  const handleNewVideoClick = () => setNewAdSurfaceType(AdType.VIDEO);

  return (
    <div className={`ad-surface-list ${loading ? 'loading' : ''}`}>
      <div className="ad-surface-list__heading">
        <div className="ad-surface-list__heading_title">
          <button type="button" onClick={() => setOpenMobileMenu(true)}>
            <Icon name="hamburger" />
          </button>
          <h1>
            Ad surfaces
            <span>{form.surfaces.length}</span>
          </h1>
        </div>
        {isUserAllowedToUpsert && (
          <div className="button-options">
            <button type="button" className="btn-primary-ghost" onClick={handleNewBannerClick}>
              <Icon name="plus" /> New Ad Surface
            </button>
            <button type="button" className="btn-primary-ghost" onClick={handleNewVideoClick}>
              <Icon name="plus" /> New Video Surface
            </button>
          </div>
        )}
      </div>
      <div className="ad-surface-list__main">
        {!loadingList ? (
          form?.surfaces.map((surface: AdSurfaceData) => (
            <BannerWithDropdown
              key={surface.adSurfaceId}
              title={surface.adType === 'BANNER' ? 'Ad surface' : 'Video'}
              id={surface.adSurfaceId}
              menuItems={
                isUserAllowedToUpsert
                  ? [{ label: 'Delete', onClick: () => handleDeleteSurface(surface.adSurfaceId) }]
                  : []
              }
            >
              <AdSurfaceFields
                allowCreateSurface={isUserAllowedToUpsert}
                surface={surface}
                updateSurface={updateSurface}
                loading={loading}
                handleFileChange={() => setLoading(true)}
              />
            </BannerWithDropdown>
          ))
        ) : (
          <LoadingFallback />
        )}
      </div>
      <Modal isOpen={!!newAdSurfaceType}>
        <NewAdSurfaceModal
          id={id}
          adType={newAdSurfaceType as AdType}
          assetType={type}
          handleAdSurface={handleNewAdSurface}
          setCloseModal={setNewAdSurfaceType}
        />
      </Modal>
    </div>
  );
};

export default AdSurfaceList;
