import { URL_VALIDATION_REGEX } from '../../../../global/consts';
import { commonValidations } from '../../../../global/validations';

export const validations = {
  title: commonValidations.requiredTrimmed(),
  message: commonValidations.requiredTrimmed(),
  buttonText: (form: { [k: string]: any }, key: string) => {
    if (!form[key] && form.ctaButton) return 'This field is required';
    if (form.ctaButton && form[key].length > 16) return 'Button text is allowed to have maximum 16 characters';
  },
  buttonUrl: (form: { [k: string]: any }, key: string) => {
    if (!form[key] && form.ctaButton) return 'This field is required';
    if (form.ctaButton && !form[key].match(URL_VALIDATION_REGEX)) return 'You must enter full valid URL';
  },
};
